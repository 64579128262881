#fr-crew-store_management-store_name_edit_popup
  > .don_wrapper
    display flex
    flex-direction column-reverse
    width 26rem
    height 11rem

    > .header
      @import "./popup_frame_header.styl"

    .don_page
      width 100%
      height 100%
      > .don_wrapper
        width 100%
        height 100%
        > .body
          width 100%
          height 100%
          .don_tab
            width 100%
            height 100%
            .don_wrapper
              width 100%
              height 100%

              @import "./store_name_edit_form.styl"
