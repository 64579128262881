&
  > .don_wrapper
    > .header
      height 56px
      display flex
      align-items center
      justify-content center
      border-bottom 1px solid #dfdfdf
    > .body
      padding-top 50px

  .wrapper
    width 70%
    margin auto

  .manager-name
    width 50%
    text-align center
    display inline-block
    font-size 1.2rem
    font-weight bold

  .crew_level_option
    width 50%
    display inline-block

  .buttons
    text-align center
    margin 50px auto 50px

  .table-wrapper
    margin-top 40px

  .chart-wrapper
    margin-bottom 30px
