

.plus-product-list
  margin 80px auto 160px
  .plus-sub-header
    margin-bottom 56px
  .product-list-header
    margin-bottom 16px
    &[data-is-close-hide="true"]
      .open-show
        display none
    &[data-is-close-hide="false"]
      .close-hide
        display none
    .category-selector
      display flex
      justify-content space-between
      button
        background transparent
        padding 0
        border 0
        display flex
        align-items center
        span
          font-size 16px
      .selector-section
        display flex
        align-items center
      .selector-open-btn
        line-height 1
        height 24px
        img
          margin-right 16px
          width 14px
      .selected-cate-item-btn
        font-weight bold
      .remove-cate-item
        padding 8px
        display block
        img
          display block
          width 8px
      .sorting-section
        color #f2f2f2
        display flex
        align-items center
        &[data-is-popular="true"]
          a.sorting-new
            color $grey_400
        &[data-is-popular="false"]
          a.sorting-popular
            color $grey_400
    .selector-body
      position relative
      display flex
      margin-top 7px
      .cate-list
        box-sizing border-box
        width 255px
        padding 16px 28px 40px
        font-size 16px
        .cate-list-name
          font-weight 600
          line-height 2
          margin-bottom 12px
        .cate-items
          .cate-item
            line-height 1.75
            margin-bottom 4px
  .plus-product-list-body
    margin-bottom 14px
  @media screen and (max-width: 1024px)
    .plus-sub-header
      margin-bottom 18px
    .product-list-header
      margin-bottom 8px
      .category-selector
        button
          span
            font-size 14px
        .selector-open-btn
          img
            margin-right 8px
            width 12px
      .selector-body
        display block
        overflow hidden
        margin 24px 0
        .cate-list
          width auto
          padding 0 20px
          margin-bottom 24px
          .cate-list-name
            font-weight 600
            line-height 2
            margin-bottom 0
          .cate-items
            position relative
            margin-left -8px
            width 100%
            margin-right -8px
            .cate-item
              display inline-block
              font-size 14px
              line-height 1.71
              margin 8px
    .plus-product-list-body
      margin 0 0 14px
