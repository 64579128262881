#plus-home
  padding-top 92px
  margin-bottom 160px
  @media screen and (max-width 1024px)
    padding-top 80px
    margin-bottom 10px
  .plus-home-background
    position absolute
    top 0
    left 0
    right 0
    height 590px
    z-index 0
    @media screen and (max-width 1024px)
      height 360px

  .plus-home-section
    margin 0 auto


  .section-title-container
    text-align center
    line-height 1.5
    margin-bottom 40px

    .section-title
      margin-bottom 4px
      font-size 32px
      font-weight 500

    .section-description
      font-size 16px
      color $grey_400

    @media screen and (max-width: 480px)
      margin-bottom 25px
      .section-title
        line-height 1.33
        margin-bottom 4px
        font-size 24px

      .section-description
        font-size 12px
        color $grey_400

  .plus-home-section-title
    display flex
    justify-content space-between
    margin-bottom 24px
    h2
      margin 0
      font-size 24px
      line-height 1.33
    a
      font-size 16px
      font-weight bold
      color $grey_400
      display flex
      justify-content space-between
      align-items center
      &:after
        content ''
        background-image url(//s3.marpple.co/files/u_29089/2020/11/original/d3b34ab59317260b72b7aa7b3bfe82e67cc4212a1.png)
        background-repeat no-repeat
        background-size 8px 14px
        width 8px
        height 14px
        margin -2px 0 0 8px
    @media screen and (max-width: 1024px)
      margin-bottom 15px
      h2
        font-size 18px
        line-height 1.78
      a
        font-size 12px
        &:after
          background-image url(//s3.marpple.co/files/u_29089/2020/11/original/dcc2fb78097008d64ba9e2f7949e301392b48aee1.png)
          background-repeat no-repeat
          background-size 7px 10px
          width 7px
          height 10px
          margin -2px 0 0 5px
.plus-home-event
    @import './event.styl'

@import './banner.styl'
@import "product.styl"
