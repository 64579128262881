nav.nav-tabs
  display flex
  -ms-flex-wrap wrap
  flex-wrap wrap
  padding-left 0
  margin-bottom 0
  list-style none
  position relative
  border 0
  margin-top 30px
  a.nav-link
    color #8c8e90
    display block
    padding .625rem 1.25rem
    line-height 1.54
    margin-bottom -1px
    border 1px solid transparent
    border-top-left-radius .25rem
    border-top-right-radius .25rem
    border-bottom 0
    margin-right .125rem
    background-color transparent
    background-clip padding-box
    outline none !important
    &[data-active="false"]:hover
      border-color rgba(24,28,33,0.06)
      border-bottom-color transparent
    &[data-active="true"]
      color #4E5155
      border-color rgba(24,28,33,0.06)
      border-bottom-color #fff
      background-color #fff
.nav-tab-body
  background #fff
  flex-shrink 1
  border 1px solid rgba(24,28,33,0.06)
  background-clip padding-box
  box-shadow 0 1px 4px rgba(24,28,33,0.012)
