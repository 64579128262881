@import "../../../../../TopMenu/PC/F/Style/style.styl"

.don_frame[frame_name="/modules/VectorEditor/Keyring/Grid/CreatorPC/S/Mui/frame.js"][is_modal="true"]
  &::before
    display none
  > .don_wrapper
    top 20px
    right 20px
    bottom 20px
    left 20px
    width auto
    height auto
    display flex
    flex-direction column
    > .header
      order 1
      display flex
      align-items center
      justify-content center
      border-bottom 1px solid #DFDFDF
      box-sizing content-box
      height 56px
      min-height 56px
      position relative
      .title
        font-size 16px
        font-weight 500
        letter-spacing -0.5px
        > .creation-modal-breadcrumb
          list-style none
          display flex
          align-items center
          margin 0
          padding 0
          > li
            position relative
            display block
            width 170px
            text-align center
            color #000000
            > span
              line-height 1
            &:not(:last-child)
              &::after
                position absolute
                top 50%
                left 100%
                transform translateY(-50%)
                display block
                width 14px
                height 28px
                content ''
                background-image: url('//s3.marpple.co/files/u_1093672/2020/9/original/ce2fefa97d2f0dd8ed7a6208919164f60b3e42021.png');
                background-size 14px 28px
            &[data-active="true"]
              > span
                font-weight 600
                position relative
                overflow visible
                &::before
                  content ''
                  position absolute
                  display block
                  width 120%
                  height 10px
                  bottom 1px
                  left 50%
                  transform translate(-50%, 50%)
                  border-radius 5px
                  background-color #fffb00;
                  z-index -1
            &[data-active="false"]
              color #b2b2b2
      .close_frame
        position absolute
        top 9px
        right 9px
        width 36px
        height 36px
        border 0 none
        padding 0
        margin 0
        background-color transparent
        background-repeat no-repeat
        background-position center center
        background-size 18px
        background-image url("//s3.marpple.co/files/u_14345/2021/5/original/e6fc7c6c93a1091aa268db9f6fa0730c8da9793d1.svg")
      .back_frame
        position absolute
        top 8px
        left 10px
        width 40px
        height 40px
        border 0 none
        padding 0
        margin 0
        background-color transparent
        background-repeat no-repeat
        background-position center center
        background-size 19px auto
        background-image url("//s3.marpple.co/files/u_14355/2018/11/original/f_12438_1541409323249_q9vQ8BNlU4U9lrAq6ER5V.png")
    > .body
      order 2

[page_name="/modules/VectorEditor/Keyring/Grid/CreatorPC/S/Mui/page.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    > .body
      width 100%
      height 100%

[tab_name="/modules/VectorEditor/Keyring/Grid/CreatorPC/S/Mui/tab.js"]
  user-select none
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    display flex

    .left_container
      position relative
      width 0
      flex-grow 1
      height 100%
      background-color #FFFFFF
      .editor_container
        position absolute
        top 0
        right 0
        bottom 0
        left 0
        background-color #F8F9FA
      .top_menu_container
        position absolute
        top 0
        left 0
        right 2px
        height 66px
        background-color #FFFFFF
        top-menus()
      .left_menu
        position absolute
        left 57px
        display inline-flex
        flex-direction column
        width 64px
        height 84px
        align-items center
        justify-content space-between
        transition left ease-in-out 200ms
        &:hover
          .icon
            background-color #0157ff
        &[data-is_show="false"]
          left -130px
        &[data-icon_type="upload_image"]
          top calc(50% + 33px - 42px - 16px - 84px - 16px - 84px)
          .icon
            background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/normal/ic-upload@2x.png")
          .icon:active
            background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/over-pressed/ic-upload-over@2x.png")
        &:hover[data-icon_type="upload_image"]
          .icon
            background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/over-pressed/ic-upload-over@2x.png")
        &[data-icon_type="my_image"]
          top calc(50% + 33px - 42px - 16px - 84px)
          .icon
            background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/normal/ic-img@2x.png")
          .icon:active
            background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/over-pressed/ic-img@2x.png")
        &:hover[data-icon_type="my_image"]
          .icon
            background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/over-pressed/ic-img@2x.png")
        &[data-icon_type="shape"]
          top calc(50% + 33px - 42px)
          .icon
            background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/normal/ic-figure@2x.png")
          .icon:active
            background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/over-pressed/ic-figure@2x.png")
        &:hover[data-icon_type="shape"]
          .icon
            background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/over-pressed/ic-figure@2x.png")
        &[data-icon_type="path"]
          top calc(50% + 33px - 42px + 84px + 16px)
          .icon
            background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/normal/ic-path@2x.png")
          .icon:active
            background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/over-pressed/ic-path@2x.png")
        &:hover[data-icon_type="path"]
          .icon
            background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/over-pressed/ic-path@2x.png")
        &[data-icon_type="text"]
          top calc(50% + 33px - 42px + 84px + 16px + 84px + 16px)
          .icon
            background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/normal/ic-text@2x.png")
          .icon:active
            background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/over-pressed/ic-text@2x.png")
        &:hover[data-icon_type="text"]
          .icon
            background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/over-pressed/ic-text@2x.png")
        .icon
          display inline-flex
          width 64px
          height 64px
          align-items center
          justify-content center
          border-radius 50%
          border 0 none
          background-color #FFFFFF
          background-size 30px 30px
          background-repeat no-repeat
          background-position center center
          padding 0
          margin 0
          box-shadow 0 2px 10px 0 rgba(0, 0, 0, 0.1)
          &:active
            background-color #0157ff
        .label_text
          overflow visible
          font-size 12px
          white-space nowrap
      .layer_container
        width 288px
        height 476px
        display flex
        flex-direction column
        position absolute
        top calc(50% - 185px)
        right 10px
        background-color #FFFFFF
        box-shadow 0 2px 10px 0 rgba(0, 0, 0, 0.1)
        border-radius 10px
        overflow hidden
        &[data-is_show="false"]
          display none
        .header
          width 100%
          height 46px
          display flex
          align-items center
          justify-content space-between
          padding 0 16px
          box-sizing border-box
          border-bottom 1px solid #E9ECEF
          .title
            font-size 14px
            font-weight bold
          .close_layer
            width 15px
            height 15px
            margin 0
            padding 0
            border 0 none
            background-color transparent
            background-repeat no-repeat
            background-size 15px 15px
            background-position center center
            background-image url("//s3.marpple.co/vector-editor-assets/pc/layer/ic-close-15-px@2x.png")
            display inline-flex
            justify-content center
            align-items center
        .list_container
          position relative
          width 100%
          height 0
          flex-grow 1
          box-sizing border-box
          padding-top 16px
          padding-bottom 16px
          overflow auto
          &[data-count="0"]
            display none
          .layer_item
            position relative
            width 100%
            height 48px
            box-sizing border-box
            background-color #FFFFFF
            display flex
            align-items center
            cursor pointer
            .image_container
              width 40px
              height 40px
              box-sizing border-box
              border-radius 2px
              border solid 1px #d5dbe0
              margin-right 8px
              margin-left 16px
              background-color #FFFFFF
              background-repeat no-repeat
              background-size 40px 40px
              background-position center center
              background-image url("//s3.marpple.co/vector-editor-assets/pc/layer/img-layer@2x.png")
            .label_container
              width 0
              height 100%
              flex-grow 1
              display flex
              align-items center
            .button_container
              width 50px
              height 100%
              display flex
              align-items center
              justify-content space-between
              margin-right 16px
              button
                width 24px
                height 24px
                padding 0
                margin 0
                border 0 none
                background-color transparent
                background-repeat no-repeat
                background-size 12px 12px
                background-position center center
            &[data-is_selected="true"]
              background-color #e8f0fe
            &[data-is_selected="false"]
              background-color #ffffff
            &[data-is_visible="true"]
              .button_container
                .visible
                  background-image url("//s3.marpple.co/vector-editor-assets/pc/layer/ic-layer-visible@2x.png")
            &[data-is_visible="false"]
              .button_container
                .visible
                  background-image url("//s3.marpple.co/vector-editor-assets/pc/layer/ic-layer-hide@2x.png")
            &[data-is_locked="true"]
              .button_container
                .lock
                  background-image url("//s3.marpple.co/vector-editor-assets/pc/layer/ic-layer-locked@2x.png")
            &[data-is_locked="false"]
              .button_container
                .lock
                  background-image url("//s3.marpple.co/vector-editor-assets/pc/layer/ic-layer-unlocked@2x.png")
  .right_container
    @keyframes hide_to_right {
      from {
        opacity 1
        transform translateX(0)
        visibility visible
      }
      to {
        opacity 0
        transform translateX(20%)
        visibility hidden
      }
    }
    @keyframes hide_to_left {
      from {
        opacity 1
        transform translateX(0)
        visibility visible
      }
      to {
        opacity 0
        transform translateX(-20%)
        visibility hidden
      }
    }
    @keyframes show_to_right {
      from {
        opacity 0
        transform translateX(-20%)
      }
      to {
        opacity 1
        transform translateX(0)
      }
    }
    @keyframes show_to_left {
      from {
        opacity 0
        transform translateX(20%)
      }
      to {
        opacity 1
        transform translateX(0)
      }
    }
    width 320px
    height 100%
    overflow hidden
    &[data-show_panel_wrapper="left"]
      .panel_wrapper[data-panel_wrapper="left"]
        animation-name show_to_right
      .panel_wrapper[data-panel_wrapper="right"]
        animation-name hide_to_right
    &[data-show_panel_wrapper="right"]
      .panel_wrapper[data-panel_wrapper="left"]
        animation-name hide_to_left
      .panel_wrapper[data-panel_wrapper="right"]
        animation-name show_to_left
    .right_wrapper
      width 100%
      height 100%
      display flex
      flex-direction column
      box-sizing border-box
      padding 40px 0 32px 0
      background-color #FFFFFF
      overflow hidden
      .panel_container
        width 100%
        height 0
        flex-grow 1
        position relative
        .panel_wrapper
          position absolute
          width 100%
          height 100%
          overflow hidden
          animation-duration 200ms
          animation-timing-function ease-in-out
          animation-iteration-count 1
          animation-fill-mode forwards
          display flex
          flex-direction column
          .inner_wrapper
            width 100%
            height 0
            flex-grow 1
            position relative
            .right_panel_home
              position absolute
              width 100%
              height 100%
              background-color #FFFFFF
              display flex
              flex-direction column
              box-sizing border-box
              padding 0 30px 0 30px
              .header
                width 100%
                height 56px
                display flex
                flex-direction column
                align-items flex-start
                justify-content space-between
                font-size 20px
                font-weight normal
          .button_container
            position relative
            width 100%
            height 56px
            display flex
            justify-content space-between
            box-sizing border-box
            padding 0 30px 0 30px
            button
              width 186px
              height 100%
              margin 0
              padding 0
              border 0 none
              &[data-is_show="false"]
                display none
              &.cancel
                background-color #FFFFFF
                box-sizing border-box
                border 1px solid #D5DBE0
                color #000000
              &.next
                background-color #000000
                color #FFFFFF

@import "../../PathEditor/F/Style/style.styl"
