[page_name="/modules/SVGEditor/MobileUnlockItems/S/Mui/page.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    > .body
      width 100%
      height calc(100% - 48px)

[tab_name="/modules/SVGEditor/MobileUnlockItems/S/Mui/tab.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
  .blank_plate
    position fixed
    top 0
    right 0
    bottom 0
    left 0
  .outer_container
    position relative
    display flex
    align-items center
    justify-content center
    width 100%
    height 100%
    overflow auto
    &[data-is_overflow="true"]
      justify-content flex-start
    .inner_container
      height 100%
      display inline-flex
      align-items center
      .item
        display flex
        align-items center
        width 56px
        height 100%
        .item_wrapper
          display flex
          flex-direction column
          align-items center
          width 100%
          .img
            width 40px
            height 40px
            flex-grow 1
            margin-bottom 6px
          .lock
          .unlock
            width 18px
            height 18px
            span
              display block
              width 100%;
              height 100%
              background-size 100%
          .lock
            span
              background-image url("//s3.marpple.co/file/guest/2018/7/original/f_5292_1531463731823_FC8lSA6rgep4T0bqs7C3C.png")
          .unlock
            span
              background-image url("//s3.marpple.co/files/u_193535/2018/10/original/f_11768_1540780825772_zlPL5chF4pL8QZ6Itux.png")
        &[data-is_locked="true"]
          .unlock
            display none
          .img
            background #FFFFFF url("//s3.marpple.co/file/guest/2018/7/original/f_5295_1531463731823_MutzRbFvF6U1AMz4q9G.png") no-repeat
            background-size 40px 40px
        &[data-is_locked="false"]
          .lock
            display none
          .img
            background #FFFFFF url("//s3.marpple.co/file/guest/2018/7/original/f_5294_1531463731823_N8U8Vo5Wi9iM0PoArETw.png") no-repeat
            background-size 40px 40px


