copy-grid(container-height, transition-duration)
  .container
    position fixed
    left 0
    right 0
    bottom 0
    height container-height
    display flex
    flex-direction column
    padding 24px 32px
    box-sizing border-box
    align-items center
    justify-content space-between
    background-color #FFFFFF
    transform translateY(0)
    transition-property transform
    transition-duration transition-duration
    &[data-is_show="true"]
      transform translateY(0)
    &[data-is_show="false"]
      transform translateY(container-height)

    .description
      display flex
      justify-content center
      width 100%
      .icon
        margin-right 8px
      p
        margin 0
        font-weight bold

    .buttons
      display flex
      width 100%
      height 32px
      button
        height 100%
        width 0
        flex-grow 1
        margin 0
        padding 0
        border 0
        box-sizing border-box
        &:not(:last-child)
          margin-right 8px
        &.cancel
          background-color #FFFFFF
          border 1px solid #D5DBE0
          color #000000
        &.done
          background-color #000000
          border 0 none
          color #FFFFFF
