@import "../../../../../SettingBackground/PC/F/Style/style.styl"
@import "../../../../../TopMenu/PC/F/Style/style.styl"

.don_frame[frame_name="/modules/VectorEditor/Sticker/Free/CreatorPC/S/Mui/frame.js"][is_modal="true"]
  &::before
    display none
  > .don_wrapper
    top 20px
    right 20px
    bottom 20px
    left 20px
    width auto
    height auto
    display flex
    flex-direction column
    > .header
      order 1
      display flex
      align-items center
      justify-content center
      border-bottom 1px solid #DFDFDF
      box-sizing content-box
      height 56px
      min-height 56px
      position relative
      .title
        font-size 16px
        font-weight 500
        letter-spacing -0.5px
        > .creation-modal-breadcrumb
          list-style none
          display flex
          align-items center
          margin 0
          padding 0
          > li
            position relative
            display block
            width 170px
            text-align center
            color #000000
            > span
              line-height 1
            &:not(:last-child)
              &::after
                position absolute
                top 50%
                left 100%
                transform translateY(-50%)
                display block
                width 14px
                height 28px
                content ''
                background-image: url('//s3.marpple.co/files/u_1093672/2020/9/original/ce2fefa97d2f0dd8ed7a6208919164f60b3e42021.png');
                background-size 14px 28px
            &[data-active="true"]
              > span
                font-weight 600
                position relative
                overflow visible
                &::before
                  content ''
                  position absolute
                  display block
                  width 120%
                  height 10px
                  bottom 1px
                  left 50%
                  transform translate(-50%, 50%)
                  border-radius 5px
                  background-color #fffb00;
                  z-index -1
            &[data-active="false"]
              color #b2b2b2
      .close_frame
        position absolute
        top 9px
        right 9px
        width 36px
        height 36px
        border 0 none
        padding 0
        margin 0
        background-color transparent
        background-repeat no-repeat
        background-position center center
        background-size 18px
        background-image url("//s3.marpple.co/files/u_14345/2021/5/original/e6fc7c6c93a1091aa268db9f6fa0730c8da9793d1.svg")
      .back_frame
        position absolute
        top 8px
        left 10px
        width 40px
        height 40px
        border 0 none
        padding 0
        margin 0
        background-color transparent
        background-repeat no-repeat
        background-position center center
        background-size 19px auto
        background-image url("//s3.marpple.co/files/u_14355/2018/11/original/f_12438_1541409323249_q9vQ8BNlU4U9lrAq6ER5V.png")
    > .body
      order 2

[page_name="/modules/VectorEditor/Sticker/Free/CreatorPC/S/Mui/page.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    > .body
      width 100%
      height 100%

[tab_name="/modules/VectorEditor/Sticker/Free/CreatorPC/S/Mui/tab.js"]
  user-select none
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    display flex

    .left_container
      position relative
      width 0
      flex-grow 1
      height 100%
      background-color #FFFFFF
      .editor_container
        position absolute
        top 0
        right 0
        bottom 0
        left 0
        background-color #F8F9FA
        .free_template
          transition-duration 1s
          transition-timing-function ease-in-out
          transition-property opacity
        .free_template[data-is_show="false"]
          opacity 0
        .free_template[data-is_show="true"]
          opacity 1
          [data-free-sticker-template-role="cutting-line"]
            cursor pointer
      .top_menu_container
        position absolute
        top 0
        left 0
        right 2px
        height 66px
        background-color #FFFFFF
        top-menus()
      .new_sticker_button
        position absolute
        display flex
        align-items center
        justify-content center
        width 190px
        height 56px
        box-sizing border-box
        margin 0
        padding 0
        border 0 none
        color #000000
        background-color #FFFFFF
        border-radius 28px
        top 235px
        left 56px
        box-shadow 0 2px 10px 0 rgba(0, 0, 0, 0.05)
        transform translateX(0)
        transition transform 1s
        .icon
          display inline-flex
          width 26px
          height 26px
          margin-right 8px
          background-repeat no-repeat
          background-position center center
          background-size 100% 100%
          background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/normal/ic-sticker@2x.png")
        &[data-is_show="false"]
          transform translateX(-200%)
        &:active, &:hover
          color #FFFFFF
          background-color #0157FF
          box-shadow 0 2px 10px 0 rgba(1, 87, 255, 0.3)
          .icon
            background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/over-pressed/ic-sticker-wht@2x.png")

    .right_container
      @keyframes hide_to_right {
        from {
          opacity 1
          transform translateX(0)
          visibility visible
        }
        to {
          opacity 0
          transform translateX(20%)
          visibility hidden
        }
      }
      @keyframes hide_to_left {
        from {
          opacity 1
          transform translateX(0)
          visibility visible
        }
        to {
          opacity 0
          transform translateX(-20%)
          visibility hidden
        }
      }
      @keyframes show_to_right {
        from {
          opacity 0
          transform translateX(-20%)
        }
        to {
          opacity 1
          transform translateX(0)
        }
      }
      @keyframes show_to_left {
        from {
          opacity 0
          transform translateX(20%)
        }
        to {
          opacity 1
          transform translateX(0)
        }
      }
      width 320px
      height 100%
      overflow hidden
      .right_wrapper
        width 100%
        height 100%
        display flex
        flex-direction column
        box-sizing border-box
        padding 40px 0 32px 0
        background-color #FFFFFF
        overflow hidden

        .panel_container
          width 100%
          height 0
          flex-grow 1
          position relative
          overflow hidden
          .panel_wrapper
            width 100%
            height 100%
            position absolute
            display flex
            flex-direction column
            animation-duration 200ms
            animation-timing-function ease-in-out
            animation-iteration-count 1
            animation-fill-mode forwards
            &[data-panel="home"][data-is_show="false"][data-is_animation="false"]
              opacity 0
              transform translateX(-20%)
              visibility hidden
            &[data-panel="home"][data-is_show="false"][data-is_animation="true"]
              animation-name hide_to_left
            &[data-panel="home"][data-is_show="true"][data-is_animation="false"]
              opacity 1
              transform translateX(0)
            &[data-panel="home"][data-is_show="true"][data-is_animation="true"]
              animation-name show_to_right
            &[data-panel="select"][data-is_show="false"][data-is_animation="false"]
              opacity 0
              transform translateX(20%)
              visibility hidden
            &[data-panel="select"][data-is_show="false"][data-is_animation="true"]
              animation-name hide_to_right
            &[data-panel="select"][data-is_show="true"][data-is_animation="false"]
              opacity 1
              transform translateX(0)
            &[data-panel="select"][data-is_show="true"][data-is_animation="true"]
              animation-name show_to_left
            .inner_wrapper
              width 100%
              height 0
              flex-grow 1
              position relative
              setting-background-right-panel(24px, 30px)
            .button_container
              width 100%
              height 56px
              display flex
              justify-content space-between
              box-sizing border-box
              padding 0 30px 0 30px
              button
                width 186px
                height 100%
                margin 0
                padding 0
                border 0 none
                &.cancel
                  background-color #FFFFFF
                  box-sizing border-box
                  border 1px solid #D5DBE0
                  color #000000
                &.done
                  background-color #000000
                  color #FFFFFF
            .right_panel_select
              position absolute
              width 100%
              height 100%
              background-color #FFFFFF
              display flex
              flex-direction column
              box-sizing border-box
              padding 0 30px
              .header
                position relative
                width 100%
                height 22px
                display flex
                align-items center
                justify-content center
                .back
                  position absolute
                  left 0
                  top 50%
                  transform translateY(-50%)
                  background-color transparent
                  background-image url("//s3.marpple.co/files/u_1187077/2021/5/original/2aac30aabf4af156704b95903af308892eefb75a1.png")
                  width 19px
                  height 16px
                  margin 0
                  padding 0
                  border 0 none
                .title
                  font-size 19px
              .wrapper
                width 100%
                height 0
                flex-grow 1
                display flex
                flex-direction column
                align-items center
                justify-content center
                .description
                  display inline-flex
                  align-items center
                  margin-bottom 16px
                  .icon
                    margin-right 6px
                  p
                    margin 0
                    font-weight bold
                .edit
                  width 100%
                  height 48px
                  background-color #FFFFFF
                  border 1px solid #D5DBE0
                  margin 0
                  box-sizing border-box

    .blank_plate
      position fixed
      top 0
      right 0
      bottom 0
      left 0
      display none
      &[data-is_show="true"]
        display block
