

@media screen and (min-width: 1025px)
  .plus-home-community
    .article_list_area
      width 1276px
      max-width 1276px
      min-height auto

@media screen and (max-width: 1024px)
  .plus-home-community
    .article_list_area
      margin 0
      .infi_wrapper
        width 49% !important
        &:first-child
          margin-right 2%

@media screen and (min-width: 1025px)
  .plus-community-list
    .article_list_area
      width 1276px
      max-width 1276px
      min-height auto



@media screen and (max-width: 1024px)
  .plus-community-list
    .article_list_area
      margin 0
      .infi_wrapper
        width 49% !important
        &:first-child
          margin-right 2%


@media screen and (min-width: 1025px)
  .marpple-shop-plus
  .marpple-shop-plus-community
    .article_list_area .infi_view_more
      text-align center
      &:before
        height 400px
        background linear-gradient(to bottom, rgba(0,0,0,0), #fff 80%, #fff)
      button
        position absolute
        left 0
        right 0
        bottom 0
        width 412px
        margin 0 auto
        font-weight normal
        border solid 1px #f2f2f2
        &:after
          content ' '
          display inline-block
          margin-left 10px
          width 14px
          height 8px
          background url("//s3.marpple.co/files/u_218933/2020/11/original/4691c8092616f576d263fae94aa3970b4d3b46891.svg") no-repeat

@media screen and (max-width: 1024px)
  .marpple-shop-plus
  .marpple-shop-plus-community
    .article_list_area .infi_view_more
      text-align center
      &:before
        height 400px
        background linear-gradient(to bottom, rgba(0,0,0,0), #fff 80%, #fff)
      button
        position absolute
        left 0
        right 0
        bottom 0
        font-weight normal
        border solid 1px #f2f2f2
        &:after
          content ' '
          display inline-block
          margin-left 10px
          width 14px
          height 8px
          background url("//s3.marpple.co/files/u_218933/2020/11/original/4691c8092616f576d263fae94aa3970b4d3b46891.svg") no-repeat