.filter
  box-sizing border-box
  margin-bottom 1rem
  padding 0.2rem
  display flex
  flex-direction column
  align-items flex-start
  label
    height 2.4rem
    > span
      display inline-flex
      width 8rem
      height 90%
      align-items center
      justify-content center
      background-color rgba(24,28,33,0.1)
      margin-right 0.2rem
    > input, select
      width 18rem
      height 90%
  .fx-auto-complete-list
    left 8.2rem
    top 32px
    width 18rem
    ul
      background #eee
      border-radius 0 0 0.25rem 0.25rem
      li
        padding 0.5rem 1px
        &[selected="true"]
          color #fff
          background-color #999
