&
  width 100%
  min-height 100%
  display flex
  flex-direction column
  background #fff
  padding-bottom 80px
  align-items center
  .store-product-list-body
    max-width none
    width 100%
    .selected-type
      margin 60px auto 0
      max-width 1120px
      ul
        display flex
        justify-content center
        margin 0
        padding 0
        list-style none
        li
          margin-right 40px
          font-size 1.2rem
          &:last-child
            margin-right 0
          a[data-selected="true"]
            color #e06e2d
    .store-categories
      margin 40px auto 60px
    .store-products
      max-width 98%
      display flex
      justify-content space-between
      flex-wrap wrap
      margin 0 auto
