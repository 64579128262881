[frame_name="/modules/VectorEditor/AcrylicFigure/Free/Mobile/ElementLayer/S/Mui/frame.js"]
  > .don_wrapper
    > .header
      display flex
      width 100%
      justify-content center
      align-items flex-end
      .header_shadow
        width 100%
        position absolute
        bottom 0
        box-shadow 0 2px 7px 2px rgba(170 170 170 0.67)
      button[name="close_frame"]
        position relative
        background-color #FFFFFF
        border 0 none
        border-radius 2px
        display inline-flex
        width 50px
        height 22px
        align-items center
        justify-content center
        box-shadow 0 -1px 10px 0px rgba(170 170 170 0.67)
    > .body
      z-index 1

[page_name="/modules/VectorEditor/AcrylicFigure/Free/Mobile/ElementLayer/S/Mui/page.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    > .body
      width 100%
      height 100%

[tab_name="/modules/VectorEditor/AcrylicFigure/Free/Mobile/ElementLayer/S/Mui/tab.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    overflow hidden

    .blank_plate
      position fixed
      top 0
      right 0
      bottom 0
      left 0

    .container
      position relative
      display flex
      flex-direction column
      width 100%
      height 100%

      .header
        width 100%
        height 48px
        box-sizing border-box
        border-bottom 1px solid #E9ECEF
        display flex
        align-items center
        justify-content center
        margin-bottom 16px
        .title
          font-size 14px
          font-weight bold
      .list_container
        width 100%
        height 0
        flex-grow 1
        html.safe_area_inset_bottom &
          margin-bottom 10px
        .list_wrapper
          .swiper-slide
            height auto
            .list_item
              display flex
              align-items center
              width 100%
              height 48px
              box-sizing border-box
              padding 0 32px
              .image_container
                width 40px
                height 40px
                margin-right 14px
                box-sizing border-box
                border solid 1px #d5dbe0;
                border-radius 2px
                overflow hidden
                background-color #ffffff
                background-size 40px 40px
                background-position center center
                background-repeat no-repeat
                background-image url("//s3.marpple.co/vector-editor-assets/mobile/layer/img-layer@2x.png")
              .label_container
                width 0
                height 100%
                flex-grow 1
                display inline-flex
                align-items center
              .visible_container
                width 24px
                height 24px
                display inline-flex
                align-items center
                justify-content center
                margin-right 12px
                .visible_icon
                  display none
                  width 12px
                  height 12px
                  padding 0
                  margin 0
                  border 0 none
                  background-color transparent
                  background-size 12px 12px
                  background-position center center
                  background-repeat no-repeat
              .lock_container
                width 24px
                height 24px
                display inline-flex
                align-items center
                justify-content center
                .lock_icon
                  display none
                  width 12px
                  height 12px
                  padding 0
                  margin 0
                  border 0 none
                  background-color transparent
                  background-size 12px 12px
                  background-position center center
                  background-repeat no-repeat
              &[data-is_locked="true"]
                .visible_container
                  .visible_icon
                    display block
                .lock_container
                  .lock_icon
                    display block
                    background-image url("//s3.marpple.co/vector-editor-assets/mobile/layer/ic-layer-locked@2x.png")
              &[data-is_locked="false"]
                .lock_container
                  .lock_icon
                    background-image url("//s3.marpple.co/vector-editor-assets/mobile/layer/ic-layer-unlocked@2x.png")
              &[data-is_visible="true"]
                .visible_container
                  .visible_icon
                    background-image url("//s3.marpple.co/vector-editor-assets/mobile/layer/ic-layer-visible@2x.png")
              &[data-is_visible="false"]
                .lock_container
                  .lock_icon
                    display block
                .visible_container
                  .visible_icon
                    display block
                    background-image url("//s3.marpple.co/vector-editor-assets/mobile/layer/ic-layer-hide@2x.png")
              &[data-is_selected="true"]
                .visible_container
                  .visible_icon
                    display block
                .lock_container
                  .lock_icon
                    display block
                background-color #E8F0FE
              &[data-is_selected="false"]
                background-color #FFFFFF
