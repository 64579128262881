html.plus-home-event-list
  .event-page-title
    font-size 38px
    font-weight bold
    line-height 1.26
    margin 81px auto 40px
    @media screen and (max-width 480px)
      margin 40px 0 16px
      font-size 24px
      font-weight 600
      line-height 1.33


  .event-container
    display flex
    flex-wrap wrap

    feed_card(0px)

    .feed_card
      .thumbnail
        margin-bottom 24px
      .text
        .title
          line-height 1.4
          font-size 20px
          margin-bottom 8px
        .description
          line-height 1.43

    @media screen and (max-width 480px)
      margin 0 -10px 70px
      .feed_card
        .thumbnail
          margin-bottom 16px

    @media screen and (min-width 1025px)

      .feed_card
        width 392px
        margin-bottom 56px
        margin-right 50px
        margin-left 0
        &:nth-child(3n)
          margin-right 0



