
[tab_name="/modules/SVGEditor/MobileColorAndBorder/S/Mui/border_tab.js"]
  *
    box-sizing border-box
  .don_wrapper
    padding 0 30px
    margin 0 auto
    height 232px
    overflow hidden
    .svg_editor_frame_border_wrapper
      padding 24px 0
      .colors_wrapper
        margin-bottom 34px
    .range_slider_wrapper
      margin-bottom 16px
[tab_name="/modules/SVGEditor/MobileColorAndBorder/S/Mui/color_tab.js"]
  *
    box-sizing border-box
  .don_wrapper
    padding 24px 30px
    margin 0 auto
  .colors_wrapper
      margin-bottom 34px

[frame_name="/modules/SVGEditor/MobileText/S/Mui/frame.js"]
  *
    box-sizing border-box
  .select_font
    height 32px
  .font_style_wrapper
    height 32px
    background-color #f8f9fa
  [tab_name="/modules/SVGEditor/MobileText/S/Mui/text_tab.js"]
    .don_wrapper
      padding 24px 30px
      margin 0 auto
    .don_wrapper
      .select_font
        margin-bottom 24px
      .font_style_wrapper
        margin-bottom 24px
  [tab_name="/modules/SVGEditor/MobileText/S/Mui/font_tab.js"]
    .don_wrapper
      padding 24px 30px
      margin 0 auto
    .don_wrapper
      padding 0
    @import "font.styl"
