&
  width 100%
  min-height 100%
  display flex
  flex-direction column
  .header
    padding 24px 24px 10px
    select
      height 35px
      font-size 14px
    .btn
      display inline-flex
      align-items center
      i
        margin-right 8px

  .chart-wrapper
    border 0
    border-top 1px solid rgba(24,28,33,0.06)
    border-radius 0
    thead
      th
        vertical-align middle
        &[collspan="3"]
          text-align center
        i
          float right
          margin-top 2px
        a
          text-decoration underline
    tbody
      tr
        height 4rem
        td
          a
            text-decoration underline
      .logo
        padding 0
        text-align center
        .product_info
          display inline-flex
      .link a
        text-decoration underline
        &:not(:last-child)
          margin-right 0.4rem
  .total-chart-wrapper
    align-items flex-end
    border-top 0
    .total-chart
      margin-top -45px
      width 740px
      background #fff
      border 0 !important
      thead
      tbody
        tr
        td
        th
          height auto
          background #fff
          border 0
          padding 4px

.search-input-container
  display inline-block
  margin-left 50px
  &__input
    padding 10px

.search-btn
  margin-left 30px
