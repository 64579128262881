.medium-editor-toolbar
  box-shadow 0 2px 4px 0 rgba(0, 0, 0, 0.1)
  border solid 1px #d9d9d9
  background none
  background-color #ffffff
  .medium-editor-toolbar-actions
    display flex
  &:after
    content: none
  li button
    background none
    background-color #ffffff
    border 0
    border-right 1px solid #f2f2f2
    box-shadow none
    color black
    &:hover
      color #3c89f9
      background #fff
  li .medium-editor-button-active
    background none
    background-color #fff
    color #3c89f9

  .medium-editor-toolbar-form
    background none
    background-color #fff
    .medium-editor-toolbar-input
      background none
      background-color #fff
      border 0
      input-box()
      display inline-block
      width auto

.medium-editor-toolbar-active.medium-editor-stalker-toolbar
  animation none

.link_prompt_box
  .buttons
    display flex

.medium-editor-plugin__alt-prompt
  .buttons
    display flex
  .caption_checkbox_container
    text-align left
    font-size 12px
  input[name="caption"]
    margin-top 12px;

