#fr-crew-store_management-store_creation
  > .don_wrapper
    display flex
    flex-direction column-reverse
    width 26rem
    height 20rem

    > .header
      display flex
      align-items center
      justify-content space-between
      width 100%
      height 5rem
      padding 0 1rem
      box-sizing border-box
      .don_hide_frame
        position static
        width 20px
      .title
        font-weight 600
        color #000000

    .don_page
      width 100%
      height 100%
      > .don_wrapper
        width 100%
        height 100%
        > .body
          width 100%
          height 100%
          .don_tab
            width 100%
            height 100%
            .don_wrapper
              width 100%
              height 100%
              .crew-store_creation
                display flex
                flex-direction column
                justify-content space-between
                width 100%
                height 100%

                box-sizing border-box
                padding 1rem

                .instruction
                  p
                    margin 0
                    &::before
                      content '*'
                      display inline-block
                      margin-right 0.4rem

                button
                  align-self flex-end

                .inputs
                  width 100%
                  input
                    width 100%
                    height 2rem
                    font-size 1rem
                  input:not(:last-child)
                    margin 0 0 1rem 0
