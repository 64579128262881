html.crew-feed_list
  .crew-feed_list
    width 100%
    .head_container
      text-align right
      max-width 1500px
      width 80%
      margin 0 auto
      padding 50px 0

      .custom-dropdown
        display inline-block
        margin-right 20px
        .dropdown-option
          padding 13px 30px

    .feed_container
      display flex
      flex-wrap wrap
      justify-content space-between
      margin auto
      max-width 1500px
      width 80%

      feed_card(0px)

      .feed_card
        margin 0 0 40px 0

