.plus-layout
  position relative
  ul
    padding 0
    margin 0
    list-style none
  .plus-sub
    margin 80px auto 160px
  .plus-sub-header
    margin-bottom 40px
    .title
      h1
        margin 0
        font-size 38px
        font-weight bold
        line-height 1.26
  .plus-layout-header
    z-index 5
    .gnb-list-btn
      button
        padding 0
        border 0
        background transparent
        touch-action manipulation
      .search-btn
        display none

  @media screen and (min-width 1025px)
    min-width 1296px
    .plus-layout-header
      box-sizing border-box
      border-bottom 1px solid $gray200
      .header-wrapper
        display flex
        justify-content space-between
        align-items center
        height 92px
        width 1276px
        padding 0 10px
        margin 0 auto
        .left
          .logo
            margin 0
            img
              height 44px
              min-width 40px
        .right
          display flex
          align-items center
          .gnb-list
            align-items center
            display flex
            font-weight 500
            &.gnb-list-nav
              .gnb-item
                font-size 18px
                margin-right 40px
            &.gnb-list-sign
              .gnb-item
                font-size 15px
                margin-left 16px
            &.gnb-list-btn
              .gnb-item
                margin-left 16px
                i
                  cursor pointer
                img
                  width 32px
    &.is-main
      .plus-layout-header
        box-sizing unset
        border-bottom 0
    .plus-layout-body
      width 1296px
      padding 0 10px
      margin 0 auto
      box-sizing border-box
      min-height 653px
  @media screen and (max-width 1024px)
    .plus-sub
      margin 40px 0 80px
    .plus-sub-header
      margin-bottom 16px
      .title
        h1
          font-size 24px
          font-weight 600
          line-height 1.33
    .plus-layout-header
      position relative
      border-bottom 1px solid $gray200
      box-sizing border-box
      .ham-drop-down
        position fixed
        bottom 0
        top 0
        left 0
        right 0
        height 0
        overflow hidden
        opacity 0
        transition opacity .3s ease-in-out, height 0s ease .3s
        &[on="true"]
          opacity 1
          transition opacity .3s ease-in-out
          height 100%
          .gnb-list-wrapper
            .gnb-list.gnb-list-nav
              .gnb-item
                opacity 1
                transition opacity .2s ease-in-out .1s
        .ham_wrap2
          height 100%
          background rgba(0,0,0,.6)
        .gnb-list-wrapper
          .gnb-list.gnb-list-nav
            padding 16px
            background #fff
            .gnb-item
              margin 8px 0
              line-height 2
              font-size 16px
              opacity 0
              transition opacity .2s ease-in
              &[data-selected="true"]
                font-weight bold
        .header-wrapper
          background #fff
          border-bottom 1px solid $gray200
          .right
            .gnb-list
              &.gnb-list-sign
                margin-right 12px
                a
                  color $grey_400
                  font-weight 300
              &.gnb-list-btn
                .gnb-item
                  .close-btn
                    img
                      width 18px
      .header-wrapper
        display flex
        justify-content space-between
        align-items center
        height 79px
        padding 0 16px
        img
          display block
        .left
          .logo
            margin 0
            img
              height 28px
        .right
          display flex
          align-items center
          .gnb-list
            align-items center
            display flex
            font-weight bold
            &.gnb-list-btn
              margin-right -8px
              .gnb-item
                padding 8px
                img
                  width 20px
                &.search-btn
                  margin-right 8px
    .plus-layout-body
      margin 0 10px
  &.is-main
    .plus-layout-header
      position absolute
      top 0
      left 0
      right 0
      border-bottom 0
    .plus-layout-header-wrapper
      &[data-menu-color="white"]
        a
          color #fff
        .plus-black-mode
          display none
      &[data-menu-color="black"]
        a
          color #000
        .plus-white-mode
          display none


@media screen and (min-width 320px)
  .plus-layout-footer
    width 100%
    background-color $grey_100
    display inline-block
    &__inner
      margin 32px 24px 30px
    &__column
      display flex
      flex-direction column
      justify-content space-between
      &:first-child
        margin-bottom 24px
      &:last-child
        .top
          margin-bottom 24px
    &__tel
      margin 0
      height 32px
      font-size 24px
      font-weight 800
      line-height 1.33
    &__time-email
      line-height 1.29
      margin-bottom 16px
    &__lang
      margin-bottom 18px !important
      display flex
      align-items center
      line-height 1.43
    &__lang-li
      margin-right 16px
    &__lang-a
      &[data-active="true"]
        font-weight bold
      &[data-active="false"]
        color $grey_400
    &__info-address
      display none
    &__terms
      display flex
    &__terms-li
      display flex
      align-items center
      &:not(:first-child)::before
        display block
        margin 0 7px
        width 1px
        height 12px
        background-color #000
        content ''
    &__shop-name
      line-height 1.43
      font-weight 200
      color #828282 !important
  .plus-layout-sns
    display flex
    align-items center
    &__anchor
      display block
      width 30px
      height 30px
      margin-right 16px
      background-repeat no-repeat
      background-size 30px 30px
      &[data-has-sns="false"]
        display none
      &--fb
        background-image url("//s3.marpple.co/files/u_29089/2020/11/original/56c92d6bdd831d760eed3eed5878d2b4ca43e7bb1.png")
      &--tw
        background-image url("//s3.marpple.co/files/u_29089/2020/11/original/0e23ceee038fcbd948ba2ef8482b8fa0cb3cc4053.png")
      &--ig
        background-image url("//s3.marpple.co/files/u_29089/2020/11/original/3147850c39ba8de0cbf3bcb902c80eac9203567f2.png")
      &--yt
        background-image url("//s3.marpple.co/files/u_29089/2020/11/original/879c57c13b0eeaaff54ffe3eddc5e3a0ade115604.png")
  .plus-layout-powered
    line-height 1.43
    color $grey_400
    font-weight 200
    a
      color inherit !important
    &__toggle
      position absolute !important
      width 1px
      height 1px
      overflow hidden
      clip rect(1px, 1px, 1px, 1px)
    &__label
      ^[0]__toggle:checked ~ &
        & ^[0]__toggle-icon
          transform rotate(-180deg)
        & ^[0]__address
          display block
    &__toggle-icon
      display inline-block
      margin 0 0 0 5px
      width 7px
      height 14px
      border none
      background transparent url(//s3.marpple.co/files/u_1396787/2020/11/original/a4ea247628cda0478939bb4b34f59d583d6522e41.png) no-repeat center
      background-size 7px 4px
    &__address
      display none
      margin-top 12px
      &.is-show
        display block
      p, p a
        margin 0
        line-height 1.43
        font-size 14px
        font-weight 200
        color #979797
      p
        &:nth-child(3)
          display none
        a
          margin-left 4px
          text-decoration underline


  [lang=kr]
    .plus-layout-footer
      &__info-address
        p:first-child
          span:first-child
            margin-right 5px
    .plus-layout-powered
      &__address
        p:first-child
          span:first-child
            margin-right 5px
  [lang=en]
    .plus-layout-footer
      &__time-email
        line-height 1.5
        font-size 16px
        font-weight bold
  [lang=jp]
    .plus-layout-footer
      &__time-email
        line-height 1.5
        font-size 16px
        font-weight bold

@media screen and (min-width 1025px)
  .plus-layout-footer
    &__inner
      position relative
      margin 0 auto
      padding 48px 0
      max-width 1276px
      height 282px
      display flex
      justify-content space-between
      &::after
        position absolute
        left 0
        bottom 158px
        width 100%
        height 1px
        background-color #d8d8d8
        content ''
    &__column
      &:first-child
        margin-bottom 0
      &:last-child
        .bottom
          text-align right
    &__tel
      margin 0 0 3px 0
      height auto
      line-height 1.4
      font-size 40px
    &__lang
      margin-bottom 3px !important
    &__lang-li
      margin-right 16px
    &__info-address
      display block
      margin-top 21px
      p, p a
        margin 0
        line-height 1.29
        font-size 14px
        font-weight 200
        color #828282
      p
        &:nth-child(3)
          display none
        a
          margin-left 4px
          text-decoration underline
    &__shop-name
      line-height 1.29
  .plus-layout-sns
    justify-content flex-end
    &__anchor
      margin-right 20px
      &:last-child
        margin-right 0
  .plus-layout-powered
    &__address, &__toggle, &__label
      display none
  [lang=en]
    .plus-layout-footer
      &__time-email
        line-height 1.78
        font-size 18px
  [lang=jp]
    .plus-layout-footer
      &__time-email
        line-height 1.78
        font-size 18px

