@import "./color_selector.styl"

[frame_name="/modules/VectorEditor/SettingBackground/Mobile/S/Mui/frame.js"]
  > .don_wrapper
    > .header
      display flex
      width 100%
      justify-content center
      align-items flex-end
      .header_shadow
        width 100%
        position absolute
        bottom 0
        box-shadow 0 2px 7px 2px rgba(170 170 170 0.67)
      button[name="close_frame"]
        position relative
        background-color #FFFFFF
        border 0 none
        border-radius 2px 2px 0 0
        display inline-flex
        width 50px
        height 22px
        align-items center
        justify-content center
        box-shadow 0 -1px 10px 0px rgba(170 170 170 0.67)
    > .body
      z-index 1

[page_name="/modules/VectorEditor/SettingBackground/Mobile/S/Mui/page.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    > .body
      width 100%
      height 100%

[tab_name="/modules/VectorEditor/SettingBackground/Mobile/S/Mui/tab.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    overflow hidden

    .blank_plate
      position fixed
      top 0
      right 0
      bottom 0
      left 0

    .container
      position relative
      display flex
      flex-direction column
      justify-content space-between
      width 100%
      height 100%
      box-sizing border-box
      padding-top 24px
      padding-bottom 26px
      padding-left 34px
      padding-right 34px

      color-selector(100%, 100%)
