&
  width 100%

  .options
    text-align right
    .option_calendar-title
      display inline-block
      margin-left 10px

  .chart-wrapper
    margin-top 20px
