.sticker_etc_share_style
  .colors_wrapper
    .title
      margin-bottom 20px
    .colors
      .row
        margin-bottom 12px
        &:last-child
          margin-bottom 0
      .color
        width 28px
        height 28px
        border-radius 28px
        span
          width 24px
          height 24px
          border-radius 24px
          background-size 24px 24px
    .title
      text-align center
    .colors
      .color
        &[data-color_code="#ffffff"]
          span
            border 1px solid #ced4da
        border 1px solid transparent
        display flex
        justify-content center
        align-items center
        &.active
          border 1px solid #0157ff
      .row
        display flex
        justify-content space-between
