&
  width 100%
  min-height 100%
  display flex
  flex-direction column

  .header
    display flex
    width 100%
    height 4rem
    flex-shrink 0
    align-items center
    .page_title
      margin 0

  .chart-wrapper
    .logo
      width 5rem !important
    tbody
      tr
        height 4rem
      .logo
        text-align center
        padding 0
        .product_info
          display inline-flex
      .seller
        .user_email
          margin-left 0.4rem
      .seller .user_email,
      .shop_link,
      .confirmation_link
        a
          text-decoration underline

