.don_frame.half_frame_v_style
  >.don_wrapper
    >.body
      z-index 1 !important
      box-shadow 0 2px 7px 2px #aaaa
    >.header
      z-index 2 !important
      .title
        display none
  .don_page >.don_wrapper >.header::after
    content ' '
    display block
    position absolute
    bottom 0
    left 0
    right 0
    height 1px
    background-color #edeff2
  >.don_wrapper >.body
    z-index 2
  >.don_wrapper >.header
    z-index 1
    background transparent
    .title
      box-shadow 0 2px 7px 2px #aaaa
      position absolute
      top 30px
      min-height 30px
      width 100%
      background white
    .don_hide_frame
      transform translateX(-50%)
      left 50%
      position absolute
      width 50px
      height 22px
      text-align center
      border none
      top 9px
      box-shadow: 0 -1px 10px 0px #aaaa
      border-radius 2px 2px 0 0
      background #fff url("//s3.marpple.co/file/u_18053/2018/7/original/f_5654_1532605972210_fSO2BpOAb5g7y7S4Afgr.png") no-repeat 50% 54%
      background-size 10px 6px
      &:focus
        outline none
      span
        color transparent
      span:after
        background #fff
        width 100px
        height 10px
        bottom -9px
        position absolute
        left -24px
        content ""
.don_page.half_frame_one_tab_style
  .tab_buttons
    display block !important
    text-align center
    button
      position relative
      z-index 1
      height 48px
      margin 0 6%
      min-width 26px
      text-align center
      padding 0
      font-size 14px
      font-weight bold
      color #212529
      border-top 2px solid transparent
      border-bottom 2px solid transparent
      background-color transparent
      outline none
      border-radius 0

.don_page.half_frame_tab_style
  .tab_buttons
    width 100%
    display flex
    padding 0 24px
    height 48px
    box-sizing border-box
    .tab_button
      background-color transparent
      flex-grow 1
      position relative
      z-index 1
      text-align center
      width 100%
      border-radius 0
      box-sizing border-box
      border-top 1px solid transparent
      border-bottom 1px solid transparent
      color #868e96
      padding 0
      font-size 14px
      font-weight bold
      height 100%
      outline none
      &:active
        color color_black
      &[selected="true"]
        color #000
        border-bottom 1px solid #000
