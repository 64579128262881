@require 'crew/crew.banner.mixins.styl'
.don_frame[is_modal="true"].crew-banner-wrap
  & > .don_wrapper
    width 1200px
    height 720px
    border-radius 10px
    overflow hidden
    & > .header
      position absolute
      top 0
      left 0
      flexBox(flex, center, center)
      width 100%
      background-color white
      font-size 16px
    & > .body
      margin-top 56px
      padding-bottom 56px


.don_frame[is_modal="true"][frame_name="/modules/Creator/Crew/LiveEditor/S/Mui/frame.js"].crew-banner-wrap
  & > .don_wrapper
    width 1200px
    min-height 500px
    height fit-content
    & > .body
      margin-top 56px
      padding-bottom 0

html[is_plus="true"]
  .don_frame[is_modal="true"][frame_name="/modules/Creator/Crew/LiveEditor/S/Mui/frame.js"].crew-banner-wrap
    & > .don_wrapper
      width 1200px
      min-height 500px
      height fit-content
      & > .body
        margin-top 56px
        padding-bottom 0

.crew-banner-editor
  &__title-wrapper
    display flex
    align-items center
    gap pxToRem(8)
  &__desc
    {$font-body-b5-r}
    color $gray-500
  &__title
    resetSpace()
    {$font-body-b1-sb}
  &__sub-title
    resetSpace()
    display flex
    align-items center
    {$font-body-b3-sb}
    color #000
  &__description
    padding-left 8px
    {$font-body-b5-r}
    color $gray-500
    &--info
      display flex
      align-items center
      margin 10px 5px 0 0
      &::before
        icoInfo()
    &--reserv
      display none
      .is-show ~ &
        display flex
  &__section
    display flex
    width 100%
    &--half
      width 50%
  &__column
    padding 20px 40px
    width 50%
    &:first-child
      background-color #F8F8F8
    ~/__section:first-child > *
      padding 40px 40px 20px
    ~/__section:last-child > *
      padding 20px 40px 40px
    &--full
      justify-content flex-end
    &--live
      display flex !important
      flex-direction column
      gap 40px
    &--center
      display flex !important
      flex-direction column
      justify-content center
      gap 32px
    &--flex
      display flex !important
      flex-direction column
      gap 24px
  &__input-row
    display flex
    flex-direction column
    gap pxToRem(24)
    &--line
      padding-bottom 24px
      border-bottom 1px solid $gray-10
  &__input-preview-data
    display flex
    flex-direction column
    gap pxToRem(16)
  &__reservation
    display none
    contentsPadding()
    &.is-show
      display block
  &__textarea-box
    countryIcon()
  &__textarea
    resetTextarea()
    border 1px solid #EDEDED !important
    border-radius 2px
    width 100%
    height pxToRem(60)
    padding pxToRem(10) pxToRem(10) pxToRem(10) pxToRem(42)
    box-sizing border-box
    {$font-body-b4-r}
    &[rows="1"]
      height pxToRem(40)
    &[rows="2"]
      height pxToRem(60)
    &[rows="3"]
      height pxToRem(80)
    &:focus
      border 1px solid point-color2 !important
    &:placeholder
      color $gray-500
  &__label_container
    countryIcon()
  &__label
    inputStyle(100%, 40px)
  &__banner_theme
    display flex
    align-items center
    gap 10px
    & > label
      display flex
      align-items center
      gap pxToRem(8)
      cursor pointer
      & > span
        {$font-body-b4-m}
        color #4C4C4C
      & > input
        resetSpace()
  &__app-title
    margin-top 10px
    inputStyle(100%, 40px)
    display none
    &--show
      display block
  &__select
    inputStyle(100%, 40px)
    -webkit-appearance none
    -moz-appearance none
    appearance none
    padding-right 13px
    background url(//s3.marpple.co/files/u_1093672/2020/9/original/e71d8ec0fcd9087bd4c8790242b155b503785d734.png) no-repeat top 50% right 13px;
    background-size auto 40%
    &::-ms-expand
      display none
  &__input
    inputStyle(180px, 40px)
  &__radio
    margin-left 20px
    &:first-child
      margin-left 0
  &__divide
    padding 0 11px
    font(14px, 500, -.5px, 22px, black)
  &__buttons
    display flex
    align-items center
    justify-content flex-end
  &__delete
    margin 0 5px
    whiteButton()
  &__submit
    margin 0 5px
    submitButton()
  &__inner-box
    width 100%
    display flex
    flex-direction column
    gap pxToRem(16)
  &__inner-column
    width 100%
    display flex
    flex-direction column
    gap pxToRem(8)
    &[data-is_hidden="true"]
      display none

.crew-banner-preview
  position relative
  width 100%
  &__input-title-container
    display inline-flex
    flex-direction column
    align-content center
    justify-content center
    text-align center
  &__input-title
    {$font-body-b3-m}
    color $gray-80
  &__input-desc
    {$font-body-b5-r}
    color $gray-50
  &--flex
    display flex
    flex-direction column
    gap 8px
  &--mobile
    .crew-banner-preview__container
      width 375px
      aspect-ratio 750/1000
  &__container
    display flex
    flex-direction column
    justify-content center
    align-items center
    width 100%
    aspect-ratio 2880/1110
    position relative
    &--no-image
      & ~ .crew-banner-preview__text-box
        display none
      border 1px dashed $gray-20
      padding pxToRem(48)
      border-radius 8px
  &__image-box
    width 100%
    height 100%
    display flex
    align-items center
    overflow hidden
    border-radius 6px
    z-index 1
    &--hide
      display none
  &__image
    width 100%
    height 100%
    vertical-align top
    object-fit cover
  &__file
    blind()
  &__title
    limitLine(2)
    {$font-heading-h3-b}
    &--aspect-ratio1
      margin-bottom 4px
    ~/--no-image &
      display none
  &__category
    limitLine(1)
    {$font-body-b5-m}
    ~/--no-image &
      display none
  &__text
    {$font-body-b3-r}
    color $gray-600
    text-align center
    margin-top pxToRem(16)
  &__blind-text
    blind()
  &__no-image
    flexBox(flex, center, center, column)
    cursor pointer
    &--hide
      display none
  &__add
    addButton()
  &__delete
    position absolute
    top pxToRem(12)
    right pxToRem(12)
    width pxToRem(36)
    height pxToRem(36)
    border-radius 50%
    text-indent -999em
    border none
    background url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' fill='none' viewBox='0 0 36 36'%3E%3Ccircle cx='18' cy='18' r='18' fill='%23111' fill-opacity='.45'/%3E%3Cpath stroke='%23F8F8F8' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='m12 14.5.92 10.587a1 1 0 0 0 .997.913h8.166a1 1 0 0 0 .996-.913L24 14.5'/%3E%3Crect width='15.4' height='.6' x='10.3' y='11.8' stroke='%23F8F8F8' stroke-width='.6' rx='.3'/%3E%3Cmask id='a' fill='%23fff'%3E%3Crect width='6' height='3.2' x='15' y='9.5' rx='1'/%3E%3C/mask%3E%3Crect width='6' height='3.2' x='15' y='9.5' stroke='%23F8F8F8' stroke-width='2.4' mask='url(%23a)' rx='1'/%3E%3Crect width='1.2' height='7' x='15.75' y='16' fill='%23F8F8F8' rx='.6'/%3E%3Crect width='1.2' height='7' x='19.15' y='16' fill='%23F8F8F8' rx='.6'/%3E%3C/svg%3E%0A")
    z-index 3
    ~/--no-image &
      display none
    &--hide
      display none


.crew-color-picker
  display flex
  align-items center
  cursor pointer
  border 1px solid #EDEDED !important
  border-radius 2px
  padding pxToRem(10)
  width 100%
  height pxToRem(40)
  &__input
    outline 0
    padding 0
    margin-left pxToRem(12)
    border none !important
    border-radius 0 !important
    cursor pointer
    {$font-body-b4-r}
  &__bullet
    display block
    width pxToRem(18px)
    height pxToRem(18px)
    border 1px solid #ededed
    border-radius 50%

.crew-banner-background-picker
  display flex
  align-items center
  cursor pointer
  border 1px solid #EDEDED !important
  border-radius 2px
  padding pxToRem(10)
  width 100%
  height pxToRem(40)
  &__input
    outline 0
    padding 0
    margin-left pxToRem(12)
    border none !important
    border-radius 0 !important
    cursor pointer
    {$font-body-b4-r}
  &__bullet
    display block
    width pxToRem(18px)
    height pxToRem(18px)
    border 1px solid #ededed
    border-radius 50%


html.crew-admin-banner
  .flatpickr-calendar
    position fixed

.plus-crew-banner-editor
  &__form
    display inline-flex
    width 100%
    height 664px
  &__section
    flex 1 1 0
    display flex
    flex-direction column
    width 100%
    height 100%
    padding pxToRem(40)
    &--preview
      background-color $gray-100
  &__column
    padding 20px 40px
    width 50%
  &__input-preview-data
    display flex
    flex-direction column
    gap pxToRem(8)
  &__row
    line-height 0
  &__url
    display flex
    width 100%
    padding pxToRem(10)
    border-radius pxToRem(2)
    border 1px solid #ededed
    background-color $white
    outline none
    {$font-body-b4-r}
    color $gray-800
  &__title
    padding 0
    margin 0
    {$font-body-b1-sb}
  &__sub-title
    padding 0
    margin 0
    {$font-body-b3-sb}
  &__select
    inputStyle(100%, 40px)
    -webkit-appearance none
    -moz-appearance none
    appearance none
    padding-right 13px
    background url(//s3.marpple.co/files/u_1093672/2020/9/original/e71d8ec0fcd9087bd4c8790242b155b503785d734.png) no-repeat top 50% right 13px;
    background-size auto 40%
    &::-ms-expand
      display none
  &__input-wrapper
    display flex
    align-items center
    {$font-body-b4-r}
    color $gray-800
    gap pxToRem(12)
  &__input
    display flex
    width pxToRem(180)
    padding pxToRem(10)
    border-radius pxToRem(2)
    border 1px solid #ededed
    background-color $white
    outline none
  &__radio
    margin-left 20px
    &:first-child
      margin-left 0
  &__buttons
    position absolute
    right pxToRem(40)
    bottom pxToRem(40)
    display flex
    align-items center
    justify-content flex-end
    gap pxToRem(8)
  &__delete
  &__submit
    display flex
    align-items center
    justify-content center
    width pxToRem(132)
    height pxToRem(45)
    gap pxToRem(8)
    border-radius pxToRem(2)
    {$font-body-b3-m}
    border none
    padding 0
  &__delete
    border 1px solid $gray-300
    background-color $white
    color $gray-700
  &__submit
    background-color $ss-sky
    color $white !important
    &::before
      display block
      margin-right pxToRem(8)
      width pxToRem(12)
      height pxToRem(8)
      background url(//s3.marpple.co/files/u_1093672/2020/9/original/17dfcb4713cfa10c1f97391cd0a67d3af46f10521.png) no-repeat 0 0
      background-size 100%
      content ''
  &__inner-box
    width 100%
    display flex
    flex-direction column
    gap pxToRem(16)
  &__inner-column
    width 100%
    display flex
    flex-direction column
    gap pxToRem(8)
    &[data-hidden="true"]
      display none
  &__contents
    display flex
    flex-direction column
    gap pxToRem(8)
  &__description
    {$font-body-b3-r}
    color $gray-600

  &__thumbnail
    display none
  &__thumbnail-label
    display flex
    align-items center
    gap pxToRem(8)
  &__thumbnail-name
    flex 1 1 0
    cursor pointer
    padding pxToRem(10)
    border-radius pxToRem(2)
    border 1px solid #ededed
    background-color $white
    {$font-body-b4-r}
    color $gray-500
  &__thumbnail-button
    cursor pointer
    display flex
    align-items center
    justify-content center
    flex-shrink 0
    width pxToRem(100)
    height pxToRem(40)
    border-radius pxToRem(2)
    background-color #3c89f9
    {$font-body-b4-r}
    color $white

  &__youtube-label
    display flex
    align-items center
    gap pxToRem(8)
    width 100%
  &__youtube
    flex 1 1 0
    display flex
    padding pxToRem(10)
    border-radius pxToRem(2)
    border 1px solid #ededed
    background-color $white
    outline none
  &__youtube-apply
    cursor pointer
    flex-shrink 0
    display flex
    align-items center
    justify-content center
    border-radius pxToRem(2)
    border none
    background-color #3c89f9
    {$font-body-b4-r}
    color $white !important
    padding 0
    margin 0
    width pxToRem(100)
    height pxToRem(40)

  &__file
    display flex
    flex-direction column
    gap pxToRem(16)
  &__file-delete-icon
    width pxToRem(24)
    height pxToRem(24)
  &__file-delete
    position absolute
    display flex
    align-items center
    justify-content center
    top pxToRem(16)
    right pxToRem(16)
    margin 0
    padding 0
    border none
    width pxToRem(32)
    height pxToRem(32)
    background-color $dim-30
    border-radius 99px
  &__file-notice
    width 100%
    display flex
    flex-direction column
    padding pxToRem(20)
    border-radius pxToRem(6)
    background-color $gray-200
    gap pxToRem(8)
  &__file-notice-row
    display flex
    align-items flex-start
    gap pxToRem(16)
  &__file-notice-title
    flex-shrink 0
    {$font-body-b4-b}
    color $gray-700
  &__file-notice-desc
    {$font-body-b4-r}
    display flex
    gap pxToRem(4) pxToRem(12)
    flex-wrap wrap
    > span
      padding 0 pxToRem(12) 0 0
      border-right 1px solid #D9D9D9
      &:last-child
        border none
        padding 0
  &__file-input
    display none
  &__file-preview
    width 100%
    padding-bottom: 56.25%
    border-radius pxToRem(6)
    background-color $black
    overflow hidden
    position relative
    > video
    > img
    > iframe
      position absolute
      top 0
      width 100%
      height 100%
      object-fit cover
    > iframe
    > video
      object-fit contain
  &__file-banner
    display flex
    flex-direction column
    align-items center
    justify-content center
    gap pxToRem(24)
    padding pxToRem(48) pxToRem(40)
    border-radius pxToRem(6)
    border 1px dashed #D9D9D9
  &__file-input-label
    cursor pointer
    display flex
    flex-direction column
    align-items center
    justify-content center
    gap pxToRem(16)
  &__file-input-icon
    width pxToRem(60)
    height pxToRem(60)
  &__file-input-desc
    {$font-body-b3-r}
    color #4C4C4C
    text-align center

.plus-crew-radio
  display flex
  align-items center
  gap pxToRem(36)
  &__input
    padding 0
    margin 0
  &__box
    display flex
    align-items center
    gap pxToRem(8)
  &__label
    {$font-body-b3-m}
    color #4C4C4C
