[tab_name="/modules/VectorEditor/Keyring/Grid/PC/S/Mui/tab.js"]
[tab_name="/modules/VectorEditor/Keyring/Free/PC/S/Mui/tab.js"]
[tab_name="/modules/VectorEditor/Sticker/Single/PC/S/Mui/tab.js"]
[tab_name="/modules/VectorEditor/AcrylicFigure/Free/PC/S/Mui/tab.js"]
  .rc_wrapper
    .rc_header
      margin 0 40px 40px 40px
    .rc_contents
      padding 40px
[tab_name="/modules/VectorEditor/Keyring/Grid/CreatorPC/S/Mui/tab.js"]
[tab_name="/modules/VectorEditor/Keyring/Free/CreatorPC/S/Mui/tab.js"]
[tab_name="/modules/VectorEditor/Sticker/Single/CreatorPC/S/Mui/tab.js"]
[tab_name="/modules/VectorEditor/AcrylicFigure/Free/CreatorPC/S/Mui/tab.js"]
  .rc_wrapper
    .rc_header
      margin 0 30px 30px 30px
    .rc_contents
      padding 30px
  .colors_wrapper
    .colors
      .row:first-child
        margin-bottom 12px
      .color
        width 24px
        height 24px
        border-radius 24px
        span
          width 20px
          height 20px
          border-radius 20px
          background-size 20px 20px
[tab_name="/modules/VectorEditor/Keyring/Grid/PC/S/Mui/tab.js"]
[tab_name="/modules/VectorEditor/Keyring/Free/PC/S/Mui/tab.js"]
[tab_name="/modules/VectorEditor/Sticker/Single/PC/S/Mui/tab.js"]
[tab_name="/modules/VectorEditor/AcrylicFigure/Free/PC/S/Mui/tab.js"]
[tab_name="/modules/VectorEditor/Keyring/Grid/CreatorPC/S/Mui/tab.js"]
[tab_name="/modules/VectorEditor/Keyring/Free/CreatorPC/S/Mui/tab.js"]
[tab_name="/modules/VectorEditor/Sticker/Single/CreatorPC/S/Mui/tab.js"]
[tab_name="/modules/VectorEditor/AcrylicFigure/Free/CreatorPC/S/Mui/tab.js"]
  .rc_wrapper
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    overflow hidden
    *
      box-sizing border-box
    .rc_header
      position relative
      text-align center
      .title
        font-size 19px
      .back
        position absolute
        left 0
        top 50%
        transform translateY(-50%)
        background-color transparent
        background-image url(//s3.marpple.co/files/u_1187077/2021/5/original/2aac30aabf4af156704b95903af308892eefb75a1.png)
        width 19px
        height 16px
        margin 0
        padding 0
        cursor pointer
    .rc_tabs
      display flex
      justify-content center
      position relative
      &:after
        content ' '
        display block
        position absolute
        bottom 0
        left 0
        right 0
        height 1px
        background #d5dbe0
      .tab
        font-size 16px
        z-index 1
        padding 16px 0
        text-align center
        color #868e96
        width 190px
        cursor pointer
    .rc_contents
      display none
    &[data-current_tab="font_tab"]
      [data-tab_name="font_tab"]
        border-bottom 1px solid #000
      .rc_contents.font_tab_contents
        display block
    &[data-current_tab="color_tab"]
      [data-tab_name="color_tab"]
        border-bottom 1px solid #000
      .rc_contents.color_tab_contents
        display block
    &[data-current_tab="border_tab"]
      [data-tab_name="border_tab"]
        border-bottom 1px solid #000
      .rc_contents.border_tab_contents
        display block
    .rc_contents
      .content
        margin-bottom 24px
      .title
        font-size 14px
        margin-bottom 16px
    .font_tab_contents
      input[data-key="letter-spacing"]
        background url(//s3.marpple.co/file/guest/2017/11/original/f_942_1511790749677_b4hIqa7TmwJq1TK4W1fF.svg) no-repeat 6px 50%
        box-sizing border-box
        position absolute
        -webkit-appearance none
        margin 0
        padding 0
        text-align center
        background-color #fff
        box-shadow none
        border none
      .select_font
        height 40px
        margin-bottom 8px
      input[data-key="innerHTML"]
        border 1px solid #d5dbe0
        width 100%
        height 40px
        outline none
        padding 12px
        font-size 14px
      .font_style_wrapper
        border 1px solid #d5dbe0
        width 209px
        height 40px
  #select_fonts_app
    position absolute
    overflow hidden
    height 386px
    border solid 1px #d5dbe0
    border-top none
    background #fff
    box-sizing border-box
    *
      box-sizing border-box
    .fonts
      width 100%
      .font
        padding 13px 10px 13px 16px
        img
          height 14px
        &:hover
          background #d5dbe0
      .font.selected
        .off
          display none
        .on
          display block
      .font:not(.selected)
        .off
          display block
        .on
          display none
