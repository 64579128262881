[frame_name="/modules/VectorEditor/Sticker/Single/Mobile/PathEditor/S/Mui/frame.js"]
  height 0
  width 0

[tab_name="/modules/VectorEditor/Sticker/Single/Mobile/PathEditor/S/Mui/tab.js"]
  user-select none
  > .don_wrapper
    .top_menu_container
      position fixed
      top 0
      left 0
      right 0
      height 48px
      background-color #FFFFFF
      display flex
      justify-content flex-end
      align-items center
      padding 0 6px
      box-shadow 0 3px 4px rgba(230, 230, 230, 0.4)
      transition transform 0.4s
      &[data-is_show="false"]
        transform translateY(-48px)
      &[data-is_show="true"]
        transform translateY(0)
      .done
        background-color transparent
        border 0 none
        width 58px
        height 33px

    .outer_container
      position fixed
      bottom 0
      left 0
      right 0
      width 100%
      height 144px
      box-sizing border-box
      overflow auto
      background-color #FFFFFF
      border-top 1px solid #E9ECEF
      display flex
      align-items center
      padding 24px 20px
      transition transform 0.4s
      &[data-is_show="false"]
        transform translateY(100%)
      &[data-is_show="true"]
        transform translateY(0)
      .bottom_menu
        width 80px
        height 100%
        padding 0 4px
        display flex
        flex-direction column
        align-items center
        justify-content space-between
        .bottom_menu_name
          color #868E96
          font-size 12px
        .bottom_menu_icon
          width 72px
          height 72px
          box-sizing border-box
          border-width 1px
          border-style solid
          border-color #D5DBE0
          border-radius 50%
          background-color #FFFFFF
          background-repeat no-repeat
          background-size 22px 22px
          background-position center center
        &:active
          .bottom_menu_name
            color #0157FF
          .bottom_menu_icon
            box-shadow: 0 4px 16px 0 rgba(178, 215, 255, 0.5)
            border-color #0157FF
        &.add_l
          .bottom_menu_icon
            background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/normal/ic-path-line@2x.png")
        &:active.add_l
          .bottom_menu_icon
            background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/pressed-selected/ic-path-line-sel@2x.png")
        &.add_c
          .bottom_menu_icon
            background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/normal/ic-path-curve@2x.png")
        &:active.add_c
          .bottom_menu_icon
            background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/pressed-selected/ic-path-curve-sel@2x.png")
        &.remove
          .bottom_menu_icon
            background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/normal/ic-path-del@2x.png")
        &:active.remove
          .bottom_menu_icon
            background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/pressed-selected/ic-path-del-sel@2x.png")
        &.change_to_l
          .bottom_menu_icon
            background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/normal/ic-replace-line@2x.png")
        &:active.change_to_l
          .bottom_menu_icon
            background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/pressed-selected/ic-replace-line-sel@2x.png")
        &.change_to_c
          .bottom_menu_icon
            background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/normal/ic-replace-curve@2x.png")
        &:active.change_to_c
          .bottom_menu_icon
            background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/pressed-selected/ic-replace-curve-sel@2x.png")
        &.close_or_open
          .bottom_menu_icon
            background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/normal/ic-path-close@2x.png")
        &:active.close_or_open
          .bottom_menu_icon
            background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/pressed-selected/ic-path-close-sel@2x.png")
      .close_or_open
        &[data-is_closed="true"]
          .close
            display none
        &[data-is_closed="false"]
          .open
            display none
