top-menus()
  .top_menus
    position absolute
    width 100%
    height 100%
    display flex
    align-items center
    justify-content space-evenly
    .top_menu
      display inline-flex
      width 40px
      height 46px
      flex-direction column
      align-items center
      justify-content center
      margin 0
      padding 0
      border 0 none
      background none
      .top_menu_icon
        width 18px
        height 18px
        display inline-block
        background-repeat no-repeat
        background-position center center
        background-size 18px auto
      .top_menu_label
        overflow visible
        font-size 10px
        white-space nowrap
        color #000000
        margin-top 5px
      &:active
        background-color #CED4DA
      &:disabled
        opacity 0.2
        cursor not-allowed
      &:not(:last-child)
        margin-right 10px
      &[data-is_show="false"]
        display none

      &[data-top_menu_id="history_reset"]
        .top_menu_icon
          background-image url("//s3.marpple.co/vector-editor-assets/pc/top-bar/ic-reset@2x.png")
      &[data-top_menu_id="history_undo"]
        .top_menu_icon
          background-image url("//s3.marpple.co/vector-editor-assets/pc/top-bar/ic-undo@2x.png")
      &[data-top_menu_id="history_redo"]
        .top_menu_icon
          background-image url("//s3.marpple.co/vector-editor-assets/pc/top-bar/ic-redo@2x.png")
      &[data-top_menu_id="toggle_layers_mode"]
        .top_menu_icon
          background-image url("//s3.marpple.co/vector-editor-assets/pc/top-bar/ic-layer@2x.png")
      &[data-top_menu_id="duplicate"]
        .top_menu_icon
          background-image url("//s3.marpple.co/vector-editor-assets/pc/top-bar/ic-duplicate@2x.png")
      &[data-top_menu_id="delete"]
        .top_menu_icon
          background-image url("//s3.marpple.co/vector-editor-assets/pc/top-bar/ic-trash@2x.png")
      &[data-top_menu_id="forward"]
        .top_menu_icon
          background-image url("//s3.marpple.co/vector-editor-assets/pc/top-bar/ic-forward@2x.png")
      &[data-top_menu_id="backward"]
        .top_menu_icon
          background-image url("//s3.marpple.co/vector-editor-assets/pc/top-bar/ic-backward@2x.png")
      &[data-top_menu_id="group"]
        .top_menu_icon
          background-image url("//s3.marpple.co/vector-editor-assets/pc/top-bar/ic-group@2x.png")
      &[data-top_menu_id="ungroup"]
        .top_menu_icon
          background-image url("//s3.marpple.co/vector-editor-assets/pc/top-bar/ic-ungroup@2x.png")
      &[data-top_menu_id="flip_horizontal"]
        .top_menu_icon
          background-image url("//s3.marpple.co/vector-editor-assets/pc/top-bar/ic-flip-h@2x.png")
      &[data-top_menu_id="flip_vertical"]
        .top_menu_icon
          background-image url("//s3.marpple.co/vector-editor-assets/pc/top-bar/ic-flip-v@2x.png")
      &[data-top_menu_id="move_to_left_end"]
        .top_menu_icon
          background-image url("//s3.marpple.co/vector-editor-assets/pc/top-bar/ic-align-3@2x.png")
      &[data-top_menu_id="move_to_horizontal_center"]
        .top_menu_icon
          background-image url("//s3.marpple.co/vector-editor-assets/pc/top-bar/ic-align-4@2x.png")
      &[data-top_menu_id="move_to_right_end"]
        .top_menu_icon
          background-image url("//s3.marpple.co/vector-editor-assets/pc/top-bar/ic-align-5@2x.png")
      &[data-top_menu_id="move_to_top_end"]
        .top_menu_icon
          background-image url("//s3.marpple.co/vector-editor-assets/pc/top-bar/ic-align-6@2x.png")
      &[data-top_menu_id="move_to_vertical_center"]
        .top_menu_icon
          background-image url("//s3.marpple.co/vector-editor-assets/pc/top-bar/ic-align-7@2x.png")
      &[data-top_menu_id="move_to_bottom_end"]
        .top_menu_icon
          background-image url("//s3.marpple.co/vector-editor-assets/pc/top-bar/ic-align-8@2x.png")
