&
  width 100%
  overflow hidden

  @import "../plus/plus.crew_arrange.styl"
  @import "crew.event.common.styl"

  .tab_headers .feature
    display flex
    align-items center

    .icon-drag
      width 34px
      margin-right 10px

  .plus-page-header .feature
    vertical-align bottom
    .btn-submit
      display inline-flex
      align-items center
      img
        height 8px
        margin-right 10px

  .feed_card
    &.sortable_drag_ghost
      visibility hidden
    &.sortable_drag_on
      .text, .public_date
        display none
