[page_name="/modules/VectorEditor/Keyring/Free/Mobile/S/Mui/page.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    > .body
      width 100%
      height 100%

[tab_name="/modules/VectorEditor/Keyring/Free/Mobile/S/Mui/tab.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    overflow hidden

    .icon_menu
      display inline-flex
      flex-direction column
      width 45px
      height 62px
      align-items center
      justify-content space-between
      .icon
        display inline-flex
        width 45px
        height 45px
        align-items center
        justify-content center
        border-radius 50%
        border 0 none
        background-color #FFFFFF
        background-position center center
        background-size 25px 25px
        background-repeat no-repeat
        padding 0
        margin 0
        box-shadow 0 2px 6px 0 rgba(0, 0, 0, 0.1)
        &:active
          background-color #0157FF
      .label_text
        overflow visible
        font-size 10px
        white-space nowrap

    .small_icon_menu
      display inline-block
      background-color transparent
      .icon
        display inline-block
        background-repeat no-repeat
        background-position center center
        background-size 18px 18px
        background-color transparent
        border 0 none
        padding 0
        margin 0
        &:active
          background-color #CED4DA
        &:disabled
          opacity 0.2
        &:disabled + span
          opacity 0.2
      &[data-icon_type="more"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-more@2x.png")
      &[data-icon_type="reset"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-reset@2x.png")
      &[data-icon_type="undo"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-undo@2x.png")
      &[data-icon_type="redo"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-redo@2x.png")
      &[data-icon_type="layers"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-layer@2x.png")
        .icon:active
          background-color transparent
      &[data-icon_type="layers"][data-is_active="true"]
        background-color #0157FF
        box-shadow inset 4px 4px 4px 0 #0048D3;
        border solid 1px #0157FF;
        border-radius 2px
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-layer-sel@2x.png")
      &[data-icon_type="trash"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-trash@2x.png")
      &[data-icon_type="forward"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-forward@2x.png")
      &[data-icon_type="backward"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-backward@2x.png")
      &[data-icon_type="group"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-group@2x.png")
      &[data-icon_type="ungroup"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-ungroup@2x.png")
      &[data-icon_type="lock"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-locked@2x.png")
      &[data-icon_type="unlock"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-unlocked@2x.png")
      &[data-icon_type="flip_h"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-flip-h@2x.png")
      &[data-icon_type="flip_v"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-flip-v@2x.png")
      &[data-icon_type="group_left"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-align-3@2x.png")
      &[data-icon_type="group_center_h"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-align-4@2x.png")
      &[data-icon_type="group_right"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-align-5@2x.png")
      &[data-icon_type="group_top"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-align-6@2x.png")
      &[data-icon_type="group_center_v"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-align-7@2x.png")
      &[data-icon_type="group_bottom"]
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/top-bar/ic-align-8@2x.png")
      .label_text
        font-size 10px
      &[data-icon_type="unlock"]
        .lock_count
          span
            display inline-flex
            width 15px
            height 15px
            justify-content center
            align-items center
            position absolute
            top -6px
            right 0
            background-color #0157FF
            color #FFFFFF
            border-radius 50%
            font-size 10px
            opacity 0.9

    .blank_plate
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      &[data-is_show="false"]
        display none

    .editor_container
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      background-color #F8F9FA

    .header
      display flex
      justify-content space-between
      align-items center
      padding 0 6px
      position absolute
      width 100%
      height 48px
      box-sizing border-box
      border-bottom 1px solid #E9ECEF
      background-color #FFFFFF
      transform translateY(0)
      &[data-is_show="true"]
        transform translateY(0)
      &[data-is_show="false"]
        transform translateY(-48px)
      button
        width 58px
        height 32px
        border 0 none
        background-color transparent
        &[data-is_show="true"]
          display inline-block
        &[data-is_show="false"]
          display none
      .icons
        display inline-flex
        width 0
        height 100%
        flex-grow 1
        justify-content space-between
        align-items center
        padding 0 16px
        .small_icon_menu
          width 32px
          height 32px
          position relative
          &::after
            content ""
            display block
            padding-bottom 100%
          .icon
            position absolute
            width 100%
            height 100%

    .footer
      position absolute
      bottom 16px
      html.safe_area_inset_bottom &
        bottom 26px
      transform translateY(0)
      transition transform 0.4s
      &[data-is_show="true"]
        transform translateY(0)
      &[data-is_show="false"]
        transform translateY(78px)
      html.safe_area_inset_bottom &[data-is_show="false"]
        transform translateY(88px)
      &.none[data-icon_type="add"]
        right 16px
        .icon
          transition-property background-color
          transition-duration 0.5s
          position relative
          overflow hidden
          &:active
            background-color #FFFFFF
          .active_icon
            position absolute
            width 100%
            height 100%
            background-color transparent
            background-repeat no-repeat
            background-size 25px 25px
            background-position center center
            background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-close@2x.png")
            opacity 0
            transition-property opacity, transform
            transition-duration 0.5s
            transform rotate(-45deg)
          .inactive_icon
            position absolute
            width 100%
            height 100%
            background-color transparent
            background-repeat no-repeat
            background-size 25px 25px
            background-position center center
            background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-plus@2x.png")
            opacity 1
            transition-property opacity, transform
            transition-duration 0.5s
            transform rotate(0)
        &[data-is_active="true"]
          .icon
            background-color #000000
            .active_icon
              opacity 1
              transform rotate(0)
            .inactive_icon
              opacity 0
              transform rotate(45deg)
      &.text[data-icon_type="decorate_text"]
        left calc(50% - 12px - 45px - 24px - 45px)
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-textedit@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-textedit-pre@2x.png")
      &.text[data-icon_type="open_keyboard"]
        left calc(50% - 12px - 45px)
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-keyboard@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-keyboard-pre@2x.png")
      &.text[data-icon_type="duplicate"]
        left calc(50% + 12px)
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-duplicate@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-duplicate-pre@2x.png")
      &.text[data-icon_type="delete"]
        left calc(50% + 12px + 45px + 24px)
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-delete@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-delete-pre@2x.png")
      &.path[data-icon_type="set_color"]
        left calc(50% - 22.5px - 24px - 45px - 24px - 45px)
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-color@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-color-pre@2x.png")
      &.path[data-icon_type="set_stroke"]
        left calc(50% - 22.5px - 24px - 45px)
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-border@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-border-pre@2x.png")
      &.path[data-icon_type="edit_path"]
        left calc(50% - 22.5px)
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-path@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-path-pre@2x.png")
      &.path[data-icon_type="duplicate"]
        left calc(50% + 22.5px + 24px)
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-duplicate@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-duplicate-pre@2x.png")
      &.path[data-icon_type="delete"]
        left calc(50% + 22.5px + 24px + 45px + 24px)
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-delete@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-delete-pre@2x.png")
      &.shape[data-icon_type="set_color"]
        left calc(50% - 12px - 45px - 24px - 45px)
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-color@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-color-pre@2x.png")
      &.shape[data-icon_type="set_stroke"]
        left calc(50% - 12px - 45px)
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-border@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-border-pre@2x.png")
      &.shape[data-icon_type="duplicate"]
        left calc(50% + 12px)
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-duplicate@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-duplicate-pre@2x.png")
      &.shape[data-icon_type="delete"]
        left calc(50% + 12px + 45px + 24px)
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-delete@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-delete-pre@2x.png")
      &.object[data-icon_type="duplicate"]
        left calc(50% - 12px - 45px)
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-duplicate@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-duplicate-pre@2x.png")
      &.object[data-icon_type="delete"]
        left calc(50% + 12px)
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-delete@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-delete-pre@2x.png")
      @media screen and (max-width 320px)
        &.path[data-icon_type="set_color"]
          left calc(50% - 22.5px - 12px - 45px - 12px - 45px)
        &.path[data-icon_type="set_stroke"]
          left calc(50% - 22.5px - 12px - 45px)
        &.path[data-icon_type="edit_path"]
          left calc(50% - 22.5px)
        &.path[data-icon_type="duplicate"]
          left calc(50% + 22.5px + 12px)
        &.path[data-icon_type="delete"]
          left calc(50% + 22.5px + 12px + 45px + 12px)

    > .top_menu_container
      position absolute
      top 8px
      right 16px
      left 16px
      height 163px
      display flex
      flex-direction column
      justify-content space-around
      background-color #FFFFFF
      padding 0 24px
      box-shadow 0 0 10px 0 rgba(0, 0, 0, 0.1)
      &[data-is_show="false"]
        display none
      .top_menu_line
        width 100%
        display inline-flex
        justify-content space-between
      .small_icon_menu
        width 32px
        height 32px
        display inline-flex
        flex-direction column
        justify-content space-between
        align-items center
        position relative
        .icon
          position relative
          width 100%
          height 100%
          background-position top center
        .label_text
          position absolute
          bottom 0
          width 100%
          display inline-flex
          justify-content center
          overflow visible
          white-space nowrap

    > .bottom_menu
      position absolute
      right 16px
      transition-property transform, bottom, opacity
      transition-duration 0.5s
      .icon
        background-color #FFFFFF
        background-position center center
        background-repeat no-repeat
        background-size 25px 25px
        &:active
          background-color #CED4DA
      &[data-is_show="true"]
        bottom 16px
      html.safe_area_inset_bottom &[data-is_show="true"]
        bottom 26px
      &[data-is_show="false"]
        bottom -78px
      &[data-is_active="true"]
        opacity 1
      &[data-is_active="false"]
        opacity 0
    > .bottom_menu[data-icon_type="image"]
      .icon
        background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-upload@2x.png")
      .icon:active
        background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-upload-pre@2x.png")
      &[data-is_active="true"]
        transform translateY(-288px)
      &[data-is_active="false"]
        transform translateY(0)
    > .bottom_menu[data-icon_type="shape"]
      .icon
        background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-figure@2x.png")
      .icon:active
        background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-design-pre@2x.png")
      &[data-is_active="true"]
        transform translateY(-216px)
      &[data-is_active="false"]
        transform translateY(0)
    > .bottom_menu[data-icon_type="path"]
      .icon
        background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-path@2x.png")
      .icon:active
        background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-path-pre@2x.png")
      &[data-is_active="true"]
        transform translateY(-144px)
      &[data-is_active="false"]
        transform translateY(0)
    > .bottom_menu[data-icon_type="text"]
      .icon
        background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-text@2x.png")
      .icon:active
        background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-text-pre@2x.png")
      &[data-is_active="true"]
        transform translateY(-72px)
      &[data-is_active="false"]
        transform translateY(0)

@import "../../CuttingLineAuto/F/Style/style.styl"
@import "../../CuttingLineManual/F/Style/style.styl"
@import "../../ElementLayer/F/Style/style.styl"
@import "../../PathEditor/F/Style/style.styl"
