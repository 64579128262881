&
  width 100%
  min-height 100%
  display flex
  flex-direction column

  h2
    flex-shrink 0
    width 100%
    height 4rem
    margin 0
    display flex
    align-items center

  .body
    width 100%
    .filter_container
      display flex
      align-items center
      margin 30px 0

      justify-content space-between

      button[name="date"], button[name="approve"], button[name="request"], span[name="without_fee_amount"]
        display inline-flex
        align-items center
        i
          margin-right 8px

      span[name="without_fee_amount"]
        margin-right 30px

      .filter
        margin-left 30px
    .chart-wrapper
      width 100%

  #withdraws
    .feature
    .status
      text-align center
      padding 0
