&
  width 100%
  min-height 100%
  display flex
  flex-direction column

  h2
    flex-shrink 0
    width 100%
    height 4rem
    margin 0
    display flex
    align-items center

  .body
    width 100%
    .filter_container
      margin 30px 0
      button[name="date"], button[name="approve"]
        display inline-flex
        float right
        align-items center
        i
          margin-right 8px

      .filter
        margin-left 30px
    .chart-wrapper
      width 100%

  #withdraws
    .feature
    .status
      text-align center
      padding 0
