html.is_pc_size
  .don_page[page_name="@PAGE/Creator/Setting/composite_template_selection"]
    .don_tab[tab_name="@TAB/Creator/Setting/composite_template_selection"]
      >.don_wrapper
        display flex
        flex-direction column
        justify-content center
      .assoc_composite_templates
        overflow-y auto
        padding 40px
        display grid
        grid-template-columns 1fr 1fr 1fr
        row-gap 40px
        column-gap 40px
      .option
        padding 40px 0 40px 0
html.is_mobile_size
  .don_page[page_name="@PAGE/Creator/Setting/composite_template_selection"]
    .assoc_composite_templates
      display flex
      padding 0 1% 120px 1%
    .option
      position absolute
      bottom 0
      width 100%
      height 120px
.don_page[page_name="@PAGE/Creator/Setting/composite_template_selection"]
  .don_wrapper
  .don_tab
    &.hidden
      visibility hidden
    >.body
      height 100%
  .option
    display flex
    justify-content center
    button.next
      font-size 14px
      background #212529
      color #fff
      height 56px
      width 200px
      border 1px solid transparent
      html.safe_area_inset_bottom &
        box-sizing content-box
        padding-bottom 34px
  .assoc_composite_templates
    .assoc_composite_template
      position relative
      .canvas_wrapper
        border 1px solid transparent
        border-radius 4px
      &:hover
        .canvas_wrapper
          border 1px solid #80abff
          cursor pointer
      .check_box
        width 18px
        height 18px
        position absolute
        top 10px
        right 10px
        border 1px solid #000000
        border-radius 3px
      &.checked
        .check_box
          border 1px solid transparent
          background url(//s3.marpple.co/files/u_193535/2020/7/original/32291519d236285130b242a1f50e30c03b0976d620ee949.jpg) 0 0 / 16px 16px

