@import "../../CopyGrid/F/Style/style.styl"

[page_name="/modules/VectorEditor/Sticker/Grid/Mobile/S/Mui/page.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    > .body
      width 100%
      height 100%

[tab_name="/modules/VectorEditor/Sticker/Grid/Mobile/S/Mui/tab.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    overflow hidden
    .editor_container
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      background-color #F8F9FA
      transform translateY(0)
      transition transform 320ms
      &[data-is_upper="false"]
        transform translateY(0)
      &[data-is_upper="true"]
        transform translateY(-48px)
    .header
      display flex
      justify-content space-between
      align-items center
      padding 0 6px
      position absolute
      width 100%
      height 48px
      box-sizing border-box
      border-bottom 1px solid #E9ECEF
      background-color #FFFFFF
      transform translateY(0)
      transition transform 320ms
      &[data-is_show="true"]
        transform translateY(0)
      &[data-is_show="false"]
        transform translateY(-48px)
      button
        width 58px
        height 32px
        border 0 none
        background-color transparent
      .title
        font-weight bold
    .footer
      position absolute
      bottom 16px
      html.safe_area_inset_bottom &
        bottom 26px
      display inline-flex
      flex-direction column
      width 45px
      height 62px
      align-items center
      justify-content space-between
      transform translateY(0)
      transition transform 0.4s
      .icon
        display inline-flex
        width 45px
        height 45px
        align-items center
        justify-content center
        border-radius 50%
        border 0 none
        padding 0
        margin 0
        box-shadow 0 2px 6px 0 rgba(0, 0, 0, 0.1)
        background-color #FFFFFF
        background-position center center
        background-repeat no-repeat
        background-size 25px 25px
        &:active
          background-color #0157FF
      .label_text
        overflow visible
        font-size 10px
      &[data-is_show="true"]
        transform translateY(0)
      &[data-is_show="false"]
        transform translateY(78px)
      html.safe_area_inset_bottom &[data-is_show="false"]
        transform translateY(88px)
      &[data-icon_type="set_background"]
        left 16px
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-bgsetting@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-bgsetting-pre@2x.png")
      &[data-icon_type="edit_target_image"]
        left calc(50% - 91.5px)
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-edit@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-edit-pre@2x.png")
      &[data-icon_type="duplicate_target_image"]
        left calc(50% - 22.5px)
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-duplicate@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-duplicate-pre@2x.png")
      &[data-icon_type="delete_target_image"]
        left calc(50% + 46.5px)
        .icon
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-delete@2x.png")
        .icon:active
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/pressed-selected/ic-delete-pre@2x.png")
    copy-grid(122px, 320ms)
