[page_name="/modules/VectorEditor/Keyring/Free/Mobile/CuttingLineManual/S/Mui/page.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    > .body
      width 100%
      height 100%

[tab_name="/modules/VectorEditor/Keyring/Free/Mobile/CuttingLineManual/S/Mui/tab.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    overflow hidden

    .editor_container
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      background-color #F8F9FA

    .header
      display flex
      align-items center
      justify-content space-between
      position absolute
      box-sizing border-box
      padding 0 6px
      top 0
      width 100%
      height 48px
      box-shadow 0 1px 0 0 #E9ECEF
      background-color #FFFFFF
      button
        width 58px
        height 32px
        background-color #FFFFFF
        border 0 none

    .footer_container
      position absolute
      box-sizing border-box
      padding 24px 20px
      bottom 0
      left 0
      right 0
      height 144px
      background-color #FFFFFF
      border-top 1px solid #E9ECEF
      overflow hidden
      display flex
      align-items center
      .footer
        .footer_menu
          width 80px
          height 100%
          padding 0 4px
          display flex
          flex-direction column
          align-items center
          justify-content space-between
          .footer_menu_name
            color #868E96
            font-size 12px
          .footer_menu_icon
            width 72px
            height 72px
            box-sizing border-box
            border-width 1px
            border-style solid
            border-color #D5DBE0
            border-radius 50%
            background-color #FFFFFF
            background-repeat no-repeat
            background-size 22px 22px
            background-position center center
          &:active
            .footer_menu_name
              color #0157FF
            .footer_menu_icon
              box-shadow: 0 4px 16px 0 rgba(178, 215, 255, 0.5)
              border-color #0157FF
          &.reset
            .footer_menu_icon
              background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/normal/ic-replace-new@2x.png")
          &:active.reset
            .footer_menu_icon
              background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/pressed-selected/ic-replace-new-sel@2x.png")
          &.add_l
            .footer_menu_icon
              background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/normal/ic-path-line@2x.png")
          &:active.add_l
            .footer_menu_icon
              background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/pressed-selected/ic-path-line-sel@2x.png")
          &.add_c
            .footer_menu_icon
              background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/normal/ic-path-curve@2x.png")
          &:active.add_c
            .footer_menu_icon
              background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/pressed-selected/ic-path-curve-sel@2x.png")
          &.remove
            .footer_menu_icon
              background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/normal/ic-path-del@2x.png")
          &:active.remove
            .footer_menu_icon
              background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/pressed-selected/ic-path-del-sel@2x.png")
          &.change_to_l
            .footer_menu_icon
              background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/normal/ic-replace-line@2x.png")
          &:active.change_to_l
            .footer_menu_icon
              background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/pressed-selected/ic-replace-line-sel@2x.png")
          &.change_to_c
            .footer_menu_icon
              background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/normal/ic-replace-curve@2x.png")
          &:active.change_to_c
            .footer_menu_icon
              background-image url("//s3.marpple.co/vector-editor-assets/mobile/path-button/pressed-selected/ic-replace-curve-sel@2x.png")
