#fr-crew-manager_management-manager_registration
  > .don_wrapper
    display flex
    flex-direction column-reverse
    width 26rem
    height 17rem

    > .header
      display flex
      align-items center
      justify-content space-between
      width 100%
      height 5rem
      .don_hide_frame
        position static
        margin-right 5px
      .title
        font-weight 600
        color #000000
        padding-left 20px

    .don_page
      width 100%
      height 100%
      > .don_wrapper
        width 100%
        height 100%
        > .body
          width 100%
          height 100%
          .don_tab
            width 100%
            height 100%
            .don_wrapper
              width 100%
              height 100%
              .crew-manager_registration
                display flex
                flex-direction column
                justify-content space-between
                width 100%
                height 100%

                box-sizing border-box
                padding 1rem

                .inputs
                  display flex
                  flex-direction column
                  width 100%

                  > *
                    width 100%
                    height 2.5rem
                    font-size 1rem
                  > *:not(:last-child)
                    margin 0 0 1rem 0


                button[type="submit"]
                  align-self flex-end


