[page_name="/modules/SVGEditor/MobileImageUpload/S/Mui/page.js"]
  width 100%
  height 100%
  .don_frame &
    font-size 10px
  > .don_wrapper
    width 100%
    height 100%
    > .header
      height 48px
    > .body
      width 100%
      height calc(100% - 48px)

[tab_name="/modules/SVGEditor/MobileImageUpload/S/Mui/tab.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
  .container
    width 100%
    height 100%
    position relative
    .blank_plate
      position fixed
      top 0
      right 0
      bottom 0
      left 0
    .wrapper
      box-sizing border-box
      width 100%
      height 100%
      padding 24px 32px
      display flex
      flex-direction column
      position relative
    .descriptions
      height 0
      flex-grow 1
      margin-bottom 10px
      font-size 12px
      color #000000
      display flex
      flex-direction column
      .description
        margin 0
      .description1
        margin-bottom 16px
        line-height 1.67
      .description2
        flex-grow 1
        line-height 1.83
      a
        text-decoration underline
    .buttons
      height 74px
      display flex
      flex-direction column
      justify-content space-between
      input[type="file"]
        display none
      .button
        height 32px
        font-size 14px
        font-weight 200
        text-align center
      .add_img
        width 100%
        color #ffffff
        border 0 none
        background-color #000000
        box-sizing border-box
      .my_lib
        width 100%
        color #000000
        background-color #ffffff
        border 1px solid #000
        box-sizing border-box
