&
  position relative
  width 100%
  padding 0 10px
  .nav-tab-body
    >.header
      margin-top 20px
      display flex
      align-items center
      justify-content center
      margin-right 0
      >
       margin 0 50px
      h2
        margin 0 20px
        display flex
        align-items center
    .links
      position absolute
      top 40px
      right 80px
      a
        margin 0 20px
        text-decoration underline
    .store-product-list-body
      margin-top 20px
      margin-bottom 20px
      .mp-shop-product-item
        &:nth-child(3n)
          margin-right 0
      .pb-me-store-product-check
        .mp-shop-product-name
        .mp-shop-product-store
          display none
        .mp-shop-product-item
          cursor pointer
          margin-bottom 0
        &__not-user
          margin-bottom 50px
          text-align center
          font-size 20px
          font-weight bold
          color #f66565
      .pb-me-store-product-info
        display grid
        box-sizing border-box
        grid-gap 0
        gap 0
        grid-template-columns 200px 200px 200px 200px
        margin 0 auto
        width 800px
        border-top 1px solid #000
        border-left 1px solid #000
        &__title
          background-color #f0f0f0
          padding 10px
          border-right 1px solid #000
          border-bottom 1px solid #000
        &__value
          padding 10px
          border-right 1px solid #000
          border-bottom 1px solid #000
