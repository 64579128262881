.don_frame.crew_chart_frame
  .tooltip-icon
    width 16px
    height 16px
    margin-left 5px
    background-image url("//s3.marpple.co/files/u_1093672/2020/9/original/7b88b4654d6545661c60af72c10d3804749854221.png")
    background-size contain

  .chart_sample_body
    .info_container
      font-size 1.3rem
      .info
        margin 5px 0
        display flex
      .info_title
        font-weight bold
        margin-right 10px
    .chart_container
      width 100%
      padding 0 100px
      display flex
      justify-content space-around
      flex-wrap wrap
      .chart_wrapper
        width 40%
        .title
          display flex
        &.one
          margin 0 30%


  .chart_wrapper
    margin-top 100px

  .chart_header
    display flex
    align-items center
    .table-view
      margin-left auto !important
      display block
      align-items center
      padding 0.5rem 0.5rem
      font-size 0.8rem

.don_frame.crew_chart_table
  .chart-table-container
    width 80%
    margin 30px auto 0
    h3
      margin 0

    .chart-wrapper
      margin-top 30px
