&
  display inline-flex
  align-items center
  justify-content space-between

  box-sizing border-box
  border-bottom 1px solid rgba(24, 28, 33, 0.06)
  padding 0 1rem 0 0

  background-color #ffffff

  h1
    margin 0
    margin-left 25px
    display inline-flex
    align-items center
    color #4e5155
    font-size 1.2rem
    font-weight normal
    a
      display inline-flex
      align-items center
      height 28px
      img
        max-width 12rem
      .app_name
        font-size 20px
        font-weight 500
        letter-spacing -0.3px
        color #000000
        padding 4px 0 2px

  .user_info
    display flex
    align-items center
    a
      text-decoration underline
    .user_name
      margin-right 0.8rem
    .crew_home
      margin-left 24px
      text-decoration none
      display flex
      padding 7px 12px 6px
      border-radius 4px
      background-color #3c89f9
      &__icon
        margin-right 6px
        width 14px
        height 14px
      &__title
        color #fff
        font-size 14px
        font-weight bold

  .crew-header-logo
    width 18px
    margin-right 9px

  .crew-header__logo-container
    display flex
    column-gap 12px
    align-items center

  .crew-header__main-logo
    height 24px
    margin-left 24px

  .crew-header__plus-logo
    width 28px
    height 28px


