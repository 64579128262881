[page_name="/modules/VectorEditor/AcrylicFigure/StandLeg/CreatorPC/S/Mui/page.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    > .header
      display none
    > .body
      width 100%
      height 100%

[tab_name="/modules/VectorEditor/AcrylicFigure/StandLeg/CreatorPC/S/Mui/tab.js"]
  user-select none
  position relative
  width 100%
  height 100%
  > .don_wrapper
    position relative
    width 100%
    height 100%
    display flex

    .left_container
      position relative
      width 0
      flex-grow 1
      height 100%
      background-color #FFFFFF
      .editor_container
        position absolute
        top 0
        right 0
        bottom 0
        left 0
        background-color #F8F9FA
      .foreground_container
        position absolute
        top 0
        left 0
        width 0
        height 0
        overflow visible
        .reposition_guide_container
          position absolute
          top 0
          left 0
          width 0
          height 0
          overflow visible
          .reposition_guide_container_drag_icon
          .reposition_guide_container_left_icon
          .reposition_guide_container_right_icon
            position absolute
            background-repeat no-repeat
            background-clip: border-box;
            background-position center center
          .reposition_guide_container_drag_icon
            width 32px
            height 32px
            background-size 38px 44px
            background-image url("//s3.marpple.co/files/u_1187077/2023/6/original/e12bb3f276262f1188b11123a7d890cda9a84df02.webp")
          .reposition_guide_container_left_icon
            width 80px
            height 8px
            background-size 81px 9px
            background-image url("//s3.marpple.co/files/u_1187077/2023/6/original/3660c1864af8dd85137e1ca6b419f2a558fdc53d2.webp")
          .reposition_guide_container_right_icon
            width 80px
            height 8px
            background-size 81px 9px
            background-image url("//s3.marpple.co/files/u_1187077/2023/6/original/28456a089fc29b492f799092d94feaee07f7f7b12.webp")
        .size_guide_container
          position absolute
          top 0
          left 0
          width 0
          height 0
          overflow visible
          .size_guide_container_value
            visibility hidden
            position absolute
            top 0
            left 0
            display inline-flex
            width 200px
            height 20px
            align-items center
            justify-content center
            background-color #474748
            color #fff
            border-radius 16px
    .right_container
      width 320px
      height 100%
      overflow hidden
      .right_wrapper
        width 100%
        height 100%
        display flex
        flex-direction column
        box-sizing border-box
        padding 40px 0 32px 0
        background-color #FFFFFF
        overflow hidden
        .panel_container
          width 100%
          height 0
          flex-grow 1
          position relative
          .panel_wrapper
            position absolute
            width 100%
            height 100%
            overflow hidden
            display flex
            flex-direction column
            box-sizing border-box
            padding 0 30px 0 30px
            .wrapper_header
              display flex
              width 100%
              height 24px
              align-items center
              justify-content center
              .wrapper_header_title
                font-size 19px
            .wrapper_body
              width 100%
              height 0
              flex-grow 1
              display flex
              flex-direction column
              align-items center
              justify-content center
              .wrapper_body_item
                width 100%
                height 74px
                display flex
                flex-direction column
                align-items center
                justify-content space-between
                .wrapper_body_item_description_wrapper
                  width 100%
                  height 18px
                  display flex
                  align-items center
                  justify-content center
                  .wrapper_body_item_description_wrapper_icon
                    width 18px
                    height 100%
                    margin-right 8px
                  .wrapper_body_item_description_wrapper_text
                    font-size 13px
                    font-weight bold
                    font-stretch normal
                    font-style normal
                    letter-spacing normal
                    text-align left
                    color #000000
                .wrapper_body_item_control_wrapper
                  width 100%
                  height 40px
                  display flex
                  border solid 1px #d5dbe0
                  box-sizing border-box
                  button
                    all initial
                    display inline-block
                    height 100%
                    cursor pointer
                    border-style solid
                    border-color #d5dbe0
                    border-width 0
                    box-sizing border-box
              .wrapper_body_item.wrapper_body_item_size
                margin-bottom 60px
                .wrapper_body_item_description_wrapper
                  .wrapper_body_item_description_wrapper_icon
                    background-repeat no-repeat
                    background-size 18px 18px
                    background-position center center
                    background-image url("//s3.marpple.co/files/u_1187077/2023/6/original/c1e60ad4b7cf0954c8f986937e05a45756a43d1b10.webp")
                .wrapper_body_item_control_wrapper
                  button
                    width 40px
                  .wrapper_body_item_control_wrapper_minus
                    background-repeat no-repeat
                    background-size 40px 38px
                    background-position center center
                    background-image url("//s3.marpple.co/files/u_1187077/2023/6/original/a9b9cf71e952db8c91f991af0919643bd80b60862.webp")
                  .wrapper_body_item_control_wrapper_plus
                    background-repeat no-repeat
                    background-size 40px 38px
                    background-position center center
                    background-image url("//s3.marpple.co/files/u_1187077/2023/6/original/5b421e2a25a01f3eead7494c05ce7491e3768b252.webp")
                  .wrapper_body_item_control_wrapper_value
                    flex-grow 1
                    display inline-flex
                    height 100%
                    align-items center
                    justify-content center
              .wrapper_body_item.wrapper_body_item_position
                .wrapper_body_item_description_wrapper
                  .wrapper_body_item_description_wrapper_icon
                    background-repeat no-repeat
                    background-size 18px 18px
                    background-position center center
                    background-image url("//s3.marpple.co/files/u_1187077/2023/6/original/85bcce0845cddda7c17fc46d5bedc326957a0ffd4.webp")
                .wrapper_body_item_control_wrapper
                  button
                    width 0
                    flex-grow 1
                  .wrapper_body_item_control_wrapper_left
                    border-right-width 0.5px
                    background-repeat no-repeat
                    background-size 40px 38px
                    background-position center center
                    background-image url("//s3.marpple.co/files/u_1187077/2023/6/original/a9b7ad69fd55464610d2994d655c3a31089491a92.webp")
                  .wrapper_body_item_control_wrapper_right
                    border-left-width 0.5px
                    background-repeat no-repeat
                    background-size 40px 38px
                    background-position center center
                    background-image url("//s3.marpple.co/files/u_1187077/2023/6/original/543fac81fc0bb4db80e8993ce2cd57285fdf4aeb2.webp")
            .wrapper_footer
              display flex
              width 100%
              height 56px
              align-items center
              justify-content space-between
              button
                border 0 none
                padding 0
                margin 0
                width 0
                height 100%
                flex-grow 1
                box-sizing border-box
                background-color transparent
              .cancel
                margin-right 8px
                background-color #FFFFFF
                border 1px solid #D5DBE0
              .done
                background-color #000000
                color #FFFFFF
