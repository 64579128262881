&
  width 100%
  min-height 100%
  display flex
  flex-direction column

  .header-btn-group
    position absolute
    bottom 0
    right 0

  .table-wrapper
    margin-top 40px

  .chart-wrapper
    margin-top 30px
    .buttons
      white-space nowrap
    .chart
      .user_email
        margin-left 0.4rem
