[page_name="/modules/VectorEditor/Keyring/Hook/CreatorPC/S/Mui/page.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    > .header
      display none
    > .body
      width 100%
      height 100%

[tab_name="/modules/VectorEditor/Keyring/Hook/CreatorPC/S/Mui/tab.js"]
  user-select none
  position relative
  width 100%
  height 100%
  > .don_wrapper
    position relative
    width 100%
    height 100%
    display flex

    .left_container
      position relative
      width 0
      flex-grow 1
      height 100%
      background-color #FFFFFF
      .editor_container
        position absolute
        top 0
        right 0
        bottom 0
        left 0
        background-color #F8F9FA
    .right_container
      width 320px
      height 100%
      overflow hidden
      .right_wrapper
        width 100%
        height 100%
        display flex
        flex-direction column
        box-sizing border-box
        padding 40px 0 32px 0
        background-color #FFFFFF
        overflow hidden
        .panel_container
          width 100%
          height 0
          flex-grow 1
          position relative
          .panel_wrapper
            position absolute
            width 100%
            height 100%
            overflow hidden
            display flex
            flex-direction column
            box-sizing border-box
            padding 0 30px 0 30px
            .wrapper_header
              display flex
              width 100%
              height 24px
              align-items center
              justify-content center
              .wrapper_header_title
                font-size 19px
            .wrapper_body
              width 100%
              height 0
              flex-grow 1
              display flex
              flex-direction column
              align-items center
              justify-content center
              .wrapper_body_description_wrapper
                display flex
                width 100%
                height 18px
                justify-content center
                align-items center
                margin-bottom 16px
                .wrapper_body_description_wrapper_icon
                  width 18px
                  height 18px
                  margin-right 8px
                  background-repeat no-repeat
                  background-size 18px 18px
                  background-position center center
                  background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-archrylic-hole@2x.png")
                .wrapper_body_description_wrapper_text
                  display inline-flex
                  height 100%
                  align-items center
                  font-size 14px
                  font-weight bold
              .wrapper_body_control_wrapper
                display flex
                width 100%
                height 40px
                .wrapper_body_control_wrapper_in
                .wrapper_body_control_wrapper_out
                  display flex
                  width 100%
                  height 100%
                  align-items center
                  justify-content center
                  .wrapper_body_control_wrapper_in_inside
                  .wrapper_body_control_wrapper_in_outside
                  .wrapper_body_control_wrapper_out_inside
                  .wrapper_body_control_wrapper_out_outside
                    display inline-flex
                    align-items center
                    justify-content center
                    width 0
                    height 100%
                    flex-grow 1
                    margin 0
                    padding 0
                    background #FFFFFF
                    border 1px solid #D5DBE0
                    box-sizing border-box
                    font-size 14px
                    font-weight bold
                .wrapper_body_control_wrapper_in
                  .wrapper_body_control_wrapper_in_inside
                    color #0157FF
                    border-color #0157FF
                  .wrapper_body_control_wrapper_in_outside
                    border-left 0 none
                .wrapper_body_control_wrapper_out
                  .wrapper_body_control_wrapper_out_inside
                    border-right 0 none
                  .wrapper_body_control_wrapper_out_outside
                    color #0157FF
                    border-color #0157FF
              .wrapper_body_control_wrapper[data-hook_position="outside"]
                .wrapper_body_control_wrapper_in
                  display none
                .wrapper_body_control_wrapper_out
                  display flex
              .wrapper_body_control_wrapper[data-hook_position="inside"]
                .wrapper_body_control_wrapper_in
                  display flex
                .wrapper_body_control_wrapper_out
                  display none
            .wrapper_footer
              display flex
              width 100%
              height 56px
              align-items center
              justify-content space-between
              button
                border 0 none
                padding 0
                margin 0
                width 0
                height 100%
                flex-grow 1
                box-sizing border-box
                background-color transparent
              .cancel
                margin-right 8px
                background-color #FFFFFF
                border 1px solid #D5DBE0
              .done
                background-color #000000
                color #FFFFFF
