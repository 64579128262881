.swiper-slide
  display flex
  flex-wrap wrap
  align-items space-around
  padding 32px 22px
  .font
    width calc(33.33%)
    box-sizing border-box
    display flex
    justify-content center
    align-items center
    padding-bottom 20px
    img
      max-height 20px
      max-width 80%
      width auto
    .active
      display none
    &.selected
      .deactive
        display none
      .active
        display block
