@import "../variables.styl"

feed_card($radius)
  .feed_card
    [data-show="false"]
      display none !important
    width 32%
    border-radius $radius
    margin 0 auto 40px
    display flex
    flex-direction column
    height auto

    &.empty
      border 0
      background-color transparent
      box-shadow none

    .label-container
      position absolute
      width 100%
      padding 10px
      display flex
      align-items center

      .main
      .public
      .private
        padding 3px 8px
        font-size 12px
        font-weight 600
        line-height 11px

      .main
        border-radius 2px
        background-color #f66565
        color white
        padding 4px 8px
        line-height 14px

      .public, .private
        padding 3px 8px
        margin-left auto
        border-radius 2px
        border solid 1px
        background-color white
        display flex
        align-items center
        img
          height 14px
          margin-right 4px
      .public
        color #5eda42
        border-color #5eda42
      .private
        color #b2b2b2
        border-color #b2b2b2

    .close-btn
      width 22px
      height 22px
      border solid 1px #b2b2b2
      background-color white
      border-radius 50%
      display flex
      align-items center
      justify-content center
      position absolute
      top 0
      right 0
      transform translate(50%, -50%)
      cursor pointer
      z-index 1
      .img
        width 16px


    .thumbnail
      width 100%
      border-radius $radius
      margin-bottom 16px
      a
        display block
        border-radius $radius
        padding-top 100%
        background-position center
        background-size contain
        background-repeat no-repeat
        &.don_lazy[data-src]
          position relative !important // lazy 에서 강제로 포지현 absolute
          display block !important // lazy 에서 강제로 inline-block

    .text
      flex-grow 1
      word-break keep-all
      .title
        margin 0 0 10px 0
        font-size 18px
        font-weight 500
      .description
        margin 0
        line-height 1.5
        color $grey_400

    .public_date
      margin-top 20px
      font-size 12px
      line-height 1.5
      letter-spacing -0.43px
      color #999999


    @media screen and (max-width 480px)
      width 100%
      .text
        padding 0 10px
        .title
          font-size 20px
          margin-bottom 4px
          line-height 1.4
        .description
          font-size 12px


