&
  width 100%
  height 100%

  [data-show="false"]
    display none !important

  .editing_list
    width 100%

    button
      display inline-flex
      align-items center
      justify-content center
      width 4rem
      height 2rem
      box-sizing border-box
      padding 0
      margin 0 0.4rem
      border 0 none
    .link_underline
      text-decoration underline

    .row
      width 100%
      min-height 3rem
      display flex
      align-items center
      .label
        min-width 8rem
        font-weight 600
        margin 0 1rem 0 0
        padding-left 0.7em
        padding-right 0.7em
      .value
        display inline-flex
        align-items center
        width 0
        flex-grow 1
        input + label
          margin-left 5px
        label
          margin-right 15px

      &.thumbnail_img_upload
        margin-top 1rem
        &[is_thumbnail_img="true"]
          .thumb_area
            display block
          button.btn_thumb_delete
            display inline-block
        .thumb_area
          margin-top 10px
          display none
        .label
          align-self flex-start
        .txt1
          margin-bottom 5px
        .input
          display none
        button
          width auto
          padding-left 20px
          padding-right 20px
          margin 0 10px 0 0
        button.btn_thumb_delete
          display none

    .row.seller .value .user .user_email
      margin 0 0 0 0.4rem
      a
        text-decoration underline

    .row.sns .value img
      height 3rem

    .invited_store
      button
        width auto
        padding-left 10px
        padding-right 10px
        margin 0 10px 0 10px

    .store_fail
      button
        width auto
        padding-left 10px
        padding-right 10px
        margin 0 10px 0 0

  .fixed_share_ratio
    .input_title
      margin-right 10px
    .fixed_share_ratio_input
      width: 100px