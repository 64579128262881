&
  &[data-is_edit_store_label="false"] > span
    display inline-flex
    align-items center
    justify-content center
    width 6rem
    padding 0.4rem 0
    border-radius 0.4rem
  &[data-is_edit_store_label="true"] select
    width 8rem
    height 2rem
