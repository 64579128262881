@import "./color_selector.styl"

setting-background-right-panel(color_selector_size = 28px, padding = 40px)
  .setting_background_right_panel
    position absolute
    width 100%
    height 100%
    background-color #FFFFFF
    display flex
    flex-direction column
    box-sizing border-box
    padding 0 padding 0 padding

    .header
      width 100%
      height 56px
      display flex
      flex-direction column
      align-items flex-start
      justify-content space-between
      font-size 20px
      font-weight normal
      margin-bottom 43px

    color-selector(100%, 132px, color_selector_size)
