[page_name="/modules/VectorEditor/Sticker/Single/CreatorPC/CuttingLineAuto/S/Mui/page.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    > .header
      display none
    > .body
      width 100%
      height 100%

[tab_name="/modules/VectorEditor/Sticker/Single/CreatorPC/CuttingLineAuto/S/Mui/tab.js"]
  user-select none
  position relative
  width 100%
  height 100%
  > .don_wrapper
    position relative
    width 100%
    height 100%
    display flex

    .left_container
      position relative
      width 0
      flex-grow 1
      height 100%
      background-color #FFFFFF
      .editor_container
        position absolute
        top 0
        right 0
        bottom 0
        left 0
        background-color #F8F9FA
    .right_container
      width 320px
      height 100%
      overflow hidden
      .right_wrapper
        width 100%
        height 100%
        display flex
        flex-direction column
        box-sizing border-box
        padding 40px 0 32px 0
        background-color #FFFFFF
        overflow hidden
        .panel_container
          width 100%
          height 0
          flex-grow 1
          position relative
          .right_panel
            position absolute
            width 100%
            height 100%
            background-color #FFFFFF
            display flex
            flex-direction column
            box-sizing border-box
            padding 0 30px 0 30px
            .header
              width 100%
              height 22px
              display flex
              align-items center
              justify-content center
              font-size 19px
              font-weight normal
            .wrapper
              width 100%
              height 0
              flex-grow 1
              display flex
              flex-direction column
              align-items center
              justify-content center
              .description_container
                display inline-flex
                align-items center
                margin-bottom 16px
                &::before
                  content ""
                  display inline-block
                  width 18px
                  height 18px
                  background-size 18px 18px
                  background-repeat no-repeat
                  background-position center center
                  background-image url("//s3.marpple.co/vector-editor-assets/pc/menu-button/normal/ic-sticker@2x.png")
                  margin-right 8px
                .description
                  margin 0
                  font-size 12px
                  font-weight bold
              .control_container
                display inline-flex
                align-items center
                width 100%
                height 40px
                .offset_control
                  display inline-flex
                  align-items center
                  width 0
                  height 100%
                  flex-grow 1
                  margin-right 8px
                  box-sizing border-box
                  border solid 1px #D5DBE0
                  .decrease,
                  .increase
                    background-color #FFFFFF
                    width 40px
                    height 100%
                    border 0 none
                  .offset_value
                    display inline-flex
                    width 0
                    height 100%
                    flex-grow 1
                    align-items center
                    justify-content center
                .edit_manual
                  width 100px
                  height 100%
                  box-sizing border-box
                  background-color #FFFFFF
                  border solid 1px #D5DBE0
        .button_container
          width 100%
          height 56px
          display flex
          justify-content space-between
          box-sizing border-box
          padding 0 30px 0 30px
          button
            width 186px
            height 100%
            margin 0
            padding 0
            border 0 none
            &.cancel
              background-color #FFFFFF
              box-sizing border-box
              border 1px solid #D5DBE0
              color #000000
            &.done
              background-color #000000
              color #FFFFFF
