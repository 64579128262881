.event-container
  margin 0
  display grid
  grid-template-columns repeat(4, minmax(0, 1fr))
  column-gap 20px;
  row-gap: 48px;

  feed_card(6px)

  .event-admin-list-item
    width: 100%;
    position relative

    .label-container
      position absolute
      width 100%
      padding 10px
      display flex
      align-items center
      top 0

      .public
      .private
        padding 3px 8px
        font-size 12px
        font-weight 600
        line-height 11px

      .public, .private
        padding 3px 8px
        margin-left auto
        border-radius 2px
        border solid 1px
        background-color white
        display flex
        align-items center
        img
          height 14px
          margin-right 4px
      .public
        color #5eda42
        border-color #5eda42
      .private
        color #b2b2b2
        border-color #b2b2b2

  .feed_card
    width 100%
    display inline-block
    vertical-align top
    margin 0 20px 20px 0

    &:nth-child(4n)
      margin-right 0

    .thumbnail
      margin-bottom 20px

    .text
      .title
        font-size 16px
        font-weight 600
        letter-spacing -0.5px
        white-space nowrap
        text-overflow ellipsis
        overflow hidden

      .description
        font-size 12px
        letter-spacing -0.5px
        white-space nowrap
        text-overflow ellipsis
        overflow hidden
        color black

    &.empty
      display flex
      justify-content center
      align-items center
      border dotted 1px #d9d9d9
      width: 100%;
      min-height 500px
      .content
        text-align center
        .plus_btn
          width 60px
          height 60px
          border-radius 50%
          background-color #3c89f9
          display inline-flex
          align-items center
          justify-content center
          border 0
          margin-bottom 20px
          img
            width 30px
            cursor pointer




