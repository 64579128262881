[page_name="/modules/VectorEditor/Keyring/Free/CreatorPC/CuttingLineManual/S/Mui/page.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    > .header
      display none
    > .body
      width 100%
      height 100%

[tab_name="/modules/VectorEditor/Keyring/Free/CreatorPC/CuttingLineManual/S/Mui/tab.js"]
  user-select none
  position relative
  width 100%
  height 100%
  > .don_wrapper
    position relative
    width 100%
    height 100%
    display flex

    .left_container
      position relative
      width 0
      flex-grow 1
      height 100%
      background-color #FFFFFF
      .editor_container
        position absolute
        top 0
        right 0
        bottom 0
        left 0
        background-color #F8F9FA
    .right_container
      width 320px
      height 100%
      overflow hidden
      .right_wrapper
        width 100%
        height 100%
        display flex
        flex-direction column
        box-sizing border-box
        padding 40px 0 32px 0
        background-color #FFFFFF
        overflow hidden
        .panel_container
          width 100%
          height 0
          flex-grow 1
          position relative
          .panel_wrapper
            position absolute
            width 100%
            height 100%
            overflow hidden
            display flex
            flex-direction column
            box-sizing border-box
            padding 0 30px 0 30px
            .wrapper_header
              display flex
              width 100%
              height 24px
              align-items center
              justify-content center
              margin-bottom 40px
              .wrapper_header_title
                font-size 19px
            .wrapper_body
              width 100%
              height 0
              flex-grow 1
              display flex
              flex-direction column
              .button_group
                width 100%
                display flex
                flex-direction column
                align-items flex-start
                &:not(:last-child)
                  margin-bottom 34px
                .button_group_label
                  font-size 14px
                  margin-bottom 16px
                .button_list
                  width 100%
                  display flex
                  flex-direction column
                  button
                    position relative
                    box-sizing border-box
                    width 100%
                    height 40px
                    border-width 1px
                    border-style solid
                    border-color #D5DBE0
                    background-color #FFFFFF
                    padding 0
                    margin 0
                    display flex
                    align-items center
                    justify-content center
                    &:not(:last-child)
                      margin-bottom 8px
                    .button_icon
                      position absolute
                      width 20px
                      height 20px
                      left 12px
                      top 50%
                      transform translateY(-50%)
                      background-repeat no-repeat
                      background-size 20px 20px
                      background-position center center
                      &.active
                        display none
                      &.inactive
                        display block
                    .button_text
                      font-size 14px
                    &:active
                      border-color #0157FF
                      .button_icon
                        &.active
                          display block
                        &.inactive
                          display none
                      .button_text
                        color #0157FF
                  button.add_l
                    .button_icon
                      &.active
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/pressed-selected/ic-path-line-sel@2x.png")
                      &.inactive
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/normal/ic-path-line@2x.png")
                  button.add_c
                    .button_icon
                      &.active
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/pressed-selected/ic-path-curve-sel@2x.png")
                      &.inactive
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/normal/ic-path-curve@2x.png")
                  button.remove
                    .button_icon
                      &.active
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/pressed-selected/ic-path-del-sel@2x.png")
                      &.inactive
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/normal/ic-path-del@2x.png")
                  button.change_to_c
                    .button_icon
                      &.active
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/pressed-selected/ic-replace-curve-sel@2x.png")
                      &.inactive
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/normal/ic-replace-curve@2x.png")
                  button.change_to_l
                    .button_icon
                      &.active
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/pressed-selected/ic-replace-line-sel@2x.png")
                      &.inactive
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/normal/ic-replace-line@2x.png")
                  button.reset
                    .button_icon
                      &.active
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/pressed-selected/ic-replace-new-sel@2x.png")
                      &.inactive
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/normal/ic-replace-new@2x.png")
            .wrapper_footer
              display flex
              width 100%
              height 56px
              align-items center
              justify-content space-between
              button
                border 0 none
                padding 0
                margin 0
                width 0
                height 100%
                flex-grow 1
                box-sizing border-box
                background-color transparent
              .prev
                margin-right 8px
                background-color #FFFFFF
                border 1px solid #D5DBE0
              .next
                background-color #000000
                color #FFFFFF
