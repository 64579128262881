[frame_name="/modules/VectorEditor/Sticker/Grid/CreatorPC/CopyGrid/S/Mui/frame.js"]
  position absolute
  top 0
  left 0
  width 0
  height 0

.don_frame[frame_name="/modules/VectorEditor/Sticker/Grid/CreatorPC/CopyGrid/S/Mui/frame.js"][is_modal]::before
  display none

[tab_name="/modules/VectorEditor/Sticker/Grid/CreatorPC/CopyGrid/S/Mui/tab.js"]
  position absolute
  top 0
  right 0
  width 0
  height 0
  display flex
  > .don_wrapper
    position relative
    width 100%
    height 100%
    display flex
    overflow hidden
    .container
      width 100%
      height 100%
      background-color #FFFFFF
      display flex
      flex-direction column
      box-sizing border-box
      padding 40px 40px 32px 40px
      .header
        position relative
        width 100%
        height 22px
        display flex
        align-items center
        justify-content center
        .back
          position absolute
          left 0
          top 50%
          transform translateY(-50%)
          background-color transparent
          background-image url("//s3.marpple.co/files/u_1187077/2021/5/original/2aac30aabf4af156704b95903af308892eefb75a1.png")
          width 19px
          height 16px
          margin 0
          padding 0
          border 0 none
        .title
          font-size 19px
      .wrapper
        width 100%
        height 0
        flex-grow 1
        display flex
        align-items center
        justify-content center
      .footer
        width 100%
        height 56px
        display flex
        justify-content space-between
        button
          width 186px
          height 100%
          margin 0
          padding 0
          border 0 none
          &.cancel
            background-color #FFFFFF
            box-sizing border-box
            border 1px solid #D5DBE0
            color #000000
          &.done
            background-color #000000
            color #FFFFFF


