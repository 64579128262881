html.home-event-detail
  @import "../../feed/editor.styl"

  .event-header
    margin-top 56px
    margin-bottom 40px
    .event-title
      margin 0 0 8px
      font-size 36px
      font-weight bold
    .date
      margin 0
      padding 0
      font-size 16px

    @media screen and (max-width: 1024px)
      margin-bottom 24px !important
      .event-title
        padding 0
        margin 16px 0 8px
        font-size 30px
      .date
        margin 0
        padding 0
        font-size 14px

    @media screen and (max-width: 480px)
      .event-title
        margin 0 0 4px
        font-size 28px
        font-weight bold
