$font-family = "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;

$font-heading-h0-b =
  font-size pxToRem(48px)
  font-weight 700
  line-height pxToRem(60px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-heading-h1-b =
  font-size pxToRem(40px)
  font-weight 700
  line-height pxToRem(60px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-heading-h2-b =
  font-size pxToRem(32px)
  font-style normal
  font-weight 700
  line-height pxToRem(48px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-heading-h3-b =
  font-size pxToRem(24px)
  font-style normal
  font-weight 700
  line-height pxToRem(36px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-heading-h3-r =
  font-size pxToRem(24px)
  font-style normal
  font-weight 400
  line-height pxToRem(36px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-heading-h4-b =
  font-size pxToRem(20px)
  font-style normal
  font-weight 700
  line-height pxToRem(30px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-heading-h4-sb =
  font-size pxToRem(20px)
  font-weight 600
  line-height pxToRem(30px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-heading-h4-r =
  font-size pxToRem(20px)
  font-style normal
  font-weight 400
  line-height pxToRem(30px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-heading-h5-b =
  font-size pxToRem(18px)
  font-weight 700
  line-height pxToRem(27px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-heading-h5-sb =
  font-size pxToRem(18px)
  font-weight 600
  line-height pxToRem(27px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-heading-h5-r =
  font-size pxToRem(18px)
  font-weight 400
  line-height pxToRem(27px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-b1-b =
  font-size pxToRem(16px)
  font-weight 700
  line-height pxToRem(24px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-b1-sb =
  font-size pxToRem(16px)
  font-weight 600
  line-height pxToRem(24px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-b1-m =
  font-size pxToRem(16px)
  font-weight 500
  line-height pxToRem(24px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-b1-r =
  font-size pxToRem(16px)
  font-weight 400
  line-height pxToRem(24px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-b2-b =
  font-size pxToRem(15px)
  font-weight 700
  line-height pxToRem(22px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-b2-sb =
  font-size pxToRem(15px)
  font-weight 600
  line-height pxToRem(22px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-b2-m =
  font-size pxToRem(15px)
  font-weight 500
  line-height pxToRem(22px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-b2-r =
  font-size pxToRem(15px)
  font-weight 400
  line-height pxToRem(22px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-b3-m =
  font-size pxToRem(14px)
  font-weight 500
  line-height pxToRem(21px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-b3-sb =
  font-size pxToRem(14px)
  font-weight 600
  line-height pxToRem(21px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-b3-r =
  font-size pxToRem(14px)
  font-weight 400
  line-height pxToRem(21px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-b3-b =
  font-size pxToRem(14px)
  font-weight 700
  line-height pxToRem(21px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-b4-b =
  font-size pxToRem(13px)
  font-weight 700
  line-height pxToRem(20px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-b4-m =
  font-size pxToRem(13px)
  font-weight 500
  line-height pxToRem(20px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-b4-r =
  font-size pxToRem(13px)
  font-weight 400
  line-height pxToRem(20px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-b5-m =
  font-size pxToRem(12px)
  font-weight 500
  line-height pxToRem(18px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-b5-r =
  font-size pxToRem(12px)
  font-weight 400
  line-height pxToRem(18px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-icon-badge =
  color #FFF
  text-align center
  {$font-body-en-b5-r}
  text-transform uppercase

$font-heading-en-h1-b =
  font-size pxToRem(40px)
  font-weight 700
  line-height pxToRem(60px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-heading-en-h2-b =
  font-size pxToRem(28px)
  font-weight 700
  line-height pxToRem(38px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-heading-en-h3-b =
  font-size pxToRem(24px)
  font-weight 700
  line-height pxToRem(36px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-heading-en-h4-sb =
  font-size pxToRem(20px)
  font-weight 600
  line-height pxToRem(30px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-heading-en-h5-sb =
  font-size pxToRem(16px)
  font-weight 600
  line-height pxToRem(24px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-heading-en-h5-r =
  font-size pxToRem(16px)
  font-weight 400
  line-height pxToRem(24px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-en-b1-sb =
  font-size pxToRem(16px)
  font-weight 625
  line-height pxToRem(26px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-en-b1-m =
  font-size pxToRem(16px)
  font-weight 500
  line-height pxToRem(26px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-en-b1-r =
  font-size pxToRem(16px)
  font-weight 475
  line-height pxToRem(26px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-en-b2-sb =
  font-size pxToRem(14px)
  font-weight 600
  line-height pxToRem(21px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-en-b2-m =
  font-size pxToRem(14px)
  font-weight 500
  line-height pxToRem(21px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-en-b2-r =
  font-size pxToRem(14px)
  font-weight 400
  line-height pxToRem(21px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-en-b3-m =
  font-size pxToRem(13px)
  font-weight 500
  line-height pxToRem(20px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-en-b3-r =
  font-size pxToRem(13px)
  font-weight 400
  line-height pxToRem(20px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-en-b4-m =
  font-size pxToRem(12px)
  font-weight 500
  line-height pxToRem(16px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-en-b4-r =
  font-size pxToRem(12px)
  font-weight 400
  line-height pxToRem(16px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-en-b5-sb =
  font-size pxToRem(10px)
  font-weight 500
  line-height pxToRem(15px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800

$font-body-en-b5-r =
  font-size pxToRem(10px)
  font-weight 400
  line-height pxToRem(15px)
  letter-spacing pxToRem(-0.3px)
  color $gray-800


