color-selector(selector-width, selector-height)
  .color_selector
    width selector-width
    height selector-height
    display flex
    flex-direction column
    .color_selector_label
      display inline-flex
      font-size 12px
      width 100%
      height 34px
      align-items flex-start
      justify-content center
    .color_grid
      width 100%
      height 0
      flex-grow 1
      display flex
      flex-direction column
      justify-content space-between
      .color_line
        width 100%
        height 36%
        display flex
        align-items center
        justify-content space-between
        .color
          position relative
          width 28px
          height 28px
          display inline-flex
          align-items center
          justify-content center
          .color_outer
            position: absolute
            width 28px
            height 28px
            border-radius 50%
            box-sizing border-box
          .color_inner
            position absolute
            width 24px
            height 24px
            box-sizing border-box
            border-radius 50%
            background-size 24px 24px
            background-position center center
          &.active
            .color_outer
              border 1px solid #0157FF
