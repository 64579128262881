[page_name="/modules/VectorEditor/AcrylicFigure/StandLeg/Mobile/S/Mui/page.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    > .body
      width 100%
      height 100%

[tab_name="/modules/VectorEditor/AcrylicFigure/StandLeg/Mobile/S/Mui/tab.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    overflow hidden

    .editor_container
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      background-color #F8F9FA
    .foreground_container
      position absolute
      top 0
      left 0
      width 0
      height 0
      overflow visible
      .reposition_guide_container
        position absolute
        top 0
        left 0
        width 0
        height 0
        overflow visible
        .reposition_guide_container_drag_icon
        .reposition_guide_container_left_icon
        .reposition_guide_container_right_icon
          position absolute
          background-repeat no-repeat
          background-clip: border-box;
          background-position center center
        .reposition_guide_container_drag_icon
          width 32px
          height 32px
          background-size 38px 43px
          background-image url("//s3.marpple.co/files/u_1187077/2023/6/original/05f7f2fbd973f1b53486f42f50a5d84211a381232.webp")
        .reposition_guide_container_left_icon
          width 80px
          height 8px
          background-size 81px 9px
          background-image url("//s3.marpple.co/files/u_1187077/2023/6/original/295fa1b27e69e71f14d58b453d7f60fcc0bf24742.webp")
        .reposition_guide_container_right_icon
          width 80px
          height 8px
          background-size 81px 9px
          background-image url("//s3.marpple.co/files/u_1187077/2023/6/original/173ae7b3b0acde08fafabd0b585d504a7b8d8ba42.webp")
      .size_guide_container
        position absolute
        top 0
        left 0
        width 0
        height 0
        overflow visible
        .size_guide_container_value
          visibility hidden
          position absolute
          top 0
          left 0
          display inline-flex
          width 200px
          height 20px
          align-items center
          justify-content center
          background-color #474748
          color #fff
          border-radius 16px

    .header
      display flex
      align-items center
      justify-content space-between
      position absolute
      box-sizing border-box
      padding 0 6px
      top 0
      width 100%
      height 48px
      box-shadow 0 1px 0 0 #E9ECEF
      background-color #FFFFFF
      button
        width 58px
        height 32px
        background-color #FFFFFF
        border 0 none
      .title
        font-weight bold

    .footer
      display flex
      align-items center
      justify-content space-between
      position absolute
      box-sizing border-box
      padding-left 24px
      padding-right 24px
      bottom 0
      left 0
      right 0
      height 122px
      background-color #fff
      .footer_item
        width 0
        height 64px
        flex-grow 1
        display flex
        flex-direction column
        .footer_item_description
          width 100%
          height 20px
          margin-bottom 12px
          display flex
          align-items center
          .footer_item_description_icon
            width 16px
            height 16px
            margin-right 8px
            background-repeat no-repeat
            background-size 16px 16px
            background-position center center
          .footer_item_description_text
            font-size 12px
            font-weight normal
            font-stretch normal
            font-style normal
            letter-spacing normal
            text-align left
            color #000000
        .footer_item_control
          width 100%
          height 0
          flex-grow 1
          display flex
          border solid 1px #d5dbe0
          box-sizing border-box
          button
            all initial
            display inline-block
            height 100%
            cursor pointer
            border-style solid
            border-color #d5dbe0
            border-width 0
            box-sizing border-box
      .footer_item.footer_item_size
        margin-right 23px
        .footer_item_description
          .footer_item_description_icon
            background-image url("//s3.marpple.co/files/u_1187077/2023/6/original/c5ee309d02747d32789c1811694f1b8036872ca59.webp")
        .footer_item_control
          button
            width 24px
            height 100%
            background-repeat no-repeat
            background-size 24px 24px
            background-position center center
          .footer_item_control_minus
            background-image url("//s3.marpple.co/files/u_1187077/2023/6/original/7093ce6d55deefec5469ec979d01e37ffa2b65a92.webp")
          .footer_item_control_plus
            background-image url("//s3.marpple.co/files/u_1187077/2023/6/original/340de9bcec3ec17d53aa0f61b2165f4bf7a362cc2.webp")
          .footer_item_control_value
            flex-grow 1
            display inline-flex
            height 100%
            align-items center
            justify-content center
      .footer_item.footer_item_position
        .footer_item_description
          .footer_item_description_icon
            background-image url("//s3.marpple.co/files/u_1187077/2023/6/original/25a9b72842c5fdcac4fd589c9179c938976828523.webp")
        .footer_item_control
          button
            width 0
            height 100%
            flex-grow 1
            background-repeat no-repeat
            background-size 24px 24px
            background-position center center
          .footer_item_control_left
            border-right-width 0.5px
            background-image url("//s3.marpple.co/files/u_1187077/2023/6/original/a24bdd3252c482058d4b445121b586bc92e7dfad2.webp")
          .footer_item_control_right
            border-left-width 0.5px
            background-image url("//s3.marpple.co/files/u_1187077/2023/6/original/c2782bb627685e18a92f1f05ffc39bedc4859ac22.webp")
