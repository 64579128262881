@import 'crew.banner.mixins.styl'

.feature
  &__icon
    width pxToRem(16)
    height pxToRem(16)
    margin-right pxToRem(4)

.crew-body {
  background-color white !important
}
.crew-banner-wrap
  width 100%

.crew-banner
  &__inner
    display grid
    grid-template-columns repeat(4, 1fr)
    @media (max-width 1920px)
      grid-template-columns repeat(3, 1fr)
    @media (max-width 1600px)
      grid-template-columns repeat(2, 1fr)
    gap pxToRem(32) pxToRem(20)
  &__item
    width 100%
    position relative
    cursor pointer
    &--add-banner
      border 1px dashed $gray-20
      border-radius 6px
      display flex
      flex-direction column
      align-items center
      justify-content center
      min-height pxToRem(160)
    &.sortable_drag_ghost
      visibility hidden
    &.sortable_drag_on
      .crew-banner__delete
        display none
      .crew-banner__date
        display none
  &__delete
    position absolute
    top -11px
    right -11px
    deleteButton(22px, 22px, 16px)
  &__icon-box
    flexBox(flex, center, center, column)
    gap pxToRem(16)
  &__image-box
    position relative
    border-radius 8px
    overflow hidden
    padding-bottom 38%
    background #f0f0f0
  &__image
    position absolute
    top 0
    height 100%
    width 100%
    object-fit cover
  &__title-box
    width 100%
    justify-content flex-end
    display flex
    flex-direction column
    margin-top ptr(16)
    gap pxToRem(16)
    box-sizing border-box
  &__category
    display none
    margin-bottom 8px
  &__title
    limitLine(2)
    {$font-heading-h4-b}
  &__date
    {$font-body-b5-r}
    color $gray-50
    display inline-flex
    align-items center
    gap 8px
  &__text
    {$font-body-b3-r}
    color $gray-600
    text-align center
  &__add-icon
    addButton()
  &__blind-text
    blind()
  &__tag
    absolute(10px, auto, auto, 10px)
    flexBox(flex, center, center)
    width 36px
    height 22px
    border-radius 2px
    background-color #f66565
    font-size 12px
    color white
  &__statuses
    absolute(8px, 8px, auto, auto)
    flexBox(flex, center, center)
  &__status
    flexBox(flex, center, center)
    padding 2px 6px
    border-radius 4px
    background-color white
    line-height 11px
    font-size 12px
    font-weight 600
    status-color($green-100)
    &::before
      display block
      margin-right 4px
      width 14px
      height 14px
      background-image url(//s3.marpple.co/files/u_1396787/2020/11/original/0cdf2841d33902b37040f20e3bcc57cadb2968441.png)
      background-repeat no-repeat
      background-position center
      background-size 14px
      content ''
    &--is-creator
      status-color(#0e2ee2)
      margin-left 4px
      &:before
        background-image none
        margin-right 0
        width 0
    &--private
      status-color($gray-50)
      &::before
        background-image url(//s3.marpple.co/files/u_1396787/2020/11/original/c4278bcd4c48c7b216f7af22a13e3a7416f71bf31.png)
    &--reservation
      status-color($purple-100)
      background-color $purple-10
      &::before
        background-image url(//s3.marpple.co/files/u_2283830/2024/5/original/e5cd762dee880fb238876a0831a2e6de438cf1781.png)


.crew-banner
  &__inner--live
    grid-template-columns repeat(5, 1fr)
    @media (max-width 1920px)
      grid-template-columns repeat(4, 1fr)
    @media (max-width 1600px)
      grid-template-columns repeat(3, 1fr)
  &__item--live
    .crew-banner__image-box
      padding-bottom 56.25%

html[is_plus="true"] .crew-banner
  &__inner
    display grid
    grid-template-columns repeat(5, 1fr)
    @media (max-width 1920px)
      grid-template-columns repeat(4, 1fr)
    @media (max-width 1600px)
      grid-template-columns repeat(3, 1fr)
    gap pxToRem(20)
  &__inner-five
    grid-template-columns repeat(5, 1fr)
  &__image-box
    padding-bottom 56.25%


.tab_headers .feature
  display flex
  align-items center

  .icon-drag
    width 34px
    margin-right 10px
  .dropdown-options
    .dropdown-option
      padding 13px 30px !important


.plus-page-header .feature
  vertical-align bottom
  .btn-submit
    display inline-flex
    align-items center
    img
      height 8px
      margin-right 10px

