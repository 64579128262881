body.crew.crew-archives
  .crew-body
    .crew-archives
      width 100%
      min-height 100%
      display flex
      flex-direction column
      .hidden
        display none
      .header
        display flex
        flex-shrink 0
        justify-content space-between
        align-items center
        width 100%
        height 4rem
        h2
          margin 0
        .toolbar
          display flex
      section.body
        position relative
        .archive-list
          .archive-item
            cursor pointer
            letter-spacing -0.4px
  .style-editing-frame
    .form-item
      position relative
      input
        height auto
      .url-preview
        position relative
        border 1px solid #ccc
        border-radius 0.25rem
        padding 16px
        text-align center
        margin-bottom 4px
        img
          width 100%
    .buttons
      text-align center
    .fx-auto-complete-list
      ul
        background #eee
        border-radius 0 0 0.25rem 0.25rem
        li
          padding 0.5rem 0.8rem
          &[selected="true"]
            color #fff
            background-color #999

