[page_name="/modules/VectorEditor/AcrylicFigure/Free/Mobile/CuttingLineAuto/S/Mui/page.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    > .body
      width 100%
      height 100%

[tab_name="/modules/VectorEditor/AcrylicFigure/Free/Mobile/CuttingLineAuto/S/Mui/tab.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    overflow hidden

    .editor_container
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      background-color #F8F9FA
    .size_guide_container
      position absolute
      top 0
      left 0
      width 0
      height 0
      overflow visible
      .size_guide_container_value
        visibility hidden
        position absolute
        top 0
        left 0
        display inline-flex
        width 200px
        height 20px
        align-items center
        justify-content center
        background-color #474748
        color #fff
        border-radius 16px

    .header
      display flex
      align-items center
      justify-content space-between
      position absolute
      box-sizing border-box
      padding 0 6px
      top 0
      width 100%
      height 48px
      box-shadow 0 1px 0 0 #E9ECEF
      background-color #FFFFFF
      button
        width 58px
        height 32px
        background-color #FFFFFF
        border 0 none
      .title
        font-weight bold

    .footer
      display flex
      flex-direction column
      align-items center
      justify-content space-between
      position absolute
      box-sizing border-box
      padding 24px
      bottom 0
      left 0
      right 0
      height 122px
      background-color #FFFFFF

      .description
        display inline-flex
        align-items center
        height 20px
        .description_text
          font-size 12px
        .description_icon
          display inline-block
          width 18px
          height 18px
          margin-right 8px
          background-repeat no-repeat
          background-size 18px 18px
          background-position center center
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-archrylic-shape@2x.png")

      .controls
        display inline-flex
        width 100%
        height 32px
        background-color #FFFFFF
        font-size 14px
        .control_auto
          display inline-flex
          align-items center
          padding 0 4px
          width 0
          height 100%
          flex-grow 1
          border solid 1px #D5DBE0
          margin-right 14px
          .control_auto_button
            margin 0
            padding 0
            background-color transparent
            border 0 none
            width 24px
            height 24px
            background-position center center
            background-repeat no-repeat
            background-size 24px 24px
            &.control_auto_button_minus
              background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-minus-gray@2x.png")
            &.control_auto_button_plus
              background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-plus-gray@2x.png")
          .control_auto_label
            display inline-flex
            width 0
            height 100%
            flex-grow 1
            align-items center
            justify-content center
            font-size 14px
        .control_manual
          width 118px
          height 100%
          .control_manual_button
            width 100%
            height 100%
            box-sizing border-box
            border solid 1px #D5DBE0
            background transparent
            margin 0
            padding 0
