[frame_name="/modules/SVGEditor/MobileTextInput/S/Mui/frame.js"]
  opacity 0.8
#mobile_text_input_81102
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  z-index 10
  input
    background transparent
    border none
    width 100%
    text-align center
    outline none
    font-size 32px
    margin 20% 0 0 0
    padding 0
    &:focus
      outline none
