@import "../../../../../TopMenu/PC/F/Style/style.styl"

[page_name="/modules/VectorEditor/Sticker/Single/PC/CuttingLineManual/S/Mui/page.js"]
  position absolute
  width 100%
  > .don_wrapper
    position relative
    width 100%
    > .header
      display none
    > .body
      position relative
      width 100%

[tab_name="/modules/VectorEditor/Sticker/Single/PC/CuttingLineManual/S/Mui/tab.js"]
  user-select none
  position relative
  width 100%
  display flex
  > .don_wrapper
    position relative
    width 100%
    height 100%
    display flex

    .left_container
      position relative
      width 0
      flex-grow 1
      height 100%
      background-color #FFFFFF
      .editor_container
        position absolute
        top 0
        right 0
        bottom 0
        left 0
        background-color #F8F9FA
      .top_menu_container
        position absolute
        top 0
        left 0
        right 2px
        height 66px
        background-color #FFFFFF
        top-menus(100%, 100%)
    .right_container
      width 460px
      height 100%
      overflow hidden
      .right_wrapper
        width 100%
        height 100%
        display flex
        flex-direction column
        box-sizing border-box
        padding 40px 0 32px 0
        background-color #FFFFFF
        overflow hidden
        .panel_container
          width 100%
          height 0
          flex-grow 1
          position relative
          .right_panel
            position absolute
            width 100%
            height 100%
            background-color #FFFFFF
            display flex
            flex-direction column
            box-sizing border-box
            padding 0 40px
            .header
              position relative
              width 100%
              height 22px
              display flex
              align-items center
              justify-content center
              .title
                font-size 19px
            .wrapper
              margin-top 40px
              width 100%
              height 0
              flex-grow 1
              display flex
              flex-direction column
              .button_group
                width 100%
                display flex
                flex-direction column
                align-items flex-start
                &:not(:last-child)
                  margin-bottom 34px
                .button_group_label
                  font-size 14px
                  margin-bottom 16px
                .button_list
                  width 100%
                  display flex
                  flex-direction column
                  button
                    position relative
                    box-sizing border-box
                    width 100%
                    height 40px
                    border-width 1px
                    border-style solid
                    border-color #D5DBE0
                    background-color #FFFFFF
                    padding 0
                    margin 0
                    display flex
                    align-items center
                    justify-content center
                    &:not(:last-child)
                      margin-bottom 8px
                    .button_icon
                      position absolute
                      width 20px
                      height 20px
                      left 12px
                      top 50%
                      transform translateY(-50%)
                      background-repeat no-repeat
                      background-size 20px 20px
                      background-position center center
                      &.active
                        display none
                      &.inactive
                        display block
                    .button_text
                      font-size 14px
                    &:active
                      border-color #0157FF
                      .button_icon
                        &.active
                          display block
                        &.inactive
                          display none
                      .button_text
                        color #0157FF
                  button.add_l
                    .button_icon
                      &.active
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/pressed-selected/ic-path-line-sel@2x.png")
                      &.inactive
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/normal/ic-path-line@2x.png")
                  button.add_c
                    .button_icon
                      &.active
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/pressed-selected/ic-path-curve-sel@2x.png")
                      &.inactive
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/normal/ic-path-curve@2x.png")
                  button.remove
                    .button_icon
                      &.active
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/pressed-selected/ic-path-del-sel@2x.png")
                      &.inactive
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/normal/ic-path-del@2x.png")
                  button.change_to_c
                    .button_icon
                      &.active
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/pressed-selected/ic-replace-curve-sel@2x.png")
                      &.inactive
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/normal/ic-replace-curve@2x.png")
                  button.change_to_l
                    .button_icon
                      &.active
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/pressed-selected/ic-replace-line-sel@2x.png")
                      &.inactive
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/normal/ic-replace-line@2x.png")
                  button.reset
                    .button_icon
                      &.active
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/pressed-selected/ic-replace-new-sel@2x.png")
                      &.inactive
                        background-image url("//s3.marpple.co/vector-editor-assets/pc/path-button/normal/ic-replace-new@2x.png")
        .button_container
          width 100%
          height 56px
          display flex
          justify-content space-between
          box-sizing border-box
          padding 0 40px 0 40px
          button
            width 186px
            height 100%
            margin 0
            padding 0
            border 0 none
            &.cancel
              background-color #FFFFFF
              box-sizing border-box
              border 1px solid #D5DBE0
              color #000000
            &.done
              background-color #000000
              color #FFFFFF
