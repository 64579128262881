&
  > .don_wrapper
    height auto
    top 10px
    bottom 10px
    padding-top 56px
    >.header
      position absolute
      top 0
      left 0
      right 0
      height 56px
      background #fff
      border-bottom 1px solid #dfdfdf
      z-index 3
      .title
        text-align center
        height 56px
        line-height 56px
        font-size 18px

  [data-show=false]
    display none !important

  .account-lock-info
    margin-bottom 30px

  #withdraw-bank-info
    padding 20px

    .warning-box
      display flex
      justify-content center
      align-items center
      margin 10px auto 20px
      border-radius 3px
      background-color: #FFD950
      font-size 1.3rem
      padding 20px

    .chart-wrapper
      margin-bottom 20px
    .amount
      color green
    .withdraw-info
      line-height 2
      .thumb
        max-width 90%
        height 50px
      .status
        text-align center


    .buttons
      display flex
      justify-content space-between
      .change_withdraw
        margin-right auto
