reset-button()
  margin 0
  padding 0
  border none
  background none

reset-list()
  margin 0
  padding 0
  text-indent 0
  list-style none

blind()
  position absolute
  width 1px
  height 1px
  margin -1px
  overflow hidden
  clip-path polygon(0 0, 0 0, 0 0)

font(fz, lh, ls, fw = normal)
  font-size fz
  line-height lh
  letter-spacing ls
  font-weight fw

displayFlex(j = flex-start, a = flex-start)
  display flex
  justify-content j
  align-items a

$flex(jc=flex-start, ai=center, di=row) {
  display: flex;
  flex-direction: di;
  justify-content: jc;
  align-items: ai;
}

flexCenter(direction = row)
  display flex
  flex-direction direction
  justify-content center
  align-items center

flexDirection(d, w = nowrap)
  flex-direction d
  flex-wrap w

pxToRem(value)
  unit(value/14, 'rem')

ptr(value)
  pxToRem(value)

fontPxToRem(f, lh)
  @media screen and (max-width pc-break-point)
    font-size pxToRem(f)
    line-height pxToRem(lh)

pc-layout-inner-style()
  margin 0 auto
  max-width pc-max-width
  width 100%
  box-sizing border-box
  padding 0 pxToRem(80px)

stripe-image(x = 0, y = 0)
  background url(//s3.marpple.co/files/u_1396787/2021/4/original/73432b5a7c3fdaa2e1202d56fe52c4a20dace2061.png) no-repeat
  background-size 200px auto
  background-position x y

stripe-image-before(w, h, u, s)
  display block
  width w
  height h
  background url(u) no-repeat
  background-size s auto
  content ''

absolute-box(t = 8px, r = 8px)
  position absolute
  top t
  right r
  display flex
  align-items center

community-utils(w = 35px, h = 34px, u)
  reset-button()
  display block
  width w
  height h
  background url(u) no-repeat
  background-size w h

hideScrollBar()
  -ms-overflow-style none
  scrollbar-width none
  &::-webkit-scrollbar
    display none

limitLine(l)
  display -webkit-box
  -webkit-box-orient vertical
  -webkit-line-clamp l
  overflow hidden

iconPlus(size = 30px, bh = 2px)
  position relative
  &::before
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    width bh
    height size
    background-color #e2e2e2
    content ''
  &::after
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    width size
    height bh
    background-color #e2e2e2
    content ''

imageUploadBox()
  position relative
  height 200px
  border 1px solid #e2e2e2
  iconPlus()

userSelectNone()
  -webkit-user-select none
  -khtml-user-select none
  -moz-user-select none
  -o-user-select none
  user-select none

userDragNone()
  -webkit-user-drag none
  -khtml-user-drag none
  -moz-user-drag none
  -o-user-drag none
  user-drag none

marginPaddingZero()
  margin 0
  padding 0

applyGap(gapSize)
  & > *
    margin-bottom ptr(gapSize)
  & > *:last-child
    margin-bottom 0
  & > [style*="display: none"]
    margin-bottom 0