@charset "utf-8"
$grey_400 = #828282
$grey_500 = #4c4c4c
$grey_300 = #a9a9a9
$grey_100 = #f7f7f7
$gray200 = #d0d0d0
$product_bg = #f6f6f6

@import './typography.styl';
@import './color.styl';
@import './style_mixins.styl';

// 마플샵 2.0
@import 'marppleshop2/Share/index.styl';

// common
@import 'feed/feed.styl'
@import "mp.shop.product/item.styl"
@import "./frame.modal.styl"

@import "crew.styl"


@import "community/community.styl"
@import "community/community_mobile.styl"
@import "plus/plus.styl"
@import "../all/composite_template_selection.styl"

@import "feed/editor_root.styl"

@import "plus/index.styl"

#ch-plugin-launcher
  display none !important

html[bonus_profit_service="false"]
  .bonus-profit-service
    display none !important

@import "../../../modules/SVGEditor/F/Style/style.styl"
@import "../../../modules/VectorEditor/F/Style/style.styl"
