&
  background-color #ffffff
  box-sizing border-box
  border-right 1px solid rgba(24, 28, 33, 0.06)

  [data-show=false]
    display none

  .menus
    width 100%

    margin 0
    padding 1rem 0 0 0

    display flex
    flex-direction column

    list-style none

    .menu_spliter
      width 100%
      height 1px
      background-color #f2f2f2
      margin 10px 0

    li
      width 100%
      overflow hidden
      user-select none
      .menu
        color #000000
        width 100%
        cursor pointer
        display inline-flex
        align-items center

        box-sizing border-box
        padding 17px 0 17px 25px
        .icon-container
          width 18px
          display inline-flex
          justify-content center
          margin-right 10px
        i
          font-size 16px
          width 16px
          height 16px
          color #4c4c4c
          &.small
            font-size 15px
            width 15px
            height 15px
          &.big
            font-size 17px
            width 17px
            height 17px
          &.youtube-icon
            width 18px
            height 18px
            background-image url("//s3.marpple.co/files/u_29089/2021/7/original/77c81ad64b85a6137df39af0d884c1278ff80b031.png")
            background-size 18px 18px
            background-repeat no-repeat
          &.adobe_illustrator-icon
            width 20px
            height 20px
            background-image url("//s3.marpple.co/files/u_218933/2022/4/original/3d596587ffb0d20781da6ecacc835b6bf2e0d4941.png")
            background-size 20px 20px
            background-repeat no-repeat
          &.seller-guide-icon
            width 20px
            height 20px
            background-image url("//s3.marpple.co/files/u_218933/2022/4/original/3d596587ffb0d20781da6ecacc835b6bf2e0d4941.png")
            background-size 20px 20px
            background-repeat no-repeat
          &.ccc-lounge-icon
            width 20px
            height 20px
            background-image url("//s3.marpple.co/files/u_218933/2022/7/original/6f0e9b62d60746d2c56a50bd877904909fe4a8971.png")
            background-size 20px 20px
            background-repeat no-repeat
        .submenu_icon
          font-size 12px
          width 12px
          height 12px
          margin-left auto

      &:hover
        > .menu
        > .menu i
          color #002aff

    li[data-selected="true"]
      >.menu
      >.sub_menu
      >.menu i
        color #002aff
        font-weight 500

    .sub_menus li
      display flex
      align-items center

    .sub_menus
      height 0
      padding 0
      .sub_menu
        color #a3a4a6
        &:hover
          color #002aff
      li
        list-style none

    .menu_dropdown
      display none
    .menu_dropdown:checked ~
      .sub_menus
        height auto
        transition height 100ms linear
        padding-left 26px
