&
  width 100%
  min-height 100%
  display flex
  flex-direction column
  .body.white
    width 1132px
    max-width 100%
    .request-of-solution
      margin 40px auto
      width 1080px
      >.header
        margin-bottom 16px


