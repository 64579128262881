
.crew_select_sellers_area
  width 100%
  .selected_sellers
    border 1px transparent
  &[data-length="0"]
    .selected_sellers
      border 1px dotted #d9d9d9
      ul
        display none
      .empty
        display flex
        align-items center
        justify-content center
        min-height 200px
        p
          margin 0
          padding 0
          text-align center
          font-size 14px
          line-height 1.5
          letter-spacing -0.5px
          color #4c4c4c
  .header
    position relative
    margin-bottom 40px
    h2
      margin 0 0 10px
      padding 0
      font-size 22px
      font-weight 600
      letter-spacing -1px
      color #000
    p
      margin 0
      padding 0
      font-size 14px
      letter-spacing -0.5px
      color #4c4c4c
    .controls
      position absolute
      right 0
      bottom 0
      button
        display flex
        justify-content center
        align-items center
        width 120px
        height 40px
        font-size 14px
        font-weight 600
        letter-spacing -0.5px
        text-align center
        color #ffffff
        border-radius 2px
        border 0 none
        background-color #3c89f9
        &::before
          display block
          margin-right 10px
          width 12px
          height 8px
          background url("//s3.marpple.co/files/u_218933/2020/11/original/f0d82b2061fa8d9d73363a500f85c60dfad1a82e2.svg") no-repeat 0 0
          content: ''
  .selected_sellers
    margin-bottom 30px
    min-height 200px
    .empty
      display none
    ul
      margin 0
      padding 20px
      list-style none
      display flex
      flex-wrap wrap
      justify-content start
      align-content flex-start
      .seller.drag_ghost
        opacity 0.2
      .seller.on_drag
        cursor grabbing
        .thumb img
          width 80px
          height 80px
        .name
        .btn_del
          display none
      .seller
        position relative
        margin-right 40px
        width 120px
        height 160px
        vertical-align top
        cursor grab
        box-sizing border-box
        .thumb
          width 120px
          height 120px
          text-align center
          img
            width 100%
            height 100%
            border-radius 50%
            border 1px solid #e5e5e5
            box-sizing border-box
        .name
          margin-top 20px
          font-size 14px
          line-height 1.29
          letter-spacing -0.5px
          text-align center
          color #000
        .btn_del
          position absolute
          top 5px
          right 5px
          width 22px
          height 22px
          cursor pointer
          display flex
          line-height 1
          align-items center
          justify-content center
          background-color #fff
          border 1px solid #ccc
          border-radius 50%
  h3
    margin 0 0 20px
    padding 0
    font-size 14px
    font-weight 500
    letter-spacing -0.5px
    color #000
  .sellers
    border-bottom 1px solid #eee
    ul
      position relative
      z-index 1
      margin 0 0 -1px
      padding 0
      list-style none
      display flex
      flex-wrap wrap
      justify-content start
      align-content flex-start
      border-top 1px solid #000
      li
        margin-top -1px
        padding-left 10px
        width 25%
        height 80px
        vertical-align top
        display flex
        overflow hidden
        cursor pointer
        box-sizing border-box
        border-top 1px solid #eee
        border-bottom 1px solid #eee
        &:nth-child(1)
        &:nth-child(2)
        &:nth-child(3)
        &:nth-child(4)
          border-top 0 none
        label
          display flex
          width 100%
          align-items center
          cursor pointer
        .check
          margin-right 10px
        .thumb
          margin-right 20px
          width 42px
          height 42px
          img
            width 100%
            height 100%
            border-radius 50%
            border 1px solid #e5e5e5
            box-sizing border-box
        .name
          flex 1
          font-size 14px
          letter-spacing -0.5px
          color #000

@media screen and (min-width: 1025px)
  .plus-seller-list
    .seller-list
      margin-bottom 50px
      ul
        margin 0
        padding 0
        list-style none
        display flex
        justify-content flex-start
        flex-wrap wrap
        align-items center
        li
          display flex
          justify-content center
          width 25%
          height 318px
          .thumb
            width 216px
            height 216px
            img
              width 100%
              height 100%
              border-radius 50%
              border 1px solid #e5e5e5
              box-sizing border-box
          .name
            margin-top 10px
            width 216px
            font-size 20px
            font-weight 500
            line-height 1.7
            text-align center
            color #000

@media screen and (max-width: 1024px)
  .plus-seller-list
    .seller-list
      ul
        margin 0 0 20px
        padding 0
        list-style none
        display flex
        flex-wrap wrap
        li
          width 50%
          margin-bottom 30px
          text-align center
          .thumb
            margin 0 auto
            width 128px
            height 128px
            img
              width 100%
              height 100%
              border-radius 50%
              border 1px solid #e5e5e5
              box-sizing border-box
          .name
            margin 10px auto 0
            width 128px
            height 22px
            overflow hidden
            font-size 16px
            font-weight 500
            line-height 1.5
            text-align center
            color #000


@media screen and (min-width: 1025px)
  .plus-home-seller-section
    &[data-sellers_length="0"]
      display none
  .plus-home-seller
    margin-top 96px
    margin-bottom 80px
    .section-title-container
      margin-bottom 48px !important
    .seller-container
      .sellers
        margin 0 0 34px
        padding 0
        list-style none
        display flex
        justify-content center
        align-items center
        .seller.on
          .thumb
            border-color #333
           .name
            color #000
        .seller
          width 106px
          height 146px
          margin 0 35px
          cursor pointer
          &:not(.on)
            .thumb
              border-color transparent !important
          .thumb
            width 100px
            height 100px
            padding 4px
            border-radius 50%
            border 4px solid transparent
            img
              border-radius 50%
              width 100px
              height 100px
              vertical-align top
              box-sizing border-box
              border 1px solid #e5e5e5
          .name
            margin-top 4px
            text-align center
            font-size 18px
            font-weight 500
            line-height 1.7
            color #828282
            height 24px
            overflow hidden
    .products
      min-height 304px
    .btn_seller_more
      margin-top 10px
      text-align center
      a
        display inline-block
        border solid 1px #f2f2f2
        padding 13px 139px 11px 151px
        background-color #fff
        font-size 16px
        font-weight normal
        line-height 1.5
        color #000
        span
          display inline-block
          padding-right 20px
          background url("//s3.marpple.co/files/u_218933/2020/11/original/4642ed8df620f3752541d41c75099c4ff1bfbc971.svg") no-repeat 100% 40%

@media screen and (max-width: 1024px)
  .plus-home-seller-section
    &[data-sellers_length="0"]
      display none
  .plus-home-seller
    margin-bottom 40px
    .section-title-container
      margin-bottom 13px !important
      line-height 1.67 !important
    .seller-container
      display flex
      flex-wrap wrap
      justify-content center
      .sellers
        margin 0 -10px
        padding 0 0 5px
        list-style none
        display flex
        align-items center
        overflow hidden
        overflow-x auto
        .seller.on
          .thumb
            border-color #333
           .name
            color #000
        .seller
          flex-basis 70px
          width 70px
          height 106px
          margin 0 11px 0 0
          cursor pointer
          &:not(.on)
            .thumb
              border-color transparent !important
          &:first-child
            margin-left 10px
          .thumb
            width 64px
            height 64px
            padding 3px
            border-radius 50%
            border 3px solid transparent
            img
              border-radius 50%
              width 100%
              height 100%
              vertical-align top
              box-sizing border-box
              border 1px solid #e5e5e5
          .name
            margin-top 4px
            text-align center
            font-weight 500
            color #828282
            height 24px
            overflow hidden
            font-size 14px
            line-height 1.71
    .products
      margin-top 10px
      width 100%
    .btn_seller_more
      margin-top 13px
      text-align center
      a
        display block
        border solid 1px #f2f2f2
        padding 12px 20px 12px
        background-color #fff
        line-height 1.5
        color #000
        font-size 14px
        letter-spacing normal
        text-align center
        span
          display inline-block
          padding-right 20px
          background url("//s3.marpple.co/files/u_218933/2020/11/original/72fa250a62c58ff53d79cca16e45786adacf7b3a1.svg") no-repeat 100% 40%

