&
  width 100%
  min-height 100%
  display flex
  flex-direction column

  [data-show="false"]
    display none

  .header
    margin-top 30px
    flex-shrink 0
    width 100%
    h2
      line-height 4rem
      height 4rem
      margin 0
    @import "../filter.styl"

  .chart-wrapper

    .store_desc
      width 30%
      word-break break-all
    .store_review
      width 10%
    tbody
      tr
        height 4rem
        .link
          @import "../links.styl"
        .store_label
          @import "../store_label.styl"
