
body.marpple-plus
  #don_up
    display none !important
  .don_dialog_msg
    outline none
  .mps_plus_app_frame
    z-index 10 !important

html[is_mobile="true"]
  .mps_plus_app_frame
    > .don_wrapper
      > .header
        background-color transparent !important
  .mp_shop_plus
    overflow hidden
    > div
      min-width 1400px

.mp_shop_plus
  > div
    min-width 1440px
  .wrapper
    max-width 1280px
    margin 0 auto
  video
    outline none
    -webkit-outline none
    -moz-outline none
  img
    vertical-align top
    max-width 100%
  span.btn_mp_shop_plus_app_go
    margin-top 42px
    display inline-block
    padding 20px 40px
    font-size 20px
    color #fff
    cursor pointer
    letter-spacing -2px
    border 0 none
    border-radius 6px
    background-color #0028f1
  .logo
    h2
      margin 0
      padding 30px 0 10px
  .logo
  .section1
    background-color #f0f0f0
  .section1
    position relative
    .wrapper
      position relative
      padding 140px 0 190px
    .movie1
      position absolute
      top 190px
      right 0
      width 50%
      height auto
      video
        width 100%
        height 100%
  .section2
    padding 170px 0 160px
    background #fff url("//s3.marpple.co/files/u_218933/2020/9/original/a026e89973c6b63e904b174562ce7f3ee4718f6a1.png") no-repeat
    background-size auto 547px
    background-position 50% 160px
    .wrapper
      position relative
      min-height 547px
    .img2
      position absolute
      bottom -80px
      right -36px
  .section3
    background-color #fff
    .wrapper
      position relative
      min-height 880px
      .img1
        padding 190px 70px 0 0
        text-align right
      .img2
        position absolute
        top 0
        left 0
      .img3
        position absolute
        right -70px
        bottom -250px
  .section4
    background-color #002ef3
    .wrapper
      max-width none
    .img1
      position relative
      z-index 1
      margin-bottom -20px
      padding-top 130px
      text-align center
    .movie1
      min-height 556px
      video
        width 100%
  .section5
    min-height 1080px
    background #002ef3 url("//s3.marpple.co/files/u_218933/2020/8/original/4a4ea5cc31c6f2e93b0e4a77f5764ccd71ee9d531.png") no-repeat
    background-size auto 690px
    background-position center center
  .section6
    min-height 1080px
    background-color #fff
    .wrapper
      position relative
    .img1
      position absolute
      top 210px
      left 0
    .movie1
      text-align right
      video
        vertical-align top
    .btn_mp_shop_plus_app_go
      margin-top 42px
      cursor pointer
  .footer
    background-color #000
    .wrapper
      display flex
      justify-content space-between
      max-width 930px
      min-height 400px
      padding 130px 0
      margin 0 auto
      color #fff
      a
        color #fff
      .footer_list
        margin 0
        padding 0
        list-style none
        li
          margin-bottom 22px
          font-size 20px
          letter-spacing -0.6px
          color #fff
          .btn_mp_shop_plus_app_open
            cursor pointer
      .right_area
        width 460px
        .customer_center
          font-size 12px
          .customer_center_tel
            margin-left -10px
            margin-bottom 30px
            font-size 70px
            font-weight 300
            line-height 1.14
            letter-spacing -4px
            color #ffffff
          .email
            a
              text-decoration underline
        .sns
          margin-top 70px
          margin-bottom 36px
          span
            display inline-block
            margin-right 10px
        address
          margin-bottom 36px
          opacity 0.4
          font-size 12px
          font-style normal
          line-height 1.5
        .txt1
          opacity 0.4
          line-height 1.5

.mp_shop_plus_app
  > .don_wrapper
    width 100% !important
    height 100% !important
    background-color #000
    > .header
      > .don_hide_frame
        display none !important
        background-image url("//s3.marpple.co/files/u_218933/2020/8/original/77953595679f1d00dac95f6d56655876203f84f01.svg")
        background-size 80px !important
        top 0 !important
        right 10px !important
        width 80px !important
        height 80px !important
    > .body
      > .don_page
        height 100%
        > .don_wrapper
          height 100%
          > .body
            height 100%
            > .don_tab
              height 100%
              > .don_wrapper
                height 100%

.mps_plus_app_frame
  > .don_wrapper
    display block !important
    width 100% !important
    height 100% !important
    max-width none !important
    max-height none !important

.mps_plus_app
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  overflow auto
  display flex
  justify-content center
  -webkit-transform all 200ms cubic-bezier(0, 0.9, 0.4, 1)
  transition all 200ms cubic-bezier(0, 0.9, 0.4, 1)
  -webkit-transform scale3d(0.85, 0.85, 1)
  transform scale3d(0.85, 0.85, 1)
  background-color #000
  > .btn_close
    position absolute
    top 0
    right 0
    width 80px
    height 80px
    border none
    outline none
    cursor pointer
    background-image url("//s3.marpple.co/files/u_218933/2020/8/original/77953595679f1d00dac95f6d56655876203f84f01.svg")
    background-size 80px !important
    background-color transparent
    background-position 50% 50%
    background-repeat no-repeat
  .wrapper
    min-width 1220px
    min-height 500px
    background url("//s3.marpple.co/files/u_218933/2020/8/original/34745512d098b8841710a1b9f33ea80dfbc1caac4cc3cd3.png") no-repeat
    background-position 100% 100px
    background-size 640px auto
  h3
    margin 0
    padding 50px 0 28px
    color #fff
    letter-spacing -1.5px
    font-size 48px
    font-weight normal
    letter-spacing -4.8px
  p.txt2
    font-size 17px
    line-height 1.35
    letter-spacing -0.79px
    text-align justify
    color #fff
  .control
    display flex
    align-items center
    margin-bottom 12px
    width 568px
    height 44px
    border-radius 4px
    background-color #fff
    label
      width 80px
      padding 0 20px 0 18px
      font-size 17px
      letter-spacing -0.7px
      line-height 2
    input
      padding 0 10px
      border 0 none
      width 425px
      font-size 20px
      letter-spacing -1px
      line-height 30px
      height 30px
      background-color transparent
  .control_type
    .selectbox
      position relative
      width 450px
      height 100%
      select::-ms-expand
        display none
      select[name="type"]
        position absolute
        top 0
        left 0
        padding-left 10px
        z-index 1
        opacity 0.4
        background-color transparent
        border 0 none
        -webkit-appearance none
        appearance none
        font-size 17px
        line-height 1.5
        width 100%
        height 100%
        &.selected
          opacity 1
      .btn_select_type
        position absolute
        top 0
        right 0
        width 80px
        height 100%
        text-align center
        &:before
          display block
          content ' '
          position absolute
          top 8px
          left 0
          bottom 8px
          width 1px
          background-color #ababab
        img
          height 100%
  .control_files
    position relative
    .input
      position relative
      width 360px
      height 100%
      overflow hidden
      display flex
      align-items center
      font-size 17px
      line-height 1.5
      span
        opacity 0.45
    input[type="file"]
      display none
    .btn_file_upload
      position absolute
      top 0
      right 0
      width 80px
      height 100%
      text-align center
      cursor pointer
      &:before
        display block
        content ' '
        position absolute
        top 8px
        left 0
        bottom 8px
        width 1px
        background-color #ababab
      img
        height 100%
  .textarea
    textarea
      margin-bottom 12px
      width 568px
      height 105px
      padding 20px
      border-radius 4px
      background-color #fff
      font-size 17px
      line-height 1.5
      resize none
      letter-spacing -0.79px
      box-sizing border-box
  p.txt1
    margin 0
    padding 0
    color #fff
    font-size 17px
    letter-spacing -0.7px
  .check
    margin 60px 0 0
    color #fff
    font-size 17px
    line-height 2
    letter-spacing -0.7px
    &[is_check="true"]
      label
        background-image url("//s3.marpple.co/files/u_218933/2020/8/original/4ac4d109260a7900ce882a7cd9bc645d5dc757a51.svg")
    label
      display inline-block
      padding-left 37px
      background url("//s3.marpple.co/files/u_218933/2020/8/original/39635d25e49daecd171be5409edfd5565b92c6071.svg") no-repeat
      background-size 26px 26px
      background-position 0 50%
      input
        display none
    p
      margin 0
      padding 0
      font-size 15px
      line-height 1.54
      letter-spacing -0.7px
      -webkit-text-size-adjust none
  .btn_plus_app
    margin-top 32px
    margin-bottom 100px
    display inline-block
    padding 0
    font-size 22px
    color #fff
    width 568px
    height 62px
    letter-spacing -1px
    border 0 none
    border-radius 6px
    background-color #0028f1
    outline none
    -webkit-outline none
    -moz-outline none


@media screen and (min-width: 1500px)
  .mp_shop_plus
    > div
      min-width 1400px
    .wrapper
      max-width 1400px
      margin 0 auto
    .section1
      .movie1
        width 800px
    .section3
      .wrapper
        .img1
          padding-right 170px
        .img3
          right -20px
    .section6
      .movie1
        padding-right 50px
    .section7
      .wrapper
        background-size 740px auto
