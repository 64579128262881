@require 'crew.banner.mixins.styl'

titleStyle()

.crew-layout
  position relative
  width 100%
  &__inner
    width 480px
  &__title
    resetSpace()
    display flex
    align-items center
    letter-spacing -.5px
    font-weight 600
    color black
    &--h1
      font-size 22px
      letter-spacing -1px
    &--h2
      font-size 18px
    &--h3
      margin-top 20px
      font-size 14px
      line-height 23px
      ~/__title--h2 + &
        margin-top 30px
  &__title-description
    margin-left 5px;
    description()
  &__description
    resetSpace()
    margin-top 10px
    letter-spacing -.5px
    color #4c4c4c
  &__section
    margin-top 80px
    &::first-child
      margin-top 60px
  &__row
    margin-top 5px
  &__input
    inputStyle()
  &__country-input
    countryIcon()
  &__submit
    submitButton()
    position absolute
    top 14px
    right 0
  &__group
    margin-top 30px

.crew-radio
  display flex
  align-items center
  &--column
    flex-direction column
    align-items flex-start
  &__box
    display flex
    align-items center
    letter-spacing -.5px
    & ~ &
      margin-left 20px
    ~/--column & ~ &
      margin 10px 0 0
  &__input
    resetSpace()
  &__label
    margin-left 5px

.crew-file-upload
  position relative
  display flex
  align-items center
  &__anchor
    inputStyle()
    display flex
    align-items center
    ~/__input--uploaded ~ &
      text-decoration underline !important
  &__placeholder
    font-weight 500
    color #b2b2b2
    ~/__input--uploaded ~ ^[0]__anchor &
      display none
  &__file-input
    blind()
  &__button
    display flex
    justify-content center
    align-items center
    margin-left 10px
    width  100px
    height 40px
    border-radius 2px
    border 1px solid #ededed
    background-color white
    font-weight 600
    color black

@import '../plus/plus.crew_arrange.styl'

html.creator.crew
  @import '../../../../modules/MShop/Util/F/Style/Pc/toast.styl'

.crew-radio
  display flex
  align-items center
  &--column
    flex-direction column
    align-items flex-start
  &__box
    display flex
    align-items center
    letter-spacing -.5px
    & ~ &
      margin-left 20px
    ~/--column & ~ &
      margin 10px 0 0
  &__input
    resetSpace()
  &__label
    margin-left 5px
