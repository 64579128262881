&
  margin-bottom 120px
  @media screen and (max-width: 1024px)
    margin-bottom 64px

  feed_card(0px)

  .event-container
    display flex
    flex-wrap wrap
    justify-content center

    .feed_card
      margin 0
      .thumbnail
        margin-bottom 24px
      .text
        background-color transparent
        .title
          font-size 24px
          line-height 1.14
        .description
          font-size 16px
      @media screen and (max-width: 1024px)
        .thumbnail
          margin-bottom 17px
        .text
          .title
            font-size 20px
            line-height 1.4
          .description
            font-size 12px



    .feed_card:first-child, .feed_card:nth-child(4)
      margin-top 72px
      width 32.28%

    .feed_card:first-child
      margin-right 10%

    .feed_card:nth-child(4)
      margin-left 10%;

    .feed_card:nth-child(2), .feed_card:nth-child(3)
      width 40.75%

    &.single
      .feed_card:first-child
        margin-right 0
        margin-top 0

    &.triple
      .feed_card:nth-child(3)
        margin-right 10%
        width 32.28%

    &.full, &.triple
      .feed_card:nth-child(4), .feed_card:nth-child(3)
        margin-top 56px

  @media screen and (max-width: 1024px)
    .event-container
      margin auto -10px

      &.single, &.triple, &.full
        .feed_card
          &:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(4)
            width 100%
            margin 0
            margin-bottom 40px
        .feed_card:last-child
          margin-bottom 0


      .feed_card
        &:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(4)
          margin 0
          margin-bottom 40px
          width 100%

      .feed_card:last-child
        margin-bottom 0

        .thumbnail
          margin-bottom 12px












