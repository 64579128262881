.process_time_loader
  position fixed
  z-index 9999
  top 0
  right 0
  bottom 0
  left 0
  .process_time_loader__container
    position relative
    width 100%
    height 100%
    background-color rgba(255 255 255 0.8)
    display flex
    flex-direction column
    align-items center
    justify-content center
    .process_time_loader__message
      text-align center
  &.pc
    .process_time_loader__percent
      font-size 30px
      font-weight bold
      margin-bottom 20px
    .process_time_loader__message
      font-size 14px
      font-weight normal
      line-height 21px
  &.mobile
    .process_time_loader__percent
      font-size 26px
      font-weight bold
      margin-bottom 20px
    .process_time_loader__message
      font-size 14px
      font-weight normal
      line-height 21px
