&
  display flex
  align-items center
  justify-content space-between
  width 100%
  height 5rem
  padding 0 1rem
  box-sizing border-box
  .don_hide_frame
    position static
  .title
    font-weight 600
    color #000000
