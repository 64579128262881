@import "layout/style.styl"
@import "home/style.styl"
@import "product/style.styl"
@import 'event/home_list.styl'
@import 'event/home_detail.styl'
@import "seller.styl"
@import "community.styl"


@media screen and (min-width: 1025px)
  .empty_plus_page
    margin 150px auto
    max-width 1120px
    width 96%
    text-align center
    img
      width 68px
    p
      margin 20px 0 30px
      padding 0
      text-align center
      font-size 20px
      line-height 1.5
      letter-spacing -1px
      color #000

  .empty_plus_contents_page
    padding ptr(160) ptr(32) !important
    &__container
      display flex
      flex-direction column
      row-gap ptr(24)
      align-items center
      text-align center
    &__title
      color $gray-80
      margin-bottom ptr(8) !important
    &__description
      color $gray-50
    &__image
      display inline-block
      width ptr(160)
      aspect-ratio 1


@media screen and (max-width: 1024px)
  .empty_plus_page
    margin-top 80px
    text-align center
    img
      width 48px
    p
      margin 20px 0 30px
      padding 0
      text-align center
      font-size 14px
      line-height 1.5
      letter-spacing -0.5px

  .empty_plus_contents_page
    padding ptr(120) 0 !important
    &__container
      display flex
      flex-direction column
      row-gap ptr(24)
      align-items center
      text-align center
    &__title
      color $gray-80
      margin-bottom ptr(8) !important
    &__description
      color $gray-50
    &__image
      display inline-block
      width ptr(120)
      aspect-ratio 1


