&
  &[data-is_edit_store_label="false"] > span
    display inline-flex
    align-items center
    justify-content center
    width 6rem
    padding 0.4rem 0
    border-radius 0.4rem
  &[data-is_edit_store_label="true"] input
    width 8rem
    height 2rem
    border: 1px solid #767676
  .fx-auto-complete-list
      position absolute
      left 0
      top 2rem !important
      z-index 1
      width: max-content
      min-width: 8rem
      ul.fx-auto-complete-list-wrap
        background #eee
        border 1px solid rgba(24,28,33,0.1)
        border-top none
        padding 0
        margin 0
        list-style none
        overflow hidden
        &[len="0"]
          display none
        li
          cursor pointer
          padding 10px
          &[selected="true"]
            color #fff
            background-color #999

