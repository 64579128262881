color-selector(selector-width, selector-height, color_size = 28px)
  .color_selector
    width selector-width
    height selector-height
    .color_selector_label
      font-size 14px
      display block
      margin-bottom 14px
    .color_grid
      width 100%
      .color_line
        width 100%
        height 24px
        display flex
        align-items center
        justify-content space-between
        .color
          position relative
          width color_size
          height color_size
          display inline-flex
          align-items center
          justify-content center
          cursor pointer
          .color_outer
            position absolute
            width color_size
            height color_size
            border-radius 50%
            box-sizing border-box
          .color_inner
            position absolute
            width color_size - 4px
            height color_size - 4px
            border-radius 50%
            background-size color_size - 4px color_size - 4px
            background-position center center
          &.active
            .color_outer
              border 1px solid #0157FF !important
        .color_placeholder
          width color_size
          height color_size
      .color_line:not(:last-child)
        margin-bottom 12px
