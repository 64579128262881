&
  width 100%
  min-height 100%
  display flex
  flex-direction column

  [data-show="false"]
    display none

  .body.bank_account
    margin-top 30px

  h2
    flex-shrink 0
    width 100%
    height 4rem
    margin 0
    display flex
    align-items center


  .fx-auto-complete-list
    top auto
    bottom 0
    transform translate(0, 100%)
    max-height 300px
    overflow-y scroll

  p
    margin-bottom 1.5em
    line-height 1.5em

  .form-group
    width 400px
    margin-bottom 80px

  .form-item
    input
      margin-bottom 8px

    &.business-no
      .input-spliter
        display inline-block
        min-width 12px
        text-align center
      input
        max-width calc(33.333333% - 8px)

    input[type="file"]
      display none

    .btn
      cursor pointer

    .upload.btn
      margin 0 3px

    .flex-box
      display flex
