// modules/Plus/PopupStores/Detail/F/Style/Pc/index.styl

.popup-store-item-card
  $flex(flex-start, flex-start, column)
  gap ptr(16)
  width 100%
  &__thumbnail
    width 100%
    aspect-ratio 382 / 286
    border-radius ptr(16)
    overflow hidden
    img
      width 100%
      height 100%
      object-fit cover
  &__number
    $flex(center, center)
    display inline-flex
    height ptr(24)
    margin 0
    padding ptr(4) ptr(6)
    border-radius 4px
    background var(--Gray-5, #F5F5F5)
  &__title
    margin 0
    font-size 20px
    font-weight 700
    line-height 28px;
    color var(--Black, #141414)
    word-break keep-all
    text-overflow ellipsis
    overflow hidden
  &__status
    margin 0
    $flex(flex-start, center)
    gap ptr(8)
    color var(--Gray-50, #A2A2A2)

.popup-store-edit-area
  & *
    margin 0
    padding 0
    box-sizing border-box


body.crew-popup-store-detail
  .popup-store-detail-page
    width 100%
    .plus-page-header
      justify-content space-between
      width 100%
    .floating_save_button
      position: fixed
      bottom: ptr(40)
      right: ptr(40)
      border-radius: ptr(4)
      overflow: hidden
      opacity: 0;
      visibility: hidden;
      transition: opacity 300ms ease-out, visibility 300ms ease-out;
      z-index: 1;
      &.active
        opacity: 1
        visibility: visible

  .popup-store-edit-area
    $flex(flex-start, stretch)
    gap ptr(24)
    &__meta
      width ptr(448)
    &__contents
      $flex(flex-start, stretch, column)
      gap ptr(24)
      flex 1
      width ptr(1130)

  .popup-store-edit-section
    $flex(flex-start, stretch, column)
    gap ptr(24)
    padding ptr(32)
    height 100%
    border 1px solid #F5F5F5
    border-radius ptr(8)

  .popup-store-form-field
    $flex(flex-start, stretch, column)
    gap ptr(8)
    &__title
      $flex(flex-start, center)
      gap ptr(8)
      h4
        {$font-body-b3-b}
        color #141414
      p
        {$font-body-b5-m}
        color #A2A2A2
    &.row
      $flex(space-between, flex-start)
    .add-products-modal-btn
      padding ptr(13) ptr(24)
      {$font-body-b3-m}
      color #1C75FF !important
      border-radius ptr(4)
      border 1px solid var(--Blue-100, #1C75FF)
      background var(--White, #FFF)

  .popup-store-control
    &__suffix,
    &__max-length
      flex-shrink 0
      {$font-body-b5-m}
      color var(--Gray-50, #A2A2A2);

  .popup-store-control-group
    $flex(flex-start, stretch, column)
    gap ptr(8)

  .popup-store-control-file
    $flex(flex-start, stretch, column)
    gap ptr(8)
    position relative
    &__label
      position relative
      $flex(center, center, column)
      gap ptr(16)
      cursor pointer
      border-radius ptr(8)
      border 1px dashed var(--Gray-20, #D9D9D9)
      background var(--White, #FFF)
      overflow hidden
      input
        position absolute
        width 0
        height 0
        opacity 0
      p
        {$font-body-b3-m}
        color var(--Gray-80, #6B6B6B)
    &__label.image_attached
      .popup-store-control-file__preview
        display block
      .popup-store-control-file__icon
        display none
    &__preview
      display none
      position absolute
      inset 0
      img
        width 100%
        height 100%
        object-fit cover
    &__icon
      display block
      width ptr(60)
      height ptr(60)
      padding ptr(12)
      border-radius 50%
      background var(--Blue-100, #1C75FF)
      img
        width 100%
        height 100%
        object-fit contain
    &__info
      $flex(space-between, center)
      gap ptr(12)
      padding ptr(8) ptr(16)
      {$font-body-b4-r}
      color var(--Gray-80, #6B6B6B)
      border-radius ptr(6)
      background var(--Gray-5, #F5F5F5)


  .popup-store-control-text
    $flex(space-between, center)
    padding ptr(9) ptr(12)
    gap ptr(8)
    background #fff
    border-radius ptr(4)
    border 1px solid var(--Gray-10, #E7E7E7)
    &.invalid
      border-color red
    input
      {$font-body-b3-m}
      flex 1
      border none !important
      outline none !important
      &:placeholder
        color var(--Gray-50, #A2A2A2)
    &__suffix,
    &__max-length
      flex-shrink 0
      {$font-body-b5-m}
      color: var(--Gray-50, #A2A2A2);

  .popup-store-control-textarea
    $flex(space-between, center)
    gap ptr(8)
    padding ptr(9) ptr(12)
    background #fff
    border-radius ptr(4)
    border 1px solid var(--Gray-10, #E7E7E7)
    &.invalid
      border-color red
    textarea
      flex 1
      {$font-body-b3-m}
      resize none
      border none !important
      outline none !important
      &::placeholder
        color var(--Gray-50, #A2A2A2)
    &__prefix
      align-self flex-start
      flex-shrink 0
      width ptr(18)
    &__flag
      display block
      padding-top ptr(2)
      img
        width 100%
    &__max-length
      align-self flex-end
      flex-shrink 0
      {$font-body-b5-m}
      color var(--Gray-50, #A2A2A2)

  .popup-store-control-keyword
    $flex(flex-start, stretch, column)
    gap ptr(8)
    &__controls
      $flex(flex-start, stretch)
      gap ptr(8)
    &__input
      flex 1
    &__add-btn
      $flex(center, center)
      flex-shrink 0
      width ptr(41)
      aspect-ratio 1 / 1
      border-radius ptr(4)
      background var(--Gray-10, #E7E7E7)
      border none
      outline none
      svg
        width ptr(20)
    &__values
      $flex(flex-start, center)
      flex-wrap wrap
      gap ptr(8)
    &__value
      $flex(flex-start, center)
      gap ptr(4)
      padding ptr(4) ptr(8)
      {$font-body-b3-m}
      color var(--Gray-80, #6B6B6B) !important
      border-radius ptr(4)
      background var(--Gray-5, #F5F5F5)
      border none
      outline none
      svg
        width ptr(12)

  .popup-store-control-public
    $flex(flex-start, stretch, column)
    gap ptr(8)
    {$font-body-b3-m}
    &__radios
      $flex(flex-start, center)
      gap ptr(24)
    &__label
      $flex(flex-start, center)
      gap ptr(8)
    &__reserve
      $flex(flex-start, stretch, column)
      gap ptr(8)
      &[data-show='true']
        display flex
      &[data-show='false']
        display none
    &__reserve-time
      $flex(space-between, center)
      gap ptr(8)
      input
        padding ptr(12)
        border-radius ptr(4)
        border 1px solid var(--Gray-10, #E7E7E7)
    &__info
      $flex(flex-start, center)
      gap ptr(4)
      {$font-body-b5-r}
      color var(--Gray-50, #A2A2A2)
      svg
        width ptr(16)

  .popup-store-control-period
    $flex(space-between, center)
    gap ptr(8)
    {$font-body-b3-m}
    &__input
      padding ptr(9) ptr(12)
      {$font-body-b3-m}
      border-radius ptr(4)
      border 1px solid var(--Gray-10, #E7E7E7)
      background var(--White, #FFF)

  .popup-store-control-public
    $flex(flex-start, stretch, column)
    gap ptr(8)
    {$font-body-b3-m}
    &__radios
      $flex(flex-start, center)
      gap ptr(24)
    &__label
      $flex(flex-start, center)
      gap ptr(8)
    &__reserve
      $flex(flex-start, stretch, column)
      gap ptr(8)
      &[data-show='true']
        display flex
      &[data-show='false']
        display none
    &__reserve-time
      $flex(space-between, center)
      gap ptr(8)
      input
        padding ptr(12)
        border-radius ptr(4)
        border 1px solid var(--Gray-10, #E7E7E7)
        &:disabled
          background: $eee;
    &__info
      $flex(flex-start, center)
      gap ptr(4)
      {$font-body-b5-r}
      color var(--Gray-50, #A2A2A2)
      svg
        width ptr(16)

  .popup-store-control-goal
    &__row
      {$font-body-b3-m}
      $flex(flex-start, center)
      gap ptr(8)
    &__select
      $flex(flex-start, stretch, column)
      gap ptr(8)
      select
        padding ptr(12) ptr(36) ptr(12) ptr(12)
        border-radius ptr(4)
        border 1px solid var(--Gray-10, #E7E7E7)
        -o-appearance none
        -webkit-appearance none
        -moz-appearance none
        appearance none
    &__input
      flex 1
      input
        text-align right
    &__is_soldout
      padding ptr(16) 0
      input
        margin-right ptr(8)
    &__soldout_label
      &.hidden
        display none

  .popup-store-control-goal-visible
    &__radios
      $flex(flex-start, center)
      gap ptr(16)
    &__label
      $flex(flex-start, center)
      gap ptr(8)

  .popup-store-editor
    height 100%
    &:focus
      outline none
    .editor.popup-store
      > h2
        {$kr-48-b}
        margin 0
      > h3, > div
        {$kr-24-b}
        margin 0
      .caption_img
        width 100%
        font-size 0
        line-height 0
      .img_container
        .resize_wrapper
          .image
            border-radius 0

  .popup-store-related-products
    &__list
      display grid
      grid-column 4
      grid-template-columns repeat(4, minmax(0, 1fr))
      gap ptr(16) ptr(24)

  .popup-store-images-in-editor
    .resize_wrapper
      display flex !important
      justify-content space-between !important
      align-items flex-start !important
      gap 0 !important
      flex-wrap nowrap !important
    .caption_img
      position relative
      text-align left
    .caption
      position absolute
      bottom 0
      left 0
      padding ptr(8) ptr(16)
      {$kr-14-m}
      color $white !important
      outline none !important
      background $black

