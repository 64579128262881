&
  width 100%
  min-height 100%
  display flex
  flex-direction column

  [data-show="false"]
    display none

  .header
    flex-shrink 0
    width 100%
    h2
      line-height 4rem
      height 4rem
      margin 0
    @import "../filter.styl"

  .chart-wrapper
    min-width 1400px
    .store_desc
      width 30%
    .store_review
      width 10%
    tbody
      tr
        height 4rem
        .logo_sns
          display flex
          align-items center
          justify-content center
          img
            max-height 40px
        .link
          @import "../links.styl"
        .store_label
          @import "../store_label.styl"
        .control
          width 120px
          text-align center
          button
            margin 2px
            border 0 none
            width 100px
            padding 5px 10px
            border-radius 5px
            &.btn_can_selling
              background #3c89f9
              color #fff
            &.btn_fail
              background #d9534f
              color #fff
