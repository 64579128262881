.toast-message-container
  position fixed
  bottom 40px
  z-index 10
  max-width 80vw
  left 50%
  transform translate(-50%, 0)

.toast-message
  display flex
  align-items center
  padding ptr(8) ptr(16)
  border-radius 99px
  background $dim-60
  backdrop-filter blur(5px)
  -webkit-backdrop-filter blur(5px)
  color white
  animation-duration 500ms
  animation-name toastIn
  column-gap ptr(16)
  margin-top ptr(20)
  &--end
    animation-name toastOut
  &__inner
    limitLine(1)
  a
    flex-shrink 0
  &__text-button
    color $bright-60
    cursor pointer


@keyframes toastIn {
  from {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8);
  }

  50% {
    opacity: $opacity;
  }
}

@keyframes toastOut {
  from {
    opacity: $opacity;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8);
  }

  to {
    opacity: 0;
  }
}

