small = 1024px

resetButton()
  margin 0
  padding 0
  border none
  background none

.plus-home-banner
  position relative
  margin-bottom 95px
  min-height 638px
  @media (max-width: small)
    margin-bottom 48px
    min-height 300px
  &__controls
    .is_mobile_size &
      display none
  &__next, &__prev
    resetButton()
    position absolute
    top 50%
    transform translateY(-50%)
    overflow: hidden;
    width 32px
    height 56px
    background-image url('//s3.marpple.co/files/u_1396787/2020/11/original/ba77ec66f1d117b7df2cb3a91ebb803970137ce81.png')
    background-size 100%
    background-repeat no-repeat
    text-indent -999em
    z-index 2
    &.swiper-button-disabled
      opacity .4
    ^[0]__controls--white &
      background-image url('//s3.marpple.co/files/u_1396787/2020/11/original/865dc78ca61e0288630a4cf893346cfb2c16c0a01.png')
  &__next
    right -64px
  &__prev
    left -64px
    transform translateY(-50%) rotate(-180deg)

.plus-home-swiper
  &__item
    position relative
    &::before
      display block
      padding-bottom 50%
      content ''
    @media (max-width: small)
      &::before
        padding-bottom 100%
  &__title
    position absolute
    bottom 54px
    left 64px
    right 64px
    margin 0
    line-height 48px
    font-size 38px
    font-weight bold
    @media (max-width: small)
      bottom 16px
      left 24px
      right 24px
      line-height 24px
      font-size 18px

  &__image
    position: absolute;
    top 0
    left 0
    width 100%
    height 100%
