#fr-crew-store_management-store_editing-seller_registration
  > .don_wrapper
    display flex
    flex-direction column-reverse
    width 26rem
    height 11rem

    > .header
      @import "./popup_frame_header.styl"

    .don_page
      width 100%
      height 100%
      > .don_wrapper
        width 100%
        height 100%
        > .body
          width 100%
          height 100%
          .don_tab
            width 100%
            height 100%
            .don_wrapper
              width 100%
              height 100%
              @import "./seller_registration_form.styl"
