[frame_name="/modules/VectorEditor/FreeShape/Mobile/S/Mui/frame.js"]
  > .don_wrapper
    > .header
      .frame_header
        width 100%
        height 100%
        position relative
        display flex
        align-items center
        justify-content center
        box-shadow 0 1px 0 0 #e9ecef
        box-sizing border-box
        background-color #FFFFFF
        .title
          font-size 14px
          font-weight bold
        .close
          position absolute
          right 16px
          width 16px
          height 9px
          margin 0
          padding 0
          border 0 none
          background-color transparent
          background-image url("//s3.marpple.co/files/u_1187077/2021/6/original/5776081716a5cdaeb37f953e42b96b0826e443ca2.png")
          background-repeat no-repeat
          background-size 16px 9px
          background-position center center

[page_name="/modules/VectorEditor/FreeShape/Mobile/S/Mui/page.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    > .body
      width 100%
      height 100%

[tab_name="/modules/VectorEditor/FreeShape/Mobile/S/Mui/tab.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    background-color #FFFFFF
    .background_plate
      position fixed
      top 0
      right 0
      bottom 0
      left 0
      background-color #FFFFFF
    .infi_container
      box-sizing border-box
      padding 16px 8px
      background-color #FFFFFF
      .infi_item
        position relative
        background-color #FFFFFF
        &::after
          content ''
          display block
          padding-bottom 100%
        .infi_item_outer_container
          position absolute
          width 100%
          height 100%
          box-sizing border-box
          padding 8px
          .infi_item_inner_container
            position relative
            width 100%
            height 100%
            padding 0
            margin 0
            overflow hidden
            border-radius 4px
            border solid 1px #d5dbe0
            background-color transparent
            background-image url("//s3.marpple.co/vector-editor-assets/mobile/layer/img-layer-pattern@2x.png")
            background-repeat repeat
            background-size 8px 8px
            background-position center center
            &:active
              border-color #0157FF
