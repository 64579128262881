.plus-page-header
  margin-bottom 60px
  .title
    margin 0
    margin-bottom 10px
    font-size 22px
    font-weight 600
    letter-spacing -1px
    color black
  .description
    margin 0
    font-size 14px
    letter-spacing -0.5px
    color #4c4c4c
    line-height 1.57
  .caution
    margin 0
    font-size 14px
    letter-spacing -0.5px
    line-height 1.57
    color #828282
  .features
    margin-left auto
