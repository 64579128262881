.sticker_etc_share_style
  .select_font
    position relative
    display flex
    align-items center
    border 1px solid #d5dbe0
    &[data-is_selected_font="true"]
      .font
        display block
      .no_font
        display none
    &[data-is_selected_font="false"]
      .font
        display none
      .no_font
        display true
    .font
      margin-left 16px
      height 16px
    .arrow
      width 6px
      position absolute
      top 50%
      right 16px
      transform translateY(-50%)
  .font_style_wrapper
    display flex
  .font_style
    width 100%
    background-repeat no-repeat
    background-position 50% 50%
    position relative
    &:active
      background-color #ced4da
      border-color transparent
    &:nth-child(n+2):after
      height 31%
      top 34%
      content ""
      position absolute
      left 0
      border-left 1px solid #d5dbe0
    &:focus
      outline none
    &[data-value="bold"]
      background-size 9px 12px
      background-image url(//s3.marpple.co/files/u_193535/2021/4/original/100fcc678afe5ccfa1a925298c6b62bcab8b2d7f1.svg)
      &.active
        background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6014_1539320766618_J4cWQzDtr5Zy4Kcwux.png)
    &[data-value="italic"]
      background-size 8px 12px
      background-image url(//s3.marpple.co/files/u_193535/2021/4/original/0e421828d8a49d3d87bf11caca78d338227a2f851.svg)
      &.active
        background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6023_1539320766618_H3OyZiCqpzFOPq7zr.png)
    &[data-value="underline"]
      background-size 8px 10px
      background-image url(//s3.marpple.co/files/u_193535/2021/4/original/f3de5772d1261bb0d871179ea65eb53a4ea8ce5a3.svg)
      &.active
        background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6028_1539320766618_Nwxrn7w7W7EdNa3mO9cy.png)
    &[data-value="line-through"]
      background-size 9px 12px
      background-image url(//s3.marpple.co/files/u_193535/2021/4/original/fef713609e87bb95db23ed8568ee5e07f29606462.svg)
      &.active
        background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6025_1539320766618_s5u1VTs1Qaii0oOR2SUE.png)
