.plus-admin-alert
  &__header
    margin 0 0 12px
    font-size 20px
    font-weight 800
    line-height 1.3
    letter-spacing -0.3px
    color #111
  &__body
    font-size 15px
    line-height 1.73
    letter-spacing -0.2px
    color #111

html.crew-today_detail
  .crew-feed_detail
    width 100%

    .feed-thumb-content
      color white
      text-align left
      width 100%
      box-sizing border-box
      position absolute
      padding unit(24 / 14, 'em')
      top 0
      &.content-bottom
        top auto
        bottom 0
      .feed-thumb-category
        font-size 1em
        line-height 1.29
        letter-spacing -0.2px
        font-weight 200
        margin-bottom 4px
      .feed-thumb-title
        font-size unit(23 / 14, 'em')
        font-weight 400
        line-height 1.3
        letter-spacing -0.3px

    .plus-page-header
      display flex
      justify-content space-between
      align-items flex-end
      .btn-submit
        display inline-flex
        align-items center
        img
          height 8px
          margin-right 10px

    .crew-feed-detail-lang-box
      display inline-flex
      margin-bottom 30px
      column-gap 10px
    .crew-feed-detail-lang
      &[data-active="true"]
        border-color: #3c97fe;
        background: #3c97fe;
        color: #fff;


    .feed_container
      width 100%
      display flex
      position relative
      &[data-expanded="true"]
        .feed_layout
          width 100%

      .feed_layout
        width 73.125%
        border-radius 6px
        border solid 1px #ededed
        font-size 18px
        padding 3em 2em
        display inline-block
        vertical-align top
        position relative
        min-height 100vh
        transition width ease 0.5s

      @import "../feed/editor.styl"

      .editor
        padding 0
        width 100%
        max-width 1276px
        flex 1

        &:after
          font-style normal
          margin-left 0


      .feed_info
        position absolute
        right 0
        width calc(26.875% - 20px)
        height 100%
        transition transform ease 0.5s

        .wrapper
          border-radius 6px
          width 100%
          border solid 1px #ededed
          padding 20px
          display inline-block
          position sticky
          top 0
          @media screen and (max-width: 1700px)
            width 100%
            position static

          .item
            margin-bottom 25px

            &.image
              width 70%
              aspect-ratio 1200/1800
              position relative
              border dashed 1px #d9d9d9
              margin-bottom 10px
              // mobile
              font-size calc((100vw - 16.6rem) * 0.009322693303)

              &.pc
                font-size calc((100vw - 16.6rem) * 0.007737362208)

              .image_attached
                p, button
                  display none

              label
                line-height 0
                width 100%
                cursor pointer
                .thumbnail
                  width 100%

              p
                line-height 1.5
                margin 0

              .plus_btn
                width 60px
                height 60px
                border-radius 50%
                background-color #3c89f9
                display inline-flex
                align-items center
                justify-content center
                border 0
                margin-bottom 20px
                img
                  width 30px
                  cursor pointer

              .ratio_box
                display flex
                justify-content center
                align-items center
                position absolute
                top 0
                left 0
                width 100%
                height 100%
                text-align center

            &.image-desc
              width 70%
              padding 10px 0
              display flex
              align-items center
              justify-content center
              line-height 1.54
              font-size 13px
              letter-spacing -0.2px
              color #4c4c4c
              background-color rgba(76, 76, 76, 0.05)
              border-radius 4px
              margin-bottom 20px
              text-align center
            &.image-setting
              margin-bottom 30px
              button
                padding 12px 24px 11px
                font-size 14px
                font-weight 600

          .close_btn
            position absolute
            top 15px
            right 15px
            width 28px
            cursor pointer

          input[type="radio"]
            margin-right 8px
          input[type="text"]
            width 100%
            font-size 14px
            border solid 1px #d5dbe0
            padding 8px 16px
            box-sizing border-box
            display block
            background-color #fff
            outline none
            -webkit-appearance none
            border-radius 2px

          .item_title
            font-weight bold
            font-size 14px
            margin-bottom 1em

          .radio-label, .label
            margin-right 20px
            width 95px

          .information
            color gray
            ul
              padding-left 0
              list-style none
            li
              margin-bottom 30px

          .plus_btn
            border 1px solid #bdbdbd
            border-radius 50%
            width 1.5em
            height 1.5em
            line-height 1.5em
            display inline-flex
            justify-content center
            align-items center
            cursor pointer
            background-color white
            color #bdbdbd
            vertical-align top
            img
              width 13px

          .today_tag_container
            .today_tag_input_container
              display flex
              align-items center
            .today_tag_input
              width 50%
              display inline-flex
            .add_tag_btn
              margin-left 10px
            .today_tags
              display flex
              flex-wrap wrap
              margin-top 10px
            .today_tag
              position relative
              cursor pointer
              height 36px
              padding 0 38px 0 16px
              border-radius 14px
              min-width 70px
              background-color #3c97fe
              color white
              display inline-flex
              align-items center
              margin-right 5px
              margin-top 5px
              .today_tag_text
                margin-right 5px
              .icon-close
                width 18px
                position absolute
                top 50%
                right 12px
                transform translateY(-50%)

          .seo_description
            .seo_description_input
              width 100%
              resize none
              padding 16px 16px
              white-space nowrap
          .seo_title
            .seo_title_input
              width 100%
              resize none
              padding 16px 16px
              white-space nowrap



