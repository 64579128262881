.crew-seller_registration
  display flex
  flex-direction column
  align-items flex-end
  width 100%
  height 100%

  box-sizing border-box
  padding 1rem

  .seller_email
    width 100%
    height 0
    flex-grow 1

    margin-bottom 1rem

    font-size 1rem
