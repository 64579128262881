.don_frame.editor-marpple-embed-modal[is_modal=true]
  > .don_wrapper
    border-radius 10px

  .don_page > .don_wrapper > .header
    border-top-left-radius 10px
    border-top-right-radius 10px
    position absolute
    top 0
    width 100%
    background-color white
    z-index 2

    .title
      font-size 18px
      height 56px
      display flex
      justify-content center
      align-items center
      font-weight 500
      border-bottom 1px solid #ededed

  frame_modal_full_height()

  .don_tab
    .layout
      display flex
      flex-direction column
      padding 20px
      padding-top 76px
      height 100%

      .content
        flex 1

        .empty_label
          display none

        .marpple-embed-container
          display block
          @import './editor.embed.styl'

        &.empty
          display flex
          justify-content center
          align-items center
          text-align center

          .empty_label
            display block
          p
            margin 0

          .marpple-embed-container
            display none

          .plus_btn
            width 60px
            height 60px
            border-radius 50%
            background-color #3c89f9
            display inline-flex
            align-items center
            justify-content center
            border 0
            margin-bottom 20px
            img
              width 30px
              cursor pointer

        .header
          position relative
          margin-bottom 20px

          .title
            position absolute
            width 100%
            height 100%
            font-size 14px
            letter-spacing -0.5px
            color #4c4c4c
            display flex
            align-items center
            justify-content center
            .icon-drag
              width 34px
              margin-right 10px

          .btn.btn-submit
            position relative
            background-color white
            color #3c89f9
            display flex
            align-items center
            margin-left auto
            z-index 1

          .icon-add
            margin-right 10px
            width 16px

      .footer
        width 100%
        display flex
        justify-content space-between
        .btn
          width calc(50% - 5px)

// url modal
.don_frame.marpple-embed-url-modal
  frame_modal_full_height()

  > .don_wrapper
    width 500px
    height 700px
    border-radius 10px

    .don_tab
      .layout
        padding 40px 20px 20px
        display flex
        flex-direction column
        height 100%

      .content
        flex 1
        display flex
        flex-direction column

      .title, .description
        margin 0
        text-align center
        line-height 1.5
        letter-spacing -0.5px

      .title
        font-size 18px
        font-weight bold
        margin-bottom 5px

      .description
        font-size 14px
        margin-bottom 30px

      .textarea
        resize none
        padding 13px
        border solid 1px #ededed
        font-size: 14px;
        width 100%
        flex 1
        letter-spacing -0.5px
        line-height 1.5
        margin-bottom 20px
        &::placeholder
          color b2b2b2

      .footer
        width 100%
        display flex
        justify-content space-between
        .btn
          width calc(50% - 5px)



