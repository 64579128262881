
.plus-page-header
  margin-bottom 60px
  display flex
  align-items flex-end
  .feature
    margin-left auto
  .feature-submit
    submitButton()

.tab_headers
  display flex
  align-items center
  height 32px
  margin-bottom 30px
  .tab
    margin-right 40px
    font-size 16px
    font-weight 500
    letter-spacing -0.5px
    position relative
    cursor pointer
    &.selected
      font-weight bold
    &.selected span:after
      content ''
      position absolute
      bottom -6px
      left 0
      width 100%
      height 2px
      background-color #002aff

  .feature
    margin-left auto

  .custom-dropdown

    .dropdown-options
      position absolute
      right 0
      top 100%
      border-radius 2px
      box-shadow 0 1px 2px 0 rgba(0, 0, 0, 0.3)
      border solid 1px #e5e5e5
      display none
      text-align center
      white-space nowrap
      background-color white
      z-index 1

      .dropdown-option
        padding 13px 70px
      .dropdown-option-input
        display none

    &:focus
      .dropdown-options
        display block
        .dropdown-option:hover
          background-color #3c89f9
          color white

