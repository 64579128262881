[page_name="/modules/VectorEditor/Sticker/Single/Mobile/CuttingLineAuto/S/Mui/page.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    > .body
      width 100%
      height 100%

[tab_name="/modules/VectorEditor/Sticker/Single/Mobile/CuttingLineAuto/S/Mui/tab.js"]
  width 100%
  height 100%
  > .don_wrapper
    width 100%
    height 100%
    overflow hidden

    .editor_container
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      background-color #F8F9FA

    .header
      display flex
      align-items center
      justify-content space-between
      position absolute
      box-sizing border-box
      padding 0 6px
      top 0
      width 100%
      height 48px
      box-shadow 0 1px 0 0 #E9ECEF
      background-color #FFFFFF
      button
        width 58px
        height 32px
        background-color #FFFFFF
        border 0 none
      .title
        font-weight bold

    .footer
      display flex
      flex-direction column
      align-items center
      justify-content space-between
      position absolute
      box-sizing border-box
      padding 24px
      bottom 0
      left 0
      right 0
      height 122px
      background-color #FFFFFF

      .description
        font-size 12px
        display inline-flex
        align-items center
        height 24px
        &::before
          content ""
          display inline-block
          width 16px
          height 16px
          background-repeat no-repeat
          background-size 16px 16px
          background-position center center
          background-image url("//s3.marpple.co/vector-editor-assets/mobile/menu-button/normal/ic-sticker@2x.png")
          margin-right 8px

      .controls
        display inline-flex
        width 100%
        height 32px
        .offset_control
          display inline-flex
          align-items center
          justify-content space-between
          padding 0 4px
          width 0
          height 100%
          flex-grow 1
          margin-right 14px
          box-sizing border-box
          background-color #FFFFFF
          border solid 1px #D5DBE0
          .decrease,
          .increase
            background-color #FFFFFF
            width 24px
            height 24px
            border 0 none
        .edit_manual
          width 118px
          height 100%
          box-sizing border-box
          background-color #FFFFFF
          border solid 1px #D5DBE0
